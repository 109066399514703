import { apiSlice } from '../apiSlice';

const formStateManagementApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET /state/{stateId}
    getFormState: builder.query({
      query: (stateId) => ({
        url: `/state/${stateId}`,
        method: 'GET',
      }),
    }),

    // POST /state/{stateId}
    saveFormState: builder.mutation({
      query: ({ stateId, payload }) => ({
        url: `/state/${stateId}`,
        method: 'POST',
        body: JSON.stringify(payload),
      }),
    }),

    // DELETE /state/{stateId}
    deleteFormState: builder.mutation({
      query: (stateId) => ({
        url: `/state/${stateId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
    useGetFormStateQuery,
    useLazyGetFormStateQuery,
    useSaveFormStateMutation,
    useDeleteFormStateMutation,
} = formStateManagementApi;
