import React from 'react';
import {
  RichTextEditorProvider,
  RichTextField,
} from 'mui-tiptap';
import { Box } from '@mui/material';
import './ConfiguredRichTextEditor.css';
import CustomFieldLoadingAnimation from '../../CustomFieldLoadingAnimation.jsx';
import EditorMenuControls from './EditorMenuControls.jsx';


const PopulatedEditor = ({
  showGenerateButton = false,
  aiDataSourceConfig,
  fieldName,
  disabled,
  documentId,
  user,
  editor,
}) => {
  return  <Box
  sx={{
    position: 'relative', // Set position to relative to contain the overlay
    '.ProseMirror': {
      minHeight: '100px',
      maxHeight: '350px',
      overflow: 'auto',
    },
  }}
>
  <RichTextEditorProvider editor={editor}>
    {/* Loading overlay inside the editor container */}
    <CustomFieldLoadingAnimation />

    <RichTextField
      controls={
        <EditorMenuControls
          editor={editor}
          aiDataSourceConfig={aiDataSourceConfig}
          showGenerateButton={showGenerateButton}
          fieldName={fieldName}
          documentId={documentId}
          user={user}
          disabled={disabled}
        />
      }
      MenuBarProps={{ hide: !editor.isFocused }}
    />
  </RichTextEditorProvider>
</Box>
}

export default PopulatedEditor;