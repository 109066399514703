import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useState } from "react";
import { IconButton, Typography, Tab, Tabs, Box, Stack } from '@mui/material';
import { Apps, ArrowBack, DocumentScanner, DocumentScannerOutlined, PeopleAltOutlined, Settings } from '@mui/icons-material';
import awsExports from '../aws-exports';
import AdminTabs from '../CustomerManagement/AdminTabs';
import UsersView from './UsersView';
import CustomerConfigView from './CustomerConfigView';
import DemandLettersView from './DemandLetters/DemandLettersView';
import IntegrationsView from './Integrations/IntegrationsView';
import { setCustomerData, setPrecedentUsers } from '../redux/slices/adminEditTemplateSlice';
import { useSelector, useDispatch } from 'react-redux';

export const CustomerManagementView = () => {
    const dispatch = useDispatch();
    const { customerData } = useSelector((state) => state.AdminEditTemplate);

    const buildFullName = (user) => {
        return user.firstName + " " + user.lastName
    };

    const getPrecedentUsersApi = () => {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify({ customerId: "precedent" })
        };

        fetch(rootApiUrl + "/users", settings)
            .then((response) => response.json())
            .then((response) => {
                const precedentUsersList = []
                if (Array.isArray(response)) {
                    response.forEach(element => {
                        const name = buildFullName(element);
                        precedentUsersList.push({ value: element.emailAddress, label: name, emailAddress: element.emailAddress, userId: element.userId });
                    });
                }
                dispatch(setPrecedentUsers(precedentUsersList));
            })
            .catch((error) => console.log(error))
            .finally(() => {
                setLoading(false);
            });
    };

    const { user } = useSelector(state => state.User);
    var { customerId } = useParams();
    const rootApiUrl = awsExports.ROOT_API_URL;
    const [loading, setLoading] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    const navigate = useNavigate();

    const getCustomerApi = async () => {
        if (customerId == "new") {
            dispatch(setCustomerData({
                "customerId": uuidv4(),
                "name": "",
                "customerEntityData": {},
                "firmApprovalRemindersConfig": [
                    {
                        "minutesFromStart": 1440,
                        "subject_filename": "email_subject_reminder1.txt",
                        "body_txt_filename": "email_body_reminder.txt",
                        "body_html_filename": "email_body_reminder.html",
                        "cc_flags": [],
                        "reminder_name": "first_reminder"
                    },
                    {
                        "minutesFromStart": 2880,
                        "subject_filename": "email_subject_reminder1.txt",
                        "body_txt_filename": "email_body_reminder.txt",
                        "body_html_filename": "email_body_reminder.html",
                        "cc_flags": [
                            "supervisorCC"
                        ],
                        "reminder_name": "second_reminder"
                    },
                    {
                        "minutesFromStart": 4320,
                        "subject_filename": "email_subject_reminder3.txt",
                        "body_txt_filename": "email_body_reminder.txt",
                        "body_html_filename": "email_body_reminder.html",
                        "cc_flags": [
                            "supervisorCC",
                            "firmApprovalCC"
                        ],
                        "reminder_name": "third_reminder"
                    }
                ],
                "isNew": true,
                "customerType": "lawfirm"
            }));
        }
        else {
            const settings = {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    Authorization: user.signInUserSession.accessToken.jwtToken
                },
            };

            setLoading(true);

            const response = await fetch(
                rootApiUrl + `/customer/${customerId}`, settings
            ).then((response) => response.json())
                .finally(() => {
                    setLoading(false);
                });

            response["isNew"] = false;
            dispatch(setCustomerData(response));
        }
    };

    /*
     * Handlers
     */

    const customerUpdatedCallback = (updatedCustomerId) => {
        customerId = updatedCustomerId;
        navigate("/customer-management/" + customerId);
    };

    const handleShowAllCustomersClick = () => {
        navigate('/customer-management');
    };

    const handleTabChanged = (e, newValue) => {
        setTabValue(newValue);

    };

    /*
     * Hooks
     */

    useEffect(() => {
        getCustomerApi();
        getPrecedentUsersApi();
    }, [customerId]);

    return (
        <Box
            sx={{
                display: "flex",
                height: "100vh",
                overflow: "hidden",
                mt: 8
            }}
        >
            {/* Left Sidebar Navigation */}
            <AdminTabs selectedTab="customer-management"></AdminTabs>

            {/* Fixed Left Tabs (Next to Sidebar) */}
            <Box
                sx={{
                    width: "px",
                    minWidth: "168px",
                    position: "fixed",
                    top: 64, // Aligns with the top of the content area
                    left: "98px", // Directly after the sidebar
                    // borderRight: 1,
                    // backgroundColor: grey[50],
                    height: "calc(100vh - 64px)", // Ensures it doesn't go offscreen
                    overflowY: "auto",
                    pt: 2,
                    px: 1
                }}
            >
                <Box>
                    <Typography variant="h7" color={'primary'}>
                        Global
                    </Typography>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={tabValue}
                        onChange={handleTabChanged}
                        aria-label="Customer admin"
                        className='no-indicator-tabs'

                    >
                        <Tab
                            label="Settings"
                            id="properties-tab"
                            value={0}
                            className='secondary-nav-tab'
                            icon={<Settings />}
                            iconPosition='start'
                        />
                        <Tab
                            label="Users"
                            id="users-tab"
                            value={1}
                            disabled={customerId === "new"}
                            className='secondary-nav-tab'
                            icon={<PeopleAltOutlined />}
                            iconPosition='start'
                        />
                        <Tab
                            label="Integrations"
                            id="integrations-tab"
                            value={2}
                            disabled={customerId === "new"}
                            className='secondary-nav-tab'
                            icon={<Apps />}
                            iconPosition='start'
                        />
                        <Typography
                            variant="h7"
                            color={'primary'}
                            mt={2}
                        >
                            Composer
                        </Typography>
                        <Tab
                            label="TBD settings"
                            id="demand-letters-tab"
                            value={3}
                            disabled={customerId === "new"}
                            className='secondary-nav-tab'
                            icon={<Settings />}
                            iconPosition='start'
                        />
                        <Tab
                            label="Demand letters"
                            id="demand-letters-tab"
                            value={4}
                            disabled={customerId === "new"}
                            className='secondary-nav-tab'
                            icon={<DocumentScannerOutlined />}
                            iconPosition='start'
                        />
                        <Typography
                            variant="h7"
                            color={'primary'}
                            mt={2}
                        >
                            Verify+
                        </Typography>
                        <Tab
                            label="TBD settings"
                            id="demand-letters-tab"
                            value={5}
                            disabled={customerId === "new"}
                            className='secondary-nav-tab'
                            icon={<Settings />}
                            iconPosition='start'
                        />
                    </Tabs>
                </Box>
            </Box>

            {/* Scrollable Content Area */}
            <Box
                sx={{
                    flexGrow: 1,
                    marginLeft: "266px", // 112px (sidebar) + 168px (tabs)
                    overflowY: "auto",
                    height: "100vh",
                    p: 2,
                }}
            >
                {customerData && (
                    <>
                        {/* Fixed Header */}
                        <Stack
                            alignItems="center"
                            direction={"row"}
                            spacing={1}
                            sx={{
                                position: "fixed",
                                top: 64, // Aligns with top of content area
                                left: "280px", // Sidebar (112px) + Tabs (168px)
                                right: 0, // Stretches to full width
                                backgroundColor: "#ffffff",
                                zIndex: 1000,
                                display: "flex",
                                alignItems: "center",
                                pl: 1,
                                pt: 1
                            }}
                        >
                            <IconButton onClick={handleShowAllCustomersClick}>
                                <ArrowBack />
                            </IconButton>
                            <Typography variant="h6">{customerData.name}</Typography>
                        </Stack>

                        {/* Main Content Area (With Top Offset to Avoid Overlap) */}
                        <Box
                            sx={{
                                width: "calc(100vw - 312px)", // Adjust for sidebar + tabs
                                marginTop: "32px", // Prevent overlap with fixed header
                                paddingTop: "16px", // Spacing after fixed header
                                marginRight: "16px",
                            }}
                        >
                            {tabValue === 0 && <CustomerConfigView updateCallback={customerUpdatedCallback} user={user} />}
                            {tabValue === 1 && <UsersView user={user} />}
                            {tabValue === 2 && <IntegrationsView />}
                            {tabValue === 4 && <DemandLettersView user={user} />}
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );

}
