import { formatPhoneNumber } from '../common';

export const isValidPhoneNumber = (value) => {
    const formattedPhoneNumber = formatPhoneNumber(value);
    //strip anything past the "x"
    const withoutExtension = formattedPhoneNumber.split("x")[0];
    //there must be at least 10 digits in a valid phone number
    const digitsOnly = withoutExtension.replace(/[^0-9]/g, '');
    return digitsOnly.length >= 10;
}

export const isValidEmail = (email) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    //if it is empty, or it is not in the correct format, return false
    if (!email || email.length === 0) {
        return false
    }
    else if (!emailRegex.test(email)) {
        return false;
    }
    else {
        return true;
    }
};