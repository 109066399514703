import React from 'react';
import { Switches, TextField } from 'mui-rff';
import Grid from '@mui/material/Unstable_Grid2';
import {
    MUTATORS_SHOW_ADJUSTER_CONTACT_INFO,
    RECIPIENT_ADJUSTER_PHONE_NUMBER,
    RECIPIENT_ADJUSTER_EMAIL_ADDRESS,
    RECIPIENT_ADJUSTER_FIRST_NAME,
    RECIPIENT_ADJUSTER_LAST_NAME
} from '../Schemas/RecipientAdjuster';
import { formatPhoneNumber } from '../../../common';

export const ShowRecipientAdjusterContactInfoField = ({ form }) => {
    return (
        <Switches
            name={MUTATORS_SHOW_ADJUSTER_CONTACT_INFO.path}
            onChange={(e) => {
                const checked = e.target.checked;
                form.change(MUTATORS_SHOW_ADJUSTER_CONTACT_INFO.path, checked)
                if (!checked) {
                    form.change(RECIPIENT_ADJUSTER_PHONE_NUMBER.path, '');
                    form.change(RECIPIENT_ADJUSTER_EMAIL_ADDRESS.path, '');
                    form.change(RECIPIENT_ADJUSTER_FIRST_NAME.path, '');
                    form.change(RECIPIENT_ADJUSTER_LAST_NAME.path, '');
                }
            }}
            data={{ label: MUTATORS_SHOW_ADJUSTER_CONTACT_INFO.label, value: true }}
            color="secondary"
        />
    )
}

export const RecipientAdjusterContactInfoFields = () => {
    return (

        <>
            <Grid xs={12} md={6}>
                <TextField
                    fullWidth
                    required={RECIPIENT_ADJUSTER_FIRST_NAME.required}
                    label={RECIPIENT_ADJUSTER_FIRST_NAME.label}
                    name={RECIPIENT_ADJUSTER_FIRST_NAME.path}
                />
            </Grid>
            <Grid xs={12} md={6}>
                <TextField
                    fullWidth
                    required={RECIPIENT_ADJUSTER_LAST_NAME.required}
                    label={RECIPIENT_ADJUSTER_LAST_NAME.label}
                    name={RECIPIENT_ADJUSTER_LAST_NAME.path}
                />
            </Grid>
            <Grid xs={12} md={6}>
                <TextField
                    fullWidth
                    required={RECIPIENT_ADJUSTER_PHONE_NUMBER.required}
                    label={RECIPIENT_ADJUSTER_PHONE_NUMBER.label}
                    name={RECIPIENT_ADJUSTER_PHONE_NUMBER.path}
                    type='tel'
                    onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)}
                    fieldProps={{ validate: RECIPIENT_ADJUSTER_PHONE_NUMBER.validation }}
                />
            </Grid>
            <Grid xs={12} md={6}>
                <TextField
                    fullWidth
                    required={RECIPIENT_ADJUSTER_EMAIL_ADDRESS.required}
                    label={RECIPIENT_ADJUSTER_EMAIL_ADDRESS.label}
                    name={RECIPIENT_ADJUSTER_EMAIL_ADDRESS.path}
                    type='email'
                    fieldProps={{ validate: RECIPIENT_ADJUSTER_EMAIL_ADDRESS.validation }}
                />
            </Grid>
        </>
    )
}



