import React from 'react';
import { IconButton } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setDemandEditOpen } from '../../redux/slices/demandDomSlice.js';
import { userHasPermission } from '../../common.js';

const EditDemandButton = () => {
    const { userData } = useSelector((state) => state.User);
    const canEditDemand = userHasPermission('DemandEdit', userData)
    const dispatch = useDispatch();

    return (
        <>
            {canEditDemand && (
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(setDemandEditOpen(true));
                    }}
                >
                    <EditOutlined />
                </IconButton>
            )}
        </>
    )
}

export default EditDemandButton;