import React, { useState } from 'react';
import { Card, Collapse, Stack } from '@mui/material';
import Title from './Title';
import ExpandMore from './ExpandMore';

const CollapseCard = ({ title, children, expanded }) => {
  const [expandedState, setExpandedState] = useState(expanded);

  const handleExpandClick = () => {
    setExpandedState(!expandedState);
  };

  return (
    <Card>
      <Stack
        direction="row"
        justifyContent={'space-between'}
        alignItems={'center'}
        onClick={handleExpandClick}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Title>{title}</Title>
        <Stack direction="row" spacing={1}>
          <ExpandMore expand={expandedState} onClick={handleExpandClick} />
        </Stack>
      </Stack>
      <Collapse in={expandedState} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Card>
  );
};

export default CollapseCard;
