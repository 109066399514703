import moment from "moment"
import { dateInPast } from "../common"

export const dateStringValidator = (fieldName) => (date, ctx) => {
  if(!moment(date).isValid()) {
    return ctx.createError({
      message: `${fieldName} must be a valid date.`
    })
  }

  return true
}

export const dateInPastValidator = (fieldName) => (date, ctx) => {
  if(!dateInPast(date)) {
        return ctx.createError({
      path: 'treatmentDate',
      message: `${fieldName} cannot be set in the future`
    })
  }
  
  return true
}
