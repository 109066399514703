import { apiSlice } from '../apiSlice';

const integrationsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET /integrations
    getIntegrations: builder.query({
      query: () => ({
        url: `/integrations`,
        method: 'GET',
      }),
    }),
    // GET /integrations/user
    getIntegrationsForUser: builder.query({
      query: () => ({
        url: `/integrations/user`,
        method: 'GET',
      }),
    }),
    // GET /integrations/customer/customerId
    getIntegrationsForCustomer: builder.query({
      query: ({ customerId }) => ({
        url: `/integrations/customer/${customerId}`,
        method: 'GET',
      }),
      providesTags: (result, error, { customerId }) => [{ type: 'CustomerIntegrations', id: customerId }],
    }),
    // GET /integrations/customer
    getCustomerIntegrationsForUser: builder.query({
      query: () => ({
        url: '/integrations/customer',
        method: 'GET',
      }),
    }),
    getIntegrationMetadata: builder.query({
      queryFn: async (_, { dispatch }) => {
        try {
          const results = await Promise.all([
            dispatch(apiSlice.endpoints.getIntegrations.initiate()),
            dispatch(apiSlice.endpoints.getCustomerIntegrationsForUser.initiate()),
          ]);
          const integrations = results[0]?.data;
          const customerIntegrations = results[1]?.data;

          const { integrationId } = customerIntegrations?.integrations[0] ?? {};
          const integrationMetadata =
            integrations?.find((integration) => integration.integrationId === integrationId) ?? {};

          return { data: integrationMetadata };
        } catch (error) {
          return { error };
        }
      },
    }),
    // POST /integrations/{integrationId}/authenticationurl
    getIntegrationAuthenticationUrl: builder.query({
      query: ({ integrationId, payload }) => ({
        url: `/integrations/${integrationId}/authenticationurl`,
        method: 'POST',
        body: JSON.stringify(payload),
      }),
    }),
    // POST /integrations/{integrationId}/query
    postSearch: builder.query({
      query: ({ queryField, queryText, integrationId }) => ({
        url: `/integrations/${integrationId}/query`,
        method: 'POST',
        body: JSON.stringify({ queryField, queryText }),
      }),
    }),
    // GET /integrations/{integrationId}/demand/{matterId}/documents
    getDocumentsForMatter: builder.query({
      query: ({ matterId, integrationId }) => ({
        url: `/integrations/${integrationId}/demand/${matterId}/documents`,
        method: 'GET',
      }),
    }),
    postSaveIntegration: builder.mutation({
      query: ({ integrationId, fieldInfo, customerId }) => ({
        url: `/integration/${integrationId}/${customerId}`,
        method: 'POST',
        body: JSON.stringify(fieldInfo),
      }),
      invalidatesTags: (result, error, { customerId }) => [{ type: 'CustomerIntegrations', id: customerId }],
    }),
    getMatter: builder.query({
      query: ({ matterId, integrationId }) => ({
        url: `/integrations/${integrationId}/demand/${matterId}`,
        method: 'GET',
      }),
    }),
    // POST /document/{documentId}/uploadToIntegration
    uploadDocumentToIntegration: builder.mutation({
      query: (documentId) => ({
        url: `/document/${documentId}/uploadToIntegration`,
        method: 'POST',
        body: JSON.stringify(),
      }),
    }),
    // GET /document/{documentId}/uploadToIntegration/{uploadRequestId}
    getDocumentUploadStatus: builder.query({
      query: ({ documentId, uploadRequestId }) => ({
        url: `/document/${documentId}/uploadToIntegration/${uploadRequestId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetIntegrationsQuery,
  useGetIntegrationsForUserQuery,
  useGetIntegrationsForCustomerQuery,
  useGetCustomerIntegrationsForUserQuery,
  useGetIntegrationMetadataQuery,
  useGetIntegrationAuthenticationUrlQuery,
  useLazyGetIntegrationAuthenticationUrlQuery,
  useGetDocumentsForMatterQuery,
  useLazyPostSearchQuery,
  usePostSaveIntegrationMutation,
  useLazyGetMatterQuery,
  useUploadDocumentToIntegrationMutation,
  useLazyGetDocumentUploadStatusQuery,
} = integrationsApi;
