import React from 'react';
import { Button, DialogActions, DialogContent, Stack, Alert, Typography, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setConfirmationDialog, resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';

const ConfirmationDialog = () => {
    const dispatch = useDispatch();
    const { confirmationDialog } = useSelector((state) => state.GlobalDialogues);
    const { data, callbacks } = confirmationDialog;
    const { title, text, confirmText, cancelText, hasAlert, alertSeverity, alertText } = data;

    const handleConfirm = () => {
        callbacks?.handleConfirm();
        dispatch(resetGlobalDialogues());
    };

    const handleCancel = () => {
        callbacks?.handleCancel()
        dispatch(resetGlobalDialogues());
    };

    return (
        <>
            <DialogContent>
                <Stack spacing={2}>
                    {hasAlert && (
                        <Alert severity={alertSeverity || "warning"}>
                            {alertText}
                        </Alert>
                    )}
                    <Typography variant="h6">{title}</Typography>
                    <Typography variant="body1">{text}</Typography>
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button
                    color="secondary"
                    onClick={handleCancel}
                >
                    {cancelText || 'Cancel'}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleConfirm}
                >
                    {confirmText || 'Confirm'}
                </Button>
            </DialogActions>
        </>
    );
};

export default ConfirmationDialog;