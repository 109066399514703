/**
 * Build Column Generator
 * 
 * Generate a function that will return a MUI table column
 * that adheres to the given model's display / validation
 * configuration
 */
export const buildColumnGenerator = (displayConfig) => (fieldName, config) => {
  const { type, displayName, transformInput, formatDisplay, getSortableValue } = displayConfig[fieldName]

  return {
    type,
    field: fieldName,
    headerName: displayName,
    minWidth: 100,
    headerAlign: 'left',
    align: 'left',
    pinnable: false,
    valueGetter: ({ row }) => getSortableValue
      ? getSortableValue(row[fieldName])
      : row[fieldName],
    ...(transformInput ? { 
      valueSetter: ({ value, row}) => 
        ({...row, [fieldName]: transformInput(value)}) 
    }: {}),
    ...(type === 'date' && formatDisplay ? {
      valueFormatter: ({value}) => (formatDisplay(value))
    }: {}),
    ...(type !== 'date' && formatDisplay ? {
      valueParser: formatDisplay
    }: {}),
    ...config
  }
}