import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, LinearProgress, Box } from '@mui/material';
import { fetchAuthSession } from '@aws-amplify/auth';
import { useAuthenticator } from "@aws-amplify/ui-react";
import AuthenticationLoadingSpinner from './AuthenticationLoadingSpinner';
import { handleSignout } from "./Helpers";
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';

const SessionManager = ({ children }) => {
    const dispatch = useDispatch();
    const { authStatus } = useAuthenticator();
    const isAuthenticated = authStatus === "authenticated";

    const [username, setUsername] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const [loading, setLoading] = useState(true);
    const [timeRemaining, setTimeRemaining] = useState(0);
    const [isExpired, setIsExpired] = useState(false);

    // Countdown dialog state
    const [showCountdown, setShowCountdown] = useState(false);
    const [countdownSeconds, setCountdownSeconds] = useState(0);

    // Activity tracking
    const lastActivityTime = useRef(Date.now());
    const inactivityTimer = useRef(null);

    // Thresholds for countdown (in seconds)
    const COUNTDOWN_THRESHOLD = 5 * 60; // 5 minutes

    const REFRESH_SESSION_THRESHOLD = 60; // 1 minute
    const INACTIVITY_CHECK_INTERVAL = 60 * 1000; // Check every minute

    // Format seconds into HH:MM:SS format
    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;

        const formattedHours = hours > 0 ? `${hours}:` : '';
        const formattedMins = `${mins < 10 ? '0' : ''}${mins}`;
        const formattedSecs = `${secs < 10 ? '0' : ''}${secs}`;

        return `${formattedHours}${formattedMins}:${formattedSecs}`;
    };

    // Calculate progress percentage for LinearProgress
    const calculateProgress = (seconds) => {
        return (seconds / COUNTDOWN_THRESHOLD) * 100;
    };

    const getAuthenticationInfo = useCallback(async () => {
        try {
            const authSession = await fetchAuthSession();
            const accessToken = authSession?.tokens?.accessToken || "";
            const username = authSession?.userSub || "";
            setUsername(username);
            setAccessToken(accessToken);

            const currentTime = Math.floor(Date.now() / 1000);
            const timeRemaining = accessToken?.payload?.exp ? accessToken.payload.exp - currentTime : 0;
            setTimeRemaining(timeRemaining);

            if (timeRemaining <= 0) {
                setIsExpired(true);
                setShowCountdown(false);
            } else {
                setIsExpired(false);
                // Show countdown dialog if less than threshold
                if (timeRemaining <= COUNTDOWN_THRESHOLD) {
                    setShowCountdown(true);
                    setCountdownSeconds(timeRemaining);
                } else {
                    setShowCountdown(false);
                }
            }
        } catch (error) {
            console.error("Error fetching authentication info:", error);
            setIsExpired(true);
        } finally {
            setLoading(false);
        }
    }, []);

    // Debounced function to handle user activity
    const handleUserActivity = useCallback(
        debounce(() => {
            console.log("User activity detected");
            lastActivityTime.current = Date.now();
            // Check session validity when user becomes active
            getAuthenticationInfo();
        }, 1000),
        // 1 second debounce (typically fires every 3-4 seconds during constant activity- but doesn't require api call)
        // fires within 1 second is user inactive for a few seconds; and then becomes active again
        [getAuthenticationInfo]
    );

    // Check for inactivity
    const setupInactivityChecker = useCallback(() => {
        if (inactivityTimer.current) {
            clearInterval(inactivityTimer.current);
        }

        inactivityTimer.current = setInterval(() => {
            const currentTime = Date.now();
            const timeSinceLastActivity = currentTime - lastActivityTime.current;

            // If user has been inactive for more than 2 minutes, check session
            if (timeSinceLastActivity > 2 * 60 * 1000) {
                getAuthenticationInfo();
            }
        }, INACTIVITY_CHECK_INTERVAL);
    }, [getAuthenticationInfo]);

    // Set up activity listeners
    useEffect(() => {
        const activityEvents = [
            'mousedown', 'mousemove', 'keypress',
            'scroll', 'touchstart', 'click', 'keydown',
            'DOMMouseScroll', 'mousewheel', 'touchmove'
        ];

        activityEvents.forEach(event => {
            window.addEventListener(event, handleUserActivity);
        });

        // Set up special listeners for visibility changes
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                handleUserActivity();
            }
        });

        // Initial setup
        lastActivityTime.current = Date.now();
        handleUserActivity();
        setupInactivityChecker();

        return () => {
            if (inactivityTimer.current) {
                clearInterval(inactivityTimer.current);
            }

            activityEvents.forEach(event => {
                window.removeEventListener(event, handleUserActivity);
            });

            document.removeEventListener('visibilitychange', handleUserActivity);
        };
    }, [handleUserActivity, setupInactivityChecker]);

    // Initial auth check
    useEffect(() => {
        getAuthenticationInfo();

        // Set up an interval to check authentication regularly
        const checkInterval = setInterval(() => {
            getAuthenticationInfo();
        }, 60000); // Check every minute

        return () => {
            clearInterval(checkInterval);
            setUsername("");
            setAccessToken("");
            setLoading(true);
            setTimeRemaining(0);
            setIsExpired(false);
            setShowCountdown(false);
        };
    }, [getAuthenticationInfo]);

    // Countdown timer effect
    useEffect(() => {
        let timer;
        if (showCountdown && countdownSeconds > 0) {
            timer = setInterval(() => {
                setCountdownSeconds(prev => {
                    if (prev <= 1) {
                        clearInterval(timer);
                        setShowCountdown(false);
                        setIsExpired(true);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }

        return () => {
            if (timer) clearInterval(timer);
        };
    }, [showCountdown, countdownSeconds]);

    // Handler for refreshing the session
    const handleRefreshSession = async () => {
        try {
            await fetchAuthSession({ forceRefresh: true });
            await getAuthenticationInfo();

            // Explicitly close dialog and reset countdown state
            setShowCountdown(false);
            setCountdownSeconds(0);

            // Update last activity time
            lastActivityTime.current = Date.now();
        } catch (error) {
            console.error("Error refreshing session:", error);
        }
    };

    // Handler for logging out
    const handleLogout = () => {
        handleSignout(null, dispatch);
    };

    // Auto-logout when session expires
    useEffect(() => {
        if (isExpired) {
            handleLogout();
        }
    }, [isExpired]);

    if (loading) {
        return <AuthenticationLoadingSpinner />;
    }

    return (
        <>
            {!isExpired && isAuthenticated && children}

            {/* Session Expiration Countdown Dialog */}
            <Dialog
                open={showCountdown && !isExpired}
                onClose={() => { }} // Prevent closing by clicking outside
                disableEscapeKeyDown // Prevent closing with Escape key
                fullWidth
                maxWidth="sm"
            >
                <DialogContent>
                    <Typography variant="body1" gutterBottom>
                        Your session will expire in {formatTime(countdownSeconds)}
                    </Typography>
                    <Box sx={{ mt: 2, mb: 1 }}>
                        <LinearProgress
                            variant="determinate"
                            value={calculateProgress(countdownSeconds)}
                            color={"primary"}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLogout} color="secondary">
                        Sign out
                    </Button>
                    <Button onClick={handleRefreshSession} variant="contained" color="secondary">
                        Continue session
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SessionManager;