// metadata.demandTemplateId
export const META_DATA_TEMPLATE_ID = {
    path: 'metadata.demandTemplateId',
    label: 'Demand template',
    dataType: 'string',
    fieldType: 'select',
    required: true,
    validation: (value) => !value ? 'Required' : null
}

// metadata.clientTemplateId
export const META_DATA_CLIENT_TEMPLATE_ID = {
    path: 'metadata.clientTemplateId',
    label: 'Client template',
    dataType: 'string',
    fieldType: 'select',
    required: true,
    validation: (value) => !value ? 'Required' : null
}
