import React, { useState, useEffect, useMemo } from 'react';
import { Button, Stack, Typography, IconButton, Box, Tooltip, Alert } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { CloudUploadOutlined, DeleteOutlined, Computer } from '@mui/icons-material';
import { setDocumentUploaderList } from '../redux/slices/demandDomSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getIntegrationLogoUrl } from '../CustomerManagement/Integrations/Helpers';
import {
  setIntegrationDocumentSelectionModal,
  setSelectedRowsForDocumentsUploaded,
} from '../redux/slices/integrationsSlice';
import { useGetIntegrationsQuery, useGetIntegrationMetadataQuery } from '../services/integrations/integrationsApi';
import {
  DOCUMENT_UPLOAD_FILE_TYPES,
  DOCUMENT_UPLOAD_FILE_TYPES_LONG_VERBOSE,
  MAX_FILE_SIZE,
  getDocumentSourceDisplayName,
} from '../common-document';
import { green } from '@mui/material/colors';

const DocumentUploader = ({ heightOffset = '128', isFromDemand = false }) => {
  const dispatch = useDispatch();

  const { data: integrations, isLoading: isIntegrationsLoading } = useGetIntegrationsQuery();
  const { data: integrationMetadata = {} } = useGetIntegrationMetadataQuery();
  const { displayName: integrationName, integrationId } = integrationMetadata;

  const hasAnyIntegrationsForCustomer = Boolean(integrationMetadata?.integrationId);

  const { documentUploaderList } = useSelector((state) => state.DemandDom);
  const {
    integratedCase: { id: matterId, claimNumber },
  } = useSelector((state) => state.Integrations);

  const [borderColor, setBorderColor] = useState('rgba(0,0,0,0.12)');
  const [backgroundColor, setBackgroundColor] = useState('');

  const hasLinkedCase = matterId && claimNumber;

  useEffect(() => {
    return () => dispatch(setDocumentUploaderList([]));
  }, [dispatch]);

  const fileListWithId = documentUploaderList.map((file, index) => ({
    ...file,
    id: file.name + index,
    name: file.name,
  }));

  const handleInputChange = (e) => {
    const files = [...e.target.files].map((file) => {
      // Spread operator does not work on file object.

      file.isExternalFile = false;

      const fileType = file.type;
      if (!DOCUMENT_UPLOAD_FILE_TYPES_LONG_VERBOSE.includes(fileType)) {
        file.error = 'Invalid file type';
      } else if (file.size > MAX_FILE_SIZE) {
        file.error = 'File size cannot exceed 150MB';
      }
      return file;
    });

    const newFileList = [...documentUploaderList, ...files];
    const uniqueFiles = newFileList.filter(
      (file, index, self) => index === self.findIndex((t) => t.name === file.name)
    );

    dispatch(setDocumentUploaderList(uniqueFiles));
  };

  const setHoverStyles = () => {
    setBorderColor(green[800]);
    setBackgroundColor(green[50]);
  };

  const clearHoverStyles = () => {
    setBorderColor('rgba(0,0,0,0.12)');
    setBackgroundColor('');
  };

  const handleDragEvent = (e, callBack) => {
    e.preventDefault();
    e.stopPropagation();
    callBack();
  };

  const dragHandlers = {
    onDragEnter: (e) => handleDragEvent(e, setHoverStyles),
    onDragLeave: (e) => handleDragEvent(e, clearHoverStyles),
    onDragOver: (e) => handleDragEvent(e, setHoverStyles),
    onDrop: (e) => {
      handleDragEvent(e, clearHoverStyles);
      const files = [...e.dataTransfer.files];
      handleInputChange({ target: { files } });
    },
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 360,
      sortable: false,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body1">{params.row.name}</Typography>
          {params.row.error && (
            <Typography variant="caption" color="error">
              {params.row.error}
            </Typography>
          )}
        </Stack>
      ),
    },
    {
      field: 'sourceSystemId',
      headerName: 'Source',
      width: 150,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body1">
            {!isIntegrationsLoading
              ? getDocumentSourceDisplayName(params.row.sourceSystemId, params.row.detectedType, integrations)
              : ''}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            const newFileList = documentUploaderList.filter((file) => file.name !== params.row.name);
            dispatch(setDocumentUploaderList(newFileList));
            dispatch(setSelectedRowsForDocumentsUploaded(newFileList.map((file) => file.id)));
          }}
        >
          <DeleteOutlined />
        </IconButton>
      ),
    },
  ].filter((column) => column.field !== 'sourceSystemId' || hasAnyIntegrationsForCustomer);

  const integrationLogo = useMemo(
    () => (
      <img
        src={getIntegrationLogoUrl(integrationId || '')}
        alt={integrationName}
        style={{
          height: '20px',
          filter: !hasLinkedCase ? 'grayscale(100%) opacity(26%)' : 'none',
        }}
      />
    ),
    [integrationId, integrationName, hasLinkedCase]
  );

  const renderNoRowsContent = () => {
    return (
      <Stack alignItems="center" sx={{ height: '100%' }} spacing="4%" useFlexGap {...dragHandlers}>
        <Typography variant="h6" sx={{ mt: 'auto', textAlign: 'center' }}>
          Upload police reports, medical bills, and supporting documents
        </Typography>

        <Stack direction="row" spacing={2}>
          {Boolean(integrationId) && (
            <Tooltip
              title={
                hasLinkedCase
                  ? ''
                  : `Search and select a case ${isFromDemand ? 'from Edit demand details ' : ''}to upload documents`
              }
            >
              <span>
                <Button
                  startIcon={integrationLogo}
                  variant="outlined"
                  color="secondary"
                  disabled={!hasLinkedCase}
                  onClick={() =>
                    dispatch(
                      setIntegrationDocumentSelectionModal({
                        open: true,
                        ...(!isFromDemand && { data: { allowChangingLinkedCase: true } }),
                      })
                    )
                  }
                >
                  Upload from {integrationName}
                </Button>
              </span>
            </Tooltip>
          )}

          <span>
            <Button startIcon={<Computer />} variant="outlined" color="secondary" component="label">
              Upload from computer
              <input accept={DOCUMENT_UPLOAD_FILE_TYPES} type="file" hidden onChange={handleInputChange} multiple />
            </Button>
          </span>
        </Stack>

        <Typography variant="caption" sx={{ textAlign: 'center', color: 'text.secondary' }}>
          File types: PDF, JPG/JPEG, PNG, TIF/TIFF, DOC/DOCX
        </Typography>

        <Stack alignItems="center" spacing={1} sx={{ mb: 'auto' }}>
          <CloudUploadOutlined sx={{ fontSize: 50, color: 'secondary.dark' }} />
          <Typography variant="h6" color="secondary.dark">
            Drop files here
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const hasFileErrors = documentUploaderList.some((file) => file.error);

  return (
    <Box height={`calc(100vh - ${heightOffset}px)`} display="flex" flexDirection="column" {...dragHandlers}>
      {hasFileErrors && (
        <Alert severity="error" sx={{ mb: 2, width: '100%' }}>
          Some files cannot be uploaded. Please check the file types and sizes and delete the invalid files.
        </Alert>
      )}
      <Box sx={{ flex: 1, position: 'relative' }}>
        <Box sx={{ position: 'absolute', inset: 0 }}>
          <DataGridPro
            rows={fileListWithId}
            columns={columns}
            slots={{
              noRowsOverlay: renderNoRowsContent,
            }}
            sx={{
              backgroundColor: backgroundColor,
              border: '1px solid',
              borderColor: borderColor,
              height: '100%',
              '& .MuiDataGrid-root': {
                border: 'none',
              },
            }}
            disableColumnMenu
            disableColumnReorder
            disableColumnFilter
            disableColumnSelector
            disableColumnPinning
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DocumentUploader;
