import { green } from '@mui/material/colors';
import { InputAdornment } from '@mui/material';
import { CheckCircle, Error } from '@mui/icons-material';

export const successFieldStyle = {
    '& .MuiOutlinedInput-root': {
        backgroundColor: green[50],
        '& fieldset': {
            borderColor: 'success.dark',
        },
        '&:hover fieldset': {
            borderColor: 'success.dark',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'success.dark',
        },
        '& .MuiInputBase-input': {
            color: 'success.dark',
        },
    }
    ,
    '& .MuiInputLabel-root': {
        color: 'success.dark'
    }
};

export const successFieldInputProps = {
    endAdornment: (
        <InputAdornment position="end">
            <CheckCircle color="success" fontSize="small" />
        </InputAdornment>
    )
}

