import { getCurrentDate, formatCurrency, cleanNumber } from "../common";
import moment from "moment";
import { INCLUDED_STATUS } from "./insights";

//medical treatment alerts
export const getMedicalTreatmentAlerts = (documentData, viewType, hasMedicalTreatments, warningsOnly, medicals) => {

    //treatments before date of loss
    const dateOfLoss = documentData?.claimInfo?.dateOfLoss ? documentData?.claimInfo.dateOfLoss.slice(0, 10) : null;
    const medicalTreatments = medicals.medicalTreatments


    const hasTreatmentDatesBeforeDateOfLoss = medicalTreatments.filter((treatment) => {
        const momentTreatmentDate = moment(treatment.treatmentDate)
        const isTreatmentDateValid = momentTreatmentDate.isValid()
        const treatmentDate = isTreatmentDateValid ? momentTreatmentDate.format('YYYY-MM-DD').slice(0, 10) : null;
        if (moment(treatmentDate).isValid() && moment(dateOfLoss).isValid()) {
            return new Date(treatmentDate) < new Date(dateOfLoss)
        } else {
            return false
        }
    }).length > 0;

    //treatments after today
    const hasTreatmentDatesAfterToday = medicalTreatments.filter((treatment) => {
        const momentTreatmentDate = moment(treatment.treatmentDate)
        const isTreatmentDateValid = momentTreatmentDate.isValid()
        const treatmentDate = isTreatmentDateValid ? momentTreatmentDate.format('YYYY-MM-DD').slice(0, 10) : null;
        if (moment(treatmentDate).isValid()) {
            return new Date(treatmentDate) > new Date(getCurrentDate())
        } else {
            return false
        }
    }).length > 0;

    //demand damages amount not within 100 of medical treatments total
    const medicalTreatmentsTotalAmount = medicalTreatments.reduce((total, treatment) => total + Number(treatment.status === INCLUDED_STATUS ? (treatment.billedAmount || 0) : 0), 0);
    const demandDamagesAmount = Number(documentData?.damagesSummary?.demandDamagesAmount ? cleanNumber(documentData?.damagesSummary.demandDamagesAmount) : cleanNumber(medicalTreatmentsTotalAmount));

    // Utility function to round a number to two decimal places
    function roundToTwoDecimals(num) {
        return Math.round(num * 100) / 100;
    }

    const roundedMedicalTreatmentsTotalAmount = roundToTwoDecimals(medicalTreatmentsTotalAmount || 0);
    const roundedDemandDamagesAmount = roundToTwoDecimals(demandDamagesAmount || 0);
    const amountsNotEqual = roundedMedicalTreatmentsTotalAmount !== roundedDemandDamagesAmount;

    //conditionally include alerts
    const medicalTreatmentAlerts = [
        ...hasTreatmentDatesBeforeDateOfLoss && ['precedent', 'lawfirm'].includes(viewType) ? [{
            alertType: "treatmentBeforeDateOfLoss",
            alertMessage: "One or more treatments have a date of service prior to the date of loss",
            alertSeverity: "warning",
        }] : [],
        ...hasTreatmentDatesAfterToday && ['precedent', 'lawfirm'].includes(viewType) ? [{
            alertType: "treatmentAfterToday",
            alertMessage: "One or more treatments have a date of service in the future",
            alertSeverity: "warning",
        }] : [],
        ...amountsNotEqual && hasMedicalTreatments && !warningsOnly && ['precedent', 'lawfirm'].includes(viewType) ? [{
            alertType: "amountsNotEqual",
            alertMessage: `Precedent identified a total bill amount of ${formatCurrency(medicalTreatmentsTotalAmount)} which differs from the expected medical specials amount: ${formatCurrency(demandDamagesAmount)}`,
            alertSeverity: "info",
        }] : [],
    ]
    return medicalTreatmentAlerts;

}

export const getMedicalsIcdCodesAlerts = (isFlaggedForReview) => {
    if (isFlaggedForReview) {
        return [
            {
                alertType: "flaggedForReview",
                alertMessage: "ICD codes need review",
                alertSeverity: "warning",
            }
        ]
    } else {
        return []
    }
}

export const transformInsightMedicalsToFileMedicalsData = (medicalCharges) => {
  return {
    medicalTreatments: (medicalCharges ?? []).map(({ entityInsightId, fileEntityId, entityInsightData, ...medicalCharge }) => ({
      medicalTreatmentId: entityInsightId,
      fileEntityId,
      generatedPageNumber: entityInsightData.generated_page_number,
      sourcePageNumber: entityInsightData.page_number,
      billedAmount: entityInsightData.billedAmount ?? '',
      lineNumber: entityInsightData.line_number ?? '',
      tableNumber: entityInsightData.table_number ?? '',
      treatmentDate: entityInsightData.treatmentDate ?? '',
      treatmentFacility: entityInsightData.treatmentFacility ?? '',
      treatmentType: entityInsightData.treatmentType ?? '',
      treatments: entityInsightData.treatments ?? '',
      diagnoses: entityInsightData.diagnoses ?? '',
      status: entityInsightData.status ?? INCLUDED_STATUS,
      boundingBox: { ...entityInsightData.boundingBox },
      ...medicalCharge,
    })),
  };
};

export const transformFileMedicalsToInsightMedicalsData = (medicalTreatments = []) =>
  medicalTreatments.map(
    ({
      medicalTreatmentId,
      diagnoses,
      treatments,
      boundingBox,
      lineNumber,
      tableNumber,
      sourcePageNumber,
      billedAmount,
      treatmentDate,
      treatmentType,
      treatmentFacility,
      generatedPageNumber,
      status,
      ...medicalTreatment
    }) => ({
      entityInsightId: medicalTreatmentId,
      entityInsightData: {
        diagnoses,
        treatments,
        boundingBox,
        line_number: lineNumber,
        page_number: sourcePageNumber,
        billedAmount,
        table_number: tableNumber,
        treatmentDate,
        treatmentType,
        treatmentFacility,
        status: status
      },
      ...medicalTreatment,
    })
  );
