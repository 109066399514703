import { is } from 'date-fns/locale';
import { isValidPhoneNumber, isValidEmail } from '../../ValidationHelpers';

export const MUTATORS_SHOW_ADJUSTER_CONTACT_INFO = {
    path: 'mutators.showAdjusterContactInfo',
    label: 'Adjuster contact info (optional)',
    required: false,
    fieldType: 'switch',
    dataType: 'boolean',
    validation: undefined
}

export const RECIPIENT_ADJUSTER_PHONE_NUMBER = {
    path: 'recipientAdjuster.contactInfo.phoneNumber',
    label: 'Adjuster phone',
    required: false,
    fieldType: 'text',
    dataType: 'string',
    validation: (value) => value && !isValidPhoneNumber(value) ? 'Invalid phone number' : undefined
}

export const RECIPIENT_ADJUSTER_EMAIL_ADDRESS = {
    path: 'recipientAdjuster.contactInfo.emailAddress',
    label: 'Adjuster email',
    required: false,
    fieldType: 'text',
    dataType: 'string',
    validation: (value) => value && !isValidEmail(value) ? 'Invalid email' : undefined
}

export const RECIPIENT_ADJUSTER_FIRST_NAME = {
    path: 'recipientAdjuster.firstName',
    label: 'Adjuster first name',
    required: false,
    fieldType: 'text',
    dataType: 'string',
    validation: undefined
}

export const RECIPIENT_ADJUSTER_LAST_NAME = {
    path: 'recipientAdjuster.lastName',
    label: 'Adjuster last name',
    required: false,
    fieldType: 'text',
    dataType: 'string',
    validation: undefined
}