import { createSlice } from "@reduxjs/toolkit";
import { fetchDocumentData } from '../thunks/documentData'
import DocumentDataApiSlice from '../../services/documentData/documentDataApi';

const initialDocumentSelectionModalState = {
    open: false,
    data: {
        allowChangingLinkedCase: false,
    },
};

const IntegrationsSlice = createSlice({
    name: "Integrations",
    initialState: {
        addIntegrationModalOpen: false,
        integrationDocumentSelectionModal: initialDocumentSelectionModalState,
        integratedCase: {},
        pendingSelectedCase: {},
        isNewCaseSelected: false,
        selectedRowsForDocumentsUploaded: []
    },
    reducers: {
        setAddIntegrationModalOpen: (state, action) => {
            state.addIntegrationModalOpen = action.payload;
        },
        setIntegrationDocumentSelectionModal: (state, action) => {
            state.integrationDocumentSelectionModal = {
                ...state.integrationDocumentSelectionModal,
                ...action.payload,
            };
        },
        resetIntegrationDocumentSelectionModal: (state) => {
            state.integrationDocumentSelectionModal = initialDocumentSelectionModalState;
        },
        setIntegratedCase: (state, action) => {
            state.integratedCase = action.payload;
            state.isNewCaseSelected = true;
            state.pendingSelectedCase = {};
        },
        setPendingSelectedCase: (state, action) => {
            state.pendingSelectedCase = action.payload;
        },
        setIsNewCaseSelected: (state, action) => {
            state.isNewCaseSelected = action.payload;
        },
        resetIntegrationState: (state) => {
            state.integratedCase = {};
            state.pendingSelectedCase = {};
            state.isNewCaseSelected = false;
        },
        setSelectedRowsForDocumentsUploaded: (state, action) => {
            state.selectedRowsForDocumentsUploaded = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => {
                    const isGetDocumentDataAction = action.type === 'api/executeQuery/fulfilled' && action.meta?.arg?.endpointName === 'getDocumentData';
                    return fetchDocumentData?.fulfilled.match(action) || DocumentDataApiSlice.endpoints.getDocumentData?.matchFulfilled(action) || isGetDocumentDataAction;
                },
                (state, action) => {
                    const { document } = action.payload;
                    if (document?.caseManagementMetadata && Object.keys(document?.caseManagementMetadata).length > 0) {
                        state.integratedCase = {
                            id: document?.caseManagementMetadata?.matterTechId,
                            claimNumber: document?.caseManagementMetadata?.matterId,
                            integrationId: document?.caseManagementMetadata?.sourceSystemId,
                        };
                    }
                }
            )
    }
});

export const {
    setAddIntegrationModalOpen,
    setIntegrationDocumentSelectionModal,
    resetIntegrationDocumentSelectionModal,
    setIntegratedCase,
    setPendingSelectedCase,
    setIsNewCaseSelected,
    resetIntegrationState,
    setSelectedRowsForDocumentsUploaded
} = IntegrationsSlice.actions;

export default IntegrationsSlice.reducer;

