import React from 'react';
import { MenuItem } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { TextField, Autocomplete, Switches } from 'mui-rff';
import {
    RECIPIENT_CARRIER_COMMON_NAME, RECIPIENT_CARRIER_FAX_NUMBER, RECIPIENT_CARRIER_EMAIL_ADDRESS,
    RECIPIENT_CARRIER_PHONE_NUMBER, RECIPIENT_CARRIER_ADDRESS_1, RECIPIENT_CARRIER_ADDRESS_2,
    RECIPIENT_CARRIER_CITY, RECIPIENT_CARRIER_STATE, RECIPIENT_CARRIER_ZIP_CODE, 
    RECIPIENT_CARRIER_SHOW_CONTACT_INFO
} from '../Schemas/RecipientCarrier';
import { getSingleSelectAutoCompleteFilteredOptions, getStateLabelValues } from '../Helpers';
import { formatPhoneNumber } from '../../../common';

export const RecipientCarrierFaxNumber = () => {
    return (
        <TextField
            fullWidth
            required={RECIPIENT_CARRIER_FAX_NUMBER.required}
            label={RECIPIENT_CARRIER_FAX_NUMBER.label}
            name={RECIPIENT_CARRIER_FAX_NUMBER.path}
            type='tel'
            autoComplete='off'
            onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)}
        />
    )
}

// recipientCarrier?.contactInfo?.emailAddress
export const RecipientCarrierEmailAddress = () => {
    return (
        <TextField
            fullWidth
            required={RECIPIENT_CARRIER_EMAIL_ADDRESS.required}
            label={RECIPIENT_CARRIER_EMAIL_ADDRESS.label}
            name={RECIPIENT_CARRIER_EMAIL_ADDRESS.path}
            type='email'
            autoComplete='off'
            fieldProps={{ validate: RECIPIENT_CARRIER_EMAIL_ADDRESS.validation }}
        />
    )
}

// recipientCarrier?.carrierCommonName
export const RecipientCarrierCommonName = ({ form, carrier_auto_complete_data }) => {
    return (
        <Autocomplete
            name={RECIPIENT_CARRIER_COMMON_NAME.path}
            required={RECIPIENT_CARRIER_COMMON_NAME.required}
            label={RECIPIENT_CARRIER_COMMON_NAME.label}
            freeSolo
            fullWidth
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            blurOnSelect
            options={carrier_auto_complete_data}
            filterOptions={(options, params) => {
                const filtered = getSingleSelectAutoCompleteFilteredOptions(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.label);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        value: { carrierCommonName: inputValue },
                        label: `Add "${inputValue}"`,
                    });
                }
                return filtered;
            }}
            isOptionEqualToValue={(option, value) => option?.label === value}
            fieldProps={{
                format: (value) => value?.value?.carrierCommonName,
                validate: RECIPIENT_CARRIER_COMMON_NAME.validation
            }}
            onChange={(e, selection) => {
                form.change('recipientCarrier', selection?.value || {
                    carrierCommonName: '',
                    carrierCustomerAliasId: '',
                    contactInfo: {
                        address: {
                            city: '',
                            state: '',
                            street1: '',
                            street2: '',
                            zipCode: ''
                        },
                        emailAddress: '',
                        faxNumber: '',
                        phoneNumber: ''
                    }
                })
            }}
        />
    )
}

// mutators.showCarrierContactInfo
export const RecipientCarrierContactInfoSwitch = ({ form }) => {
    return (
        <Switches
            name={RECIPIENT_CARRIER_SHOW_CONTACT_INFO.path}
            onChange={(e) => {
                const checked = e.target.checked;
                form.change(RECIPIENT_CARRIER_SHOW_CONTACT_INFO.path, checked)
                if (!checked) {
                    form.change(RECIPIENT_CARRIER_PHONE_NUMBER.path, '');
                    form.change(RECIPIENT_CARRIER_ADDRESS_1.path, '');
                    form.change(RECIPIENT_CARRIER_ADDRESS_2.path, '');
                    form.change(RECIPIENT_CARRIER_CITY.path, '');
                    form.change(RECIPIENT_CARRIER_STATE.path, '');
                    form.change(RECIPIENT_CARRIER_ZIP_CODE.path, '');
                }
            }}
            data={{ label: RECIPIENT_CARRIER_SHOW_CONTACT_INFO.label, value: true }}
            color="secondary"
        />
    )
}

export const RecipientCarrierContactInfo = () => {
    return (
        <>
            <Grid xs={12}>
                <TextField
                    name={RECIPIENT_CARRIER_PHONE_NUMBER.path}
                    required={RECIPIENT_CARRIER_PHONE_NUMBER.required}
                    label={RECIPIENT_CARRIER_PHONE_NUMBER.label}
                    type='tel'
                    autoComplete='off'
                    onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)}
                    fieldProps={{ validate: RECIPIENT_CARRIER_PHONE_NUMBER.validation }}
                />
            </Grid>

            <Grid xs={12} md={6}>
                <TextField
                    fullWidth
                    required={RECIPIENT_CARRIER_ADDRESS_1.required}
                    label={RECIPIENT_CARRIER_ADDRESS_1.label}
                    name={RECIPIENT_CARRIER_ADDRESS_1.path}
                />
            </Grid>
            <Grid xs={12} md={6}>
                <TextField
                    fullWidth
                    required={RECIPIENT_CARRIER_ADDRESS_2.required}
                    label={RECIPIENT_CARRIER_ADDRESS_2.label}
                    name={RECIPIENT_CARRIER_ADDRESS_2.path}
                />
            </Grid>
            <Grid xs={12} md={4}>
                <TextField
                    fullWidth
                    required={RECIPIENT_CARRIER_CITY.required}
                    label={RECIPIENT_CARRIER_CITY.label}
                    name={RECIPIENT_CARRIER_CITY.path}
                />
            </Grid>
            <Grid xs={6} md={4}>
                <TextField
                    fullWidth
                    required={RECIPIENT_CARRIER_STATE.required}
                    label={RECIPIENT_CARRIER_STATE.label}
                    name={RECIPIENT_CARRIER_STATE.path}
                    select
                >
                    {getStateLabelValues().map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid xs={6} md={4}>
                <TextField
                    fullWidth
                    required={RECIPIENT_CARRIER_ZIP_CODE.required}
                    label={RECIPIENT_CARRIER_ZIP_CODE.label}
                    name={RECIPIENT_CARRIER_ZIP_CODE.path}
                />
            </Grid>
        </>
    )
}