import { apiSlice } from '../apiSlice';

const pdfApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    // GET /document/{documentId}/pdfUrl
    getPdfUrl: builder.query({
      query: (documentId) => ({
        url: `/document/${documentId}/pdfUrl`,
        method: 'GET',
      })
    }),

    // GET /document/{documentId}/documentRegenerate
    getPdfRegenerationStatus: builder.query({
      query: (documentId) => ({
        url: `/document/${documentId}/documentRegenerate`,
        method: 'GET',
      })
    })
  })
});

export const {
  useGetPdfUrlQuery,
} = pdfApi;
