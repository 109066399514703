import { FilterList } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShowHeaderFilters } from '../redux/slices/inventorySlice'
import { grey } from '@mui/material/colors'

const ShowHeaderFilters = () => {
    const handleClick = () => dispatch(setShowHeaderFilters(!showHeaderFilters))


    const dispatch = useDispatch();
    const inventoryState = useSelector((state) => state.Inventory);

    const { showHeaderFilters } = inventoryState;



    return (
        <IconButton
            sx={{
                backgroundColor: showHeaderFilters && grey[300]
            }}
        >
            <FilterList onClick={handleClick} />
        </IconButton>
    )
}

export default ShowHeaderFilters