import React from 'react';
import {
  MenuControlsContainer,
  MenuButtonAlignCenter,
  MenuButtonAlignJustify,
  MenuButtonAlignLeft,
  MenuButtonAlignRight,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonBlockquote,
  MenuButtonRedo,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuButtonIndent,
  MenuButtonUnindent,
  MenuDivider,
  MenuButtonAddTable,
  TableMenuControls,
} from 'mui-tiptap';
import { Box } from '@mui/material';
import './ConfiguredRichTextEditor.css';
import { getIndent, getOutdent } from './CustomExtensions/indent.ts';
import MenuButtonGenerate from './CustomButtons/MenuButtonGenerate.jsx';
import MenuButtonPageBreak from './CustomButtons/MenuButtonPageBreak.jsx';

const EditorMenuControls = ({ editor, showGenerateButton, aiDataSourceConfig, fieldName, documentId, user, disabled }) => {
  // Helper function to prevent blur on button click
  const handleMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Box onMouseDown={handleMouseDown}>
      <MenuControlsContainer>
        {showGenerateButton && (
          <>
            <MenuButtonGenerate
              fieldName={fieldName}
              aiDataSourceConfig={aiDataSourceConfig}
              documentId={documentId}
              user={user}
              onMouseDown={handleMouseDown}
              disabled={disabled}
            />
            <MenuDivider />
          </>
        )}
        <MenuButtonBold />
        <MenuButtonItalic />
        <MenuButtonUnderline />
        <MenuDivider />
        <MenuButtonBlockquote />
        <MenuButtonBulletedList />
        <MenuButtonOrderedList />
        <MenuButtonUnindent
          tooltipLabel="Decrease indent"
          disabled={!editor?.isEditable}
          onClick={() => getOutdent()({ editor })}
        />
        <MenuButtonIndent
          tooltipLabel="Increase indent"
          disabled={!editor?.isEditable}
          onClick={() => getIndent()({ editor })}
        />
        <MenuDivider />
        <MenuButtonAlignLeft />
        <MenuButtonAlignCenter />
        <MenuButtonAlignRight />
        <MenuButtonAlignJustify />
        <MenuDivider />
        <MenuButtonPageBreak />
        <MenuDivider />
        <MenuButtonUndo />
        <MenuButtonRedo />
        <MenuDivider />
        <MenuButtonAddTable />
        {editor.isActive('table') && <TableMenuControls />}
      </MenuControlsContainer>
    </Box>
  );
};

export default EditorMenuControls