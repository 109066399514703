import React from 'react';
import { Tooltip, InputAdornment } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { formatDecimal } from '../../../common'
import { DAMAGES_SUMMARY_DEMAND_DAMAGES_AMOUNT } from '../Schemas/DamagesSummary';
import { TextField } from 'mui-rff';

export const DamagesSummaryDemandDamagesAmountField = () => {
    return (
        <TextField
            fullWidth
            required={DAMAGES_SUMMARY_DEMAND_DAMAGES_AMOUNT.required}
            label={DAMAGES_SUMMARY_DEMAND_DAMAGES_AMOUNT.label}
            name={DAMAGES_SUMMARY_DEMAND_DAMAGES_AMOUNT.path}
            onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                endAdornment: <Tooltip title="This optional field captures the expected amount of medical charges that have been uploaded for the demand." arrow placement="top">
                    <div style={{ marginTop: '8px' }}>
                        <InfoOutlined color="action" />
                    </div>
                </Tooltip>
            }}
        />
    )
}