import moment from 'moment';
import { isPrecedentUser } from '../../../common-roles';

export const DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE = {
    path: 'demandDetails.demandResponseDueDate',
    label: 'Demand response fixed due date',
    dataType: 'date',
    fieldType: 'date',
    required: false,
    validation: (value, userData) => {
        const isPrecedent = isPrecedentUser(userData);
        if (value) {
            if (!moment(value, 'YYYY-MM-DD', true).isValid()) return 'Invalid date';
            if (!isPrecedent && moment(value).isBefore(moment())) return 'Due date cannot be in the past';
            if (!isPrecedent && moment(value).isAfter(moment().add(50, 'years'))) return 'Due date must be more recent';
        }
        return null;
    }
}

export const DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE = {
    path: 'demandDetails.demandResponseRelativeDueDate',
    label: 'Relative days to respond',
    dataType: 'number',
    fieldType: 'number',
    required: false,
    validation: undefined
}

export const DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_1 = {
    path: 'demandDetails.demandResponseAddress.street1',
    label: 'Address line 1',
    required: false
}

export const DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_2 = {
    path: 'demandDetails.demandResponseAddress.street2',
    label: 'Address line 2',
    required: false,
    fieldType: 'text',
    dataType: 'string'
}

export const DEMAND_DETAILS_DEMAND_RESPONSE_CITY = {
    path: 'demandDetails.demandResponseAddress.city',
    label: 'City',
    required: false,
    fieldType: 'text',
    dataType: 'string'
}

export const DEMAND_DETAILS_DEMAND_RESPONSE_STATE = {
    path: 'demandDetails.demandResponseAddress.state',
    label: 'State',
    required: false,
    fieldType: 'select',
    dataType: 'string'
}

export const DEMAND_DETAILS_DEMAND_RESPONSE_ZIP_CODE = {
    path: 'demandDetails.demandResponseAddress.zipCode',
    label: 'Zip',
    required: false,
    fieldType: 'text',
    dataType: 'string'
}

export const DEMAND_DETAILS_DISPLAY_DEMAND_AMOUNT = {
    path: 'demandDetails.displayDemandAmount',
    label: 'Display demand amount in letter',
    required: true,
    fieldType: 'switch',
    dataType: 'boolean'
}

export const DEMAND_DETAILS_DEMAND_OFFER_TO_SETTLE = {
    path: 'demandDetails.demandOfferToSettleAmount',
    label: 'Demand amount',
    required: false,
    fieldType: 'text',
    dataType: 'string'
}

export const DEMAND_DETAILS_DISPLAY_POLICY_LIMIT_INDICATOR = {
    path: 'demandDetails.policyLimitDemandIndicator',
    label: 'This is a policy limit demand',
    required: false,
    fieldType: 'switch',
    dataType: 'boolean'
}

export const DAMAGES_SUMMARY_DEMAND_DAMAGES_AMOUNT = {
    path: 'damagesSummary.demandDamagesAmount',
    label: 'Expected medical specials amount',
    required: false,
    fieldType: 'text',
    dataType: 'string'
}


export const DEMAND_DETAILS_USING_RELATIVE_DUE_DATE = {
    path: 'demandDetails.usingRelativeDueDate',
    label: 'Use relative due date',
    required: false,
    fieldType: 'switch',
    dataType: 'boolean',
    validation: undefined
}

