import React from 'react';
import { TextField } from 'mui-rff';
import { cloneDeep, merge } from 'lodash';
import { MenuItem } from '@mui/material';
import { META_DATA_TEMPLATE_ID, META_DATA_CLIENT_TEMPLATE_ID } from '../Schemas/MetaData';

// 'metadata.demandTemplateId'
export const DemandTemplateField = ({ form, values, availableTemplates, isClientTemplate, isGlobalDemand }) => {
    const TEMPLATE_SCHEMA = isClientTemplate ? META_DATA_CLIENT_TEMPLATE_ID : META_DATA_TEMPLATE_ID;
    const label = !isGlobalDemand ? TEMPLATE_SCHEMA.label : `${isClientTemplate ? 'Client' : 'Global'} template`;


    return (
        <TextField
            label={label}
            name={TEMPLATE_SCHEMA.path}
            required={TEMPLATE_SCHEMA.required}
            select
            fullWidth
            fieldProps={{ validate: TEMPLATE_SCHEMA.validation }}
            onChange={(e) => {
                const templateId = e.target.value;
                form.change(TEMPLATE_SCHEMA.path, templateId);
                const selectedTemplate = availableTemplates.find(at => at.templateId === templateId);
                const selectedTemplateDefaultDemandConfig = selectedTemplate?.defaultDemandConfig || {};

                for (const key in selectedTemplateDefaultDemandConfig) {
                    const currentCorrespondingValues = cloneDeep(values[key]);
                    const defaultCorrespondingValues = cloneDeep(selectedTemplateDefaultDemandConfig[key]);
                    // If we have two objects, use lodash.merge.  Otherwise, use the defaultCorrespondingValues provided by selectedTemplate
                    const newCorrespondingValues = (typeof currentCorrespondingValues === 'object' && currentCorrespondingValues !== null
                        && typeof defaultCorrespondingValues === 'object' && defaultCorrespondingValues !== null) ?
                        merge(currentCorrespondingValues, defaultCorrespondingValues) :
                        defaultCorrespondingValues;

                    form.change(key, newCorrespondingValues);
                }
            }}
        >
            {availableTemplates.map((option) => {
                return (

                    <MenuItem key={option.templateId} value={option.templateId} >
                        {option.templateName}
                    </MenuItem>
                )
            })}
        </TextField>
    )
}