import React from 'react';
import { useEditor } from '@tiptap/react';
import { getEditorExtensions } from '../editorConfiguration.js';
import PopulatedEditor from '../PopulatedEditor.jsx';


const useConfiguredRichTextEditor = ({ 
  content,
  onChange,showGenerateButton = false,
  ...editorProps
}) => {


  const editor = useEditor({
    extensions: getEditorExtensions(),
    content,
    parseOptions: { preserveWhitespace: 'full' },
    onUpdate: onChange,
  });

  const EditorComponent = (
   <PopulatedEditor
     {...editorProps}
     editor={editor}
   />
  );

  return [editor, EditorComponent]

}

export default useConfiguredRichTextEditor;