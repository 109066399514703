import { apiSlice } from '../apiSlice';

const globalDemandsApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // POST /globalDocument
        saveGlobalDocument: builder.mutation({
            query: ({ data }) => ({
                url: `/globalDocument`,
                method: 'POST',
                body: JSON.stringify(data),
            }),
            invalidatesTags: (result) => {
                const { errors, documentId } = result;
                if (errors?.length > 0) {
                    //todo in future: handle errors
                    return
                } else
                    return [{ type: 'DocumentData', id: documentId }]
            }
        })
    })
});

export const {
    useSaveGlobalDocumentMutation
} = globalDemandsApi;

export default globalDemandsApi;

