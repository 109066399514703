import { reach, ValidationError } from "yup"
import { setToast } from "../redux/slices/globalToastSlice"

export const parseValidationErrors = (err) => {
  if(!err || !(err instanceof ValidationError)) {
    console.error('Failed to parse validation errors', err)
    return
  }

  return {
    isValid: false,
    errors: err.inner.map(({errors, path}) => ({ message: errors, field: path}))
  }
}

export const runValidation = ({ schema, abortEarly = false, target }) => {
   try {
      schema.validateSync(target, { abortEarly })
      return { isValid: true, errors: []}
    } catch (err) {
      return parseValidationErrors(err)
    }
}

/**
 * Use Validator
 * 
 * Returns a function that will validate a given model and  optionally
 * display a toast for validation issues
 */
export const useValidator = ({ dispatch, schema, abortEarly = false }) => ({ target, toast = false }) => {
  const validation = runValidation({schema, target, abortEarly})
  const { errors } = validation

  if (errors.length && toast && dispatch) {
    dispatch(setToast({
      isOpen: true, severity: 'error', message: errors.map((e) => e.message).join(', ')
    }))
  }

  return validation
}

export const validatePartial = async (schema, partial) => {
  const validityChecks = Object.entries(partial).map(
    ([k,v]) => reach(schema, k)
      .validate(v, { abortEarly: false })
      .then(() => ({ field: k, isValid: true, errors: [] }))
      .catch((err) => ({ field: k, isValid: false, errors: err.inner.reduce((acc, { errors }) => {
        return[...acc, ...errors]
      }, []),}))
  )
  const validity = await Promise.allSettled(validityChecks.map(p => p.catch(e => e)))

  return {
    isValid: validity.every(({ value: { isValid } }) => isValid),
    validation: validity.reduce((acc, { value: { field, ...rest} }) => ({ ...acc, [field]: { ...rest } }), {})
  }
}