import React, { useEffect, useState, useCallback, useRef } from 'react';
import PdfEditor from '../Features/PDF/PdfEditor/view.jsx';
import PdfRedactorDialog from './PdfRedactor/PdfRedactorDialog.jsx';
import { DataGridPro, useGridApiRef, gridClasses } from '@mui/x-data-grid-pro';
import {
  Dialog,
  AppBar,
  IconButton,
  Typography,
  Stack,
  Box,
  DialogContent,
  Button,
  DialogTitle,
  Tooltip,
  Switch,
  CircularProgress,
} from '@mui/material';
import { ArrowBack, Close } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import InsertPageBreakOutlinedIcon from '@mui/icons-material/InsertPageBreakOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import Title from '../CommonComponents/Title';
import { Transition } from '../Transition';
import { theme } from '../Theme';
import { useDispatch, useSelector } from 'react-redux';
import {
  setEditAllDocumentsOpen,
  setEditOneDocumentFileName,
  setDocumentEdit3Url,
  setRedactDocumentOpen,
  setEditDocumentPdfOpen,
  setFileDisabled,
  setFileSaveStatusMap,
  setPdfPanelOpen,
} from '../redux/slices/demandDomSlice';
import {
  setAreDocumentFilesSaving,
  setSelectedRows,
  setSequencedDocumentFiles,
} from '../redux/slices/editDocumentsSlice';
import { deleteFile, getFileUrl } from '../api';
import { setShouldDeleteFile } from '../redux/slices/documentSlice';
import { setConfirmDeleteFile, setDocumentEditingConfirmation } from '../redux/slices/globalDialogueSlice';
import LoadingStatusChip from '../CommonComponents/Chips/LoadingStatusChip.jsx';
import { DiscardWork } from '../dialogs/DialogText.js';
import { apiSlice } from '../services/apiSlice.js';
import { setPdfUrlPollingLoadingSkeleton } from '../services/pdf/customSlice';
import { pollPdfRegenerationStatus, viewPageInDocument } from '../services/pdf/thunks.js';
import {
  useGetAllFilesByDocumentIdQuery,
  usePostDocumentFileEntityDataMutation,
  usePostFilesSequenceMutation,
} from '../services/documentFiles/documentFilesApi';
import { setToast } from '../redux/slices/globalToastSlice.js';
import { insightInvalidationTags } from '../MedicalsComponents/insights.js';
import { useParams } from 'react-router-dom';
import ResizableWrapWithPdfViewer from '../CommonComponents/ResizableWrapWithPdfViewer.jsx';
import { isDemandOrCoverLetter } from '../common-document.js';
import RedactIcon from '../assets/redact.svg';
import BulkMoveDocuments from './BulkMoveDocuments';

const MAX_DISPLAY_NAME_LENGTH = 150;

const dataGridStyles = {
  height: 'calc(100vh - 130px)',
  '.coverLetterRow:hover': {
    cursor: 'not-allowed',
  },
  '.MuiDataGrid-rowReorderCell--draggable': {
    color: 'rgba(0,0,0,0.54)',
  },
  '.MuiDataGrid-row.Mui-selected': {
    backgroundColor: '#e5f5fd',
  },
  '.MuiDataGrid-row.Mui-selected.Mui-hovered': {
    backgroundColor: '#e5f5fd',
  },
  '& .editDisplayNameButton': {
    opacity: 0,
  },
  [`& .${gridClasses.row}:hover`]: {
    '.editDisplayNameButton': {
      opacity: 1,
    },
  },
};

const pinnedColumns = { left: ['__reorder__', '__check__'] };

const isCoverLetter = (detectedType) => detectedType === 'cover letter';

const EditDocuments = () => {
  const { documentId } = useParams();
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const dividerPageFileId = useRef('');

  const { data: documentFiles = [], isLoading: documentFilesLoading } = useGetAllFilesByDocumentIdQuery(documentId, {
    refetchOnMountOrArgChange: true,
  });
  const [saveDocumentFileEntityData] = usePostDocumentFileEntityDataMutation();
  const [saveFileSequence] = usePostFilesSequenceMutation();

  const {
    editAllDocumentsOpen,
    editOneDocumentFileName,
    documentEditS3Url,
    editDocumentPdfOpen,
    redactDocumentOpen,
    fileDisabled,
    fileSaveStatusMap,
    pdfPanelOpen,
  } = useSelector((state) => state.DemandDom);
  const documentDataLoading = useSelector((state) => state.Document.documentDataLoading)[documentId] ?? false;
  const { shouldDeleteFile, usesDividerPages } = useSelector((state) => state.Document);
  const { user } = useSelector((state) => state.User);
  const {
    sequencedDocumentFiles,
    selectedRows,
    areDocumentFilesSaving: isSaving,
  } = useSelector((state) => state.EditDocuments);
  const { currentPage, currentBoundingBoxes, currentPageTrigger } = useSelector((state) => state.PdfCustomSlice);

  const [isDirty, setIsDirty] = useState(false);
  const [file, setFile] = useState(null);
  const [newFormState, setNewFormState] = useState({});
  const [originalFormState, setOriginalFormState] = useState({});
  const [isRowOrderUpdated, setIsRowOrderUpdated] = useState(false);
  const [isCellEditingMode, setIsCellEditingMode] = useState(false);
  const [columnWidths, setColumnWidths] = useState({}); // State to track column widths


  useEffect(() => {
    return () => {
      closeAndReset();
    };
  }, []);

  useEffect(() => {
    if (!documentFilesLoading && sequencedDocumentFiles.length > 0) {
      const filesMapById = sequencedDocumentFiles.reduce((acc, file) => {
        if (!isCoverLetter(file.fileEntityData?.detectedType)) {
          acc[file.fileEntityId] = {
            displayName: file?.fileEntityData?.aliasFileName ?? '',
            hasDividerPage: Boolean(file?.fileEntityData?.dividerPageId),
          };
        }
        return acc;
      }, {});

      setOriginalFormState(filesMapById);
      const demandLetter = sequencedDocumentFiles.find((file) => file.fileEntityData?.detectedType === 'demand letter');
      dividerPageFileId.current = demandLetter?.fileEntityId ?? '';
    }
  }, [sequencedDocumentFiles, documentFilesLoading]);

  useEffect(() => {
    setIsDirty(false);
    setIsRowOrderUpdated(false);
    setNewFormState({});
    dispatch(setFileSaveStatusMap({}));
    if (editAllDocumentsOpen) {
      dispatch(setSequencedDocumentFiles(documentFiles));
    }
  }, [editAllDocumentsOpen]);

  useEffect(() => {
    if (shouldDeleteFile) {
      dispatch(
        setToast({
          isOpen: true,
          message: `Deleting ${file?.fileEntityData?.fileName}...`,
          severity: 'info',
          duration: 1700,
        })
      );
      dispatch(setPdfUrlPollingLoadingSkeleton({ [documentId]: true })); //set polling loading status before we poll for ui updates
      dispatch(setFileDisabled(file.fileEntityId));
      dispatch(setFileSaveStatusMap({ ...fileSaveStatusMap, [file.fileEntityId]: 'Deleting' }));
      deleteFile(file.fileEntityId, user)
        .then(() => {
          const newLocalFileList = [...documentFiles].filter(
            (localFile) => localFile.fileEntityId !== file.fileEntityId
          );
          dispatch(setSequencedDocumentFiles(newLocalFileList));
          dispatch(apiSlice.endpoints.getAllFilesByDocumentId.initiate(documentId, { forceRefetch: true }));
          // Refresh insight entities data
          dispatch(apiSlice.util.invalidateTags(insightInvalidationTags));
          dispatch(pollPdfRegenerationStatus({ documentId, user }));
          dispatch(setShouldDeleteFile(false));
          setTimeout(() => {
            dispatch(
              setToast({
                isOpen: true,
                message: `${file?.fileEntityData?.fileName} deleted successfully`,
                severity: 'success',
              })
            );
          }, 1700);

          dispatch(setFileDisabled(''));
          const newFileStatusMap = { ...fileSaveStatusMap };
          delete newFileStatusMap[file.fileEntityId];
          dispatch(setFileSaveStatusMap(newFileStatusMap));
        })
        .catch((error) => {
          console.log(error);
          dispatch(
            setToast({ isOpen: true, message: `Error deleting ${file?.fileEntityData?.fileName}`, severity: 'error' })
          );
          dispatch(setFileDisabled(''));
        });
    }
  }, [shouldDeleteFile]);

  const closeAndReset = () => {
    dispatch(setEditAllDocumentsOpen(false));
    dispatch(setSelectedRows([]));
  };

  const setIsSaving = (isSaving) => dispatch(setAreDocumentFilesSaving(isSaving));

  const handleEditFileClick = async (file, action) => {
    setFile(file);

    const response = await getFileUrl(file.fileEntityId, user)
      .then((response) => response.json())
      .catch((error) => {
        console.log(error);
        dispatch(setToast({ isOpen: true, message: 'Error fetching file', severity: 'error' }));
      });

    const s3Url = response?.s3Url || null;
    s3Url && dispatch(setDocumentEdit3Url(s3Url));
    s3Url && dispatch(setEditOneDocumentFileName(file.fileEntityData.fileName));
    action === 'redact' && dispatch(setRedactDocumentOpen(true));
    action === 'edit' && dispatch(setEditDocumentPdfOpen(true));
  };

  const handleRowOrderChange = (params) => {
    const filesCopy = [...sequencedDocumentFiles];
    const nonPinnedFiles = filesCopy.filter(
      (file) =>
        file.fileEntityData.detectedType !== 'cover letter' && file.fileEntityData.detectedType !== 'demand letter'
    );
    const pinnedFiles = filesCopy
      .filter(
        (file) =>
          file.fileEntityData.detectedType === 'cover letter' || file.fileEntityData.detectedType === 'demand letter'
      )
      .sort((a, b) => a.fileEntityData.detectedType.localeCompare(b.fileEntityData.detectedType));
    const { oldIndex, targetIndex } = params;
    const movedFile = nonPinnedFiles.splice(oldIndex, 1)[0];
    nonPinnedFiles.splice(targetIndex, 0, movedFile);
    dispatch(setSequencedDocumentFiles([...pinnedFiles, ...nonPinnedFiles]));
    setIsDirty(true);
    setIsRowOrderUpdated(true);
  };

  const handleSelectionModelChange = (newSelectionModel) => {
    // Filter out cover letter and demand letter from selection
    const filteredSelection = newSelectionModel.filter((id) => {
      const file = sequencedDocumentFiles.find((f) => f.fileEntityId === id);
      return file && !isDemandOrCoverLetter(file.fileEntityData?.detectedType);
    });
    dispatch(setSelectedRows(filteredSelection));
  };

  const handleClose = () => {
    !isDirty && closeAndReset();
    isDirty &&
      dispatch(
        setDocumentEditingConfirmation({
          open: true,
          data: {
            text: DiscardWork.text,
            confirmText: DiscardWork.confirmText,
            cancelText: DiscardWork.cancelText,
            source: 'editDocuments',
            buttonColor: 'error',
          },
        })
      );
  };

  const handleSubmit = async (saveAndClose = false) => {
    setIsSaving(true);
    dispatch(setToast({ isOpen: true, message: 'Saving document files', severity: 'info' }));

    if (saveAndClose) {
      closeAndReset();
    }

    const filesIdSequence = sequencedDocumentFiles.map((file) => file.fileEntityId);

    dispatch(setPdfUrlPollingLoadingSkeleton({ [documentId]: true }));

    const updatedFiles = Object.entries(newFormState).map(([id, { displayName, hasDividerPage }]) => {
      return {
        id,
        aliasFileName: displayName ?? originalFormState[id].displayName,
        hasDividerPage: hasDividerPage ?? originalFormState[id].hasDividerPage,
      };
    });

    try {
      if (Object.keys(newFormState).length > 0) {
        await saveDocumentFileEntityData({ documentId, updatedFilesList: updatedFiles });
      }
      if (isRowOrderUpdated) {
        await saveFileSequence({ documentId, orderedFiles: filesIdSequence });
      }

      dispatch(apiSlice.endpoints.getAllFilesByDocumentId.initiate(documentId, { forceRefetch: true }));
      dispatch(pollPdfRegenerationStatus({ documentId }));
      // Refresh insight entities data
      dispatch(apiSlice.util.invalidateTags(insightInvalidationTags));
      dispatch(setToast({ isOpen: true, message: 'Document files updated successfully', severity: 'success' }));

      setIsDirty(false);
      setIsRowOrderUpdated(false);
    } catch (error) {
      dispatch(setToast({ isOpen: true, message: 'Error updating files', severity: 'error' }));
    }

    setIsSaving(false);
  };

  const handleEditDisplayName = (rowId, field) => {
    apiRef.current.startCellEditMode({ id: rowId, field });
    setIsCellEditingMode(true);
  };

  const handleCellEditStop = useCallback(() => setIsCellEditingMode(false), []);

  const processRowUpdate = (newRow) => {
    setNewFormState((prevState) => {
      return {
        ...prevState,
        [newRow.id]: {
          ...prevState[newRow.id],
          displayName: newRow.displayName,
        },
      };
    });
    setIsDirty(true);
    return newRow;
  };

  const getIsCellEditable = useCallback(({ row }) => !isCoverLetter(row.detectedType), []);

  const getIsRowSelectable = useCallback(({ row }) => !row.pinned, []);

  const getRowClassName = useCallback((params) => {
    const rowClassName = params.indexRelativeToCurrentPage % 2 === 0 ? 'doc-row-even' : 'doc-row-odd';
    if (isCoverLetter(params.row.detectedType)) {
      return `${rowClassName} coverLetterRow`;
    }
    return rowClassName;
  }, []);

  const getRowHeight = useCallback(() => 'auto', []);

  const handleResizeStop = useCallback(
    (params) => {
      setColumnWidths((prevWidths) => ({
        ...prevWidths,
        [params.colDef.field]: params.colDef.width,
      }));

      // Force a row height recalculation after resizing
      apiRef.current.resetRowHeights();
    },
    [apiRef]
  );


  const rows = sequencedDocumentFiles.map(({ fileEntityId, ...file }) => {
    return {
      ...file,
      id: fileEntityId,
      fileEntityId,
      fileName: file.fileEntityData?.fileName || '',
      displayName: newFormState[fileEntityId]?.displayName ?? originalFormState[fileEntityId]?.displayName,
      hasDividerPage: newFormState[fileEntityId]?.hasDividerPage ?? originalFormState[fileEntityId]?.hasDividerPage,
      detectedType: file.fileEntityData?.detectedType || '',
      pinned: isDemandOrCoverLetter(file.fileEntityData?.detectedType),
      __reorder__: file?.fileEntityData?.fileName,
    };
  });

  const columns = [
    ...(usesDividerPages
      ? [
        {
          field: 'hasDividerPage',
          headerName: 'Divider',
          resizable: false,
          type: 'boolean',
          width: 128,
          renderHeader: (params) => {
            const everyFileHasDivider =
              Object.keys(newFormState).length === Object.keys(originalFormState).length &&
              Object.entries(newFormState).every(
                ([id, { hasDividerPage }]) => hasDividerPage || id === dividerPageFileId.current
              );

            return (
              <>
                <Switch
                  {...params}
                  checked={everyFileHasDivider}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setNewFormState((prevState) => {
                      const newState = { ...prevState };
                      Object.keys(originalFormState).forEach((id) => {
                        newState[id] = {
                          ...prevState[id],
                          hasDividerPage: id === dividerPageFileId.current ? false : checked,
                        };
                      });
                      return newState;
                    });
                    setIsDirty(true);
                  }}
                />
                <Typography variant="subtitle2">Divider</Typography>
              </>
            );
          },
          renderCell: ({ row }) => {
            return (
              !isDemandOrCoverLetter(row.detectedType) && (
                <Switch
                  checked={row.hasDividerPage}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setNewFormState((prevState) => {
                      return {
                        ...prevState,
                        [row.id]: {
                          ...prevState[row.id],
                          hasDividerPage: checked,
                        },
                      };
                    });
                    setIsDirty(true);
                  }}
                />
              )
            );
          },
        },
      ]
      : []),
    {
      field: 'fileName',
      headerName: 'Document',
      resizable: true,
      width: columnWidths['fileName'] || 300,
      minWidth: 100,
      renderCell: ({ row }) => {
        const showSaveStatus = fileSaveStatusMap[row.fileEntityId] !== undefined;
        return (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            sx={{ minHeight: 42 }}
          >
            {row.hasDividerPage && <InsertPageBreakOutlinedIcon color="action" />}

            <Typography
              variant="tableP1"
              color="secondary"
              onClick={() => {
                if (!pdfPanelOpen) {
                  dispatch(setPdfPanelOpen(true));
                }
                setTimeout(
                  () => {
                    dispatch(viewPageInDocument(row.fileEntityData?.startPage, []));
                  },
                  !pdfPanelOpen ? 3000 : 0
                );
              }}
              sx={{
                cursor: 'pointer',
              }}
            >
              {row.fileName}
            </Typography>

            {showSaveStatus && (
              <LoadingStatusChip
                isLoading={fileDisabled === row.fileEntityId && !redactDocumentOpen && !editDocumentPdfOpen}
                label={fileSaveStatusMap[row.fileEntityId]}
              />
            )}
          </Stack>
        );
      },
    },
    {
      field: 'displayName',
      headerName: 'Display name',
      resizable: true,
      width: columnWidths['displayName'] || 200,
      minWidth: 100,
      editable: true,
      preProcessEditCellProps: ({ props }) => {
        const hasError = props.value.length > MAX_DISPLAY_NAME_LENGTH;
        return { ...props, error: hasError };
      },
      renderCell: ({ row, field }) => {
        return (
          <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Typography variant="tableP1">{row.displayName}</Typography>

            {!isCoverLetter(row.detectedType) && (
              <IconButton
                aria-label="Edit file display name"
                className="editDisplayNameButton"
                onClick={() => handleEditDisplayName(row.id, field)}
              >
                <DriveFileRenameOutlineOutlinedIcon />
              </IconButton>
            )}
          </Stack>
        );
      },
    },
    {
      field: 'pages',
      headerName: 'Pages',
      resizable: false,
      width: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <Typography variant="tableP1">{params.row?.fileEntityData?.totalPages || ''}</Typography>;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      resizable: false,
      width: 175,
      headerAlign: 'center',
      renderCell: (params) => {
        const { fileEntityId, fileName } = params.row;
        const fileLoading = fileDisabled === fileEntityId;

        return (
          <Stack
            direction="row"
            justifyContent={'center'}
            sx={{ width: '100%' }}
          >
            {isDemandOrCoverLetter(params.row.detectedType) ? (
              ''
            ) : (
              <Stack
                direction="row"
                spacing={1}
              >
                <Tooltip title={'Edit'} arrow>
                  <div>
                    <IconButton
                      aria-label="Edit"
                      disabled={fileLoading}
                      onClick={() => handleEditFileClick(params.row, 'edit')}
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </div>
                </Tooltip>
                <Tooltip title="Redact" arrow>
                  <div>
                    <IconButton
                      aria-label="Redact"
                      disabled={fileLoading}
                      onClick={() => handleEditFileClick(params.row, 'redact')}
                    >
                      <img src={RedactIcon} />
                    </IconButton>
                  </div>
                </Tooltip>
                <Tooltip title={'Delete'} arrow>
                  <div>
                    <IconButton
                      aria-label="Delete"
                      disabled={fileLoading}
                      onClick={() => {
                        dispatch(
                          setConfirmDeleteFile({ open: true, data: { fileName, fileEntityId, documentId, user } })
                        );
                        setFile(params.row);
                      }}
                    >
                      <DeleteOutlinedIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </Stack>
            )}
          </Stack>
        );
      },
    },
  ];

  const columnsWithOptions = columns.map((column) => {
    return {
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      disableColumnSelector: true,
      disableReorder: true,
      ...column,
    };
  });

  const getPinnedRows = {
    top: rows.filter((row) => row.pinned).sort((a, b) => a.detectedType.localeCompare(b.detectedType)),
    bottom: [],
  };

  return (
    <Dialog
      fullScreen
      open={editAllDocumentsOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      disableEscapeKeyDown={isCellEditingMode}
    >
      <AppBar sx={{ position: 'relative' }}>
        <DialogTitle
          className="dialog-title-blue"
          sx={{ height: '60px', width: '100%', zIndex: 1000 }}
          display="flex"
          alignItems="center"
          position={'fixed'}
        >
          <Stack direction={'row'} style={{ width: '100%' }} alignItems={'center'} justifyContent={'space-between'}>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <IconButton
                onClick={handleClose}
                aria-label="close"
                className='dark-bg-icon-button'
              >
                <Close color={'white'} />
              </IconButton>

              <Title color={theme.palette.white.main}>Edit documents</Title>
            </Stack>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <Button
                disabled={Boolean(!isDirty || isSaving || fileDisabled)}
                variant="outlined"
                color="white"
                className='dark-bg-button-outlined'
                onClick={() => handleSubmit(false)}
                startIcon={isSaving ? <CircularProgress size={20} color="inherit" /> : ''}
              >
                {isSaving ? 'Saving' : 'Save'}
              </Button>

              <Button
                disabled={Boolean(!isDirty || isSaving || fileDisabled)}
                variant="contained"
                color="white"
                className='dark-bg-button-contained'
                onClick={() => handleSubmit(true)}
              >
                Save & close
              </Button>
            </Stack>
          </Stack>
        </DialogTitle>
      </AppBar>

      <DialogContent sx={{ m: 0, py: 0, overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', mt: '70px' }}>
          <ResizableWrapWithPdfViewer
            defaultWidth={'60%'}
            pdfHeight={'90vh'}
            user={user}
            documentId={documentId}
            currentPage={currentPage}
            currentBoundingBoxes={currentBoundingBoxes}
            currentPageTrigger={currentPageTrigger}
            dividerTopMargin={'50px'}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
              <Typography variant="body1" color="primary">
                Drag and drop to arrange
              </Typography>

              {/* Only render BulkMoveDocuments when sequencedDocumentFiles is available */}
              {sequencedDocumentFiles && sequencedDocumentFiles.length > 0 && (
                <BulkMoveDocuments
                  selectedRows={selectedRows}
                  sequencedDocumentFiles={sequencedDocumentFiles}
                  setIsDirty={setIsDirty}
                  setIsRowOrderUpdated={setIsRowOrderUpdated}
                />
              )}
            </Stack>

            <DataGridPro
              rows={rows}
              columns={columnsWithOptions}
              apiRef={apiRef}
              rowReordering
              onRowOrderChange={handleRowOrderChange}
              processRowUpdate={processRowUpdate}
              pinnedRows={getPinnedRows}
              pinnedColumns={pinnedColumns}
              isCellEditable={getIsCellEditable}
              hideFooter
              loading={Boolean(isSaving || fileDisabled || documentDataLoading)}
              sx={dataGridStyles}
              getRowClassName={getRowClassName}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={handleSelectionModelChange}
              selectionModel={selectedRows}
              isRowSelectable={getIsRowSelectable}
              onCellEditStop={handleCellEditStop}
              getRowHeight={getRowHeight}
              onColumnWidthChange={handleResizeStop}

            />
          </ResizableWrapWithPdfViewer>
        </Box>
      </DialogContent>

      {!!editOneDocumentFileName && !!documentEditS3Url && (
        <Dialog open={editDocumentPdfOpen} fullScreen={true} fullWidth={true}>
          <DialogTitle
            className="dialog-title-blue"
            sx={{ height: '60px', width: '100%', zIndex: 1000 }}
            display="flex"
            alignItems="center"
            position={'fixed'}
          >
            <Stack direction={'row'} style={{ width: '100%' }} alignItems={'center'} justifyContent={'space-between'}>
              <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <IconButton
                  onClick={(e) => {
                    dispatch(setEditDocumentPdfOpen(false));
                  }}
                  aria-label="close"
                  sx={{ mr: 2, '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.15)' } }}
                >
                  <ArrowBack color={'white'} />
                </IconButton>

                <Title color={theme.palette.white.main}>Edit {editOneDocumentFileName}</Title>
              </Stack>
            </Stack>
          </DialogTitle>
          <PdfEditor
            demandS3Url={documentEditS3Url}
            user={user}
            documentId={documentId}
            file={file}
            setIsSaving={setIsSaving}
          />
        </Dialog>
      )}

      {!!editOneDocumentFileName && !!documentEditS3Url && (
        <Dialog open={redactDocumentOpen} fullScreen={true} fullWidth={true}>
          <PdfRedactorDialog
            demandS3Url={documentEditS3Url}
            user={user}
            documentId={documentId}
            file={file}
            setIsSaving={setIsSaving}
          />
        </Dialog>
      )}
    </Dialog>
  );
};

export default EditDocuments;
