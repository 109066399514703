import { object, string, number } from 'yup';


import { dateInPastValidator, dateStringValidator } from '../../Validations/custom/date';
import { EXCLUDED_STATUS, INCLUDED_STATUS, MEDICAL_CHARGES_INSIGHTS_TYPE, MRI_ENTITY_INSIGHTS_TYPE, NEEDS_REVIEW_STATUS } from '../../MedicalsComponents/insights';

/**
 * Medical Treatment Schema
 * 
 * Used to validate medicalTreatments data and inform of
 * any deviations from acceptable values
 */
export const medicalTreatmentSchema = object().shape({
  billedAmount: number().min(0, 'Charged amount cannot be negative').required(),
  description: string(),
  diagnoses: string(),
  entityInsightType: string().oneOf([MRI_ENTITY_INSIGHTS_TYPE, MEDICAL_CHARGES_INSIGHTS_TYPE]),
  fileEntityId: string(),
  generatedPageNumber: number(),
  lineNumber: number(),
  medicalTreatmentId: string(),
  procedure: string(),
  quantity: number().min(0),
  status: string().oneOf([INCLUDED_STATUS, EXCLUDED_STATUS, NEEDS_REVIEW_STATUS]),
  tableNumber: number(),
  treatmentFacility: string(),
  treatments: string(),
  treatmentType: string(),
  boundingBox: object().shape({
    bottom: number(),
    left: number(),
    right: number(),
    top: number(),
  }),
  startDate: string().test({
    name: 'startDate-valid',
    test: dateStringValidator('Start date')
  }),
  treatmentDate: string().test({
    name: 'startDate-in-past',
    test: dateInPastValidator('Treatment date')
  }).test({
    name: 'treatmentDate-valid',
    test: dateStringValidator('Treatment date'),
  }),
})
