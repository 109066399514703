import { apiSlice } from '../apiSlice';
import { setDemandScoreError } from './customSlice';
import { setToast } from '../../redux/slices/globalToastSlice';

const demandScoreApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // /document/{documentId}/generateDemandScore/{version}
        generateDemandScore: builder.query({
            query: ({ documentId, version }) => ({
                url: `/document/${documentId}/generateDemandScore/${version}`,
                method: 'GET',
            }),
            extraOptions: {
                maxRetries: 10,
            },
            async onQueryStarted({ documentId, version }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    dispatch(setDemandScoreError({
                        message: 'Demand score calculation failed after multiple attempts',
                        documentId
                    }));
                    console.error('Query failed after retries:', error);
                }
            },
        }),
    }),
});

export const {
    useGenerateDemandScoreQuery,
    useLazyGenerateDemandScoreQuery
} = demandScoreApi;

export default demandScoreApi;