import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCurrentCodeEditorContent, setInitialCodeEditorContent, setCodeEditorContentFetched } from '../slices/codeEditorSlice';
import { getDocumentApi, generateTemplatePreviewApi } from '../../api';
import { setToast } from '../slices/globalToastSlice';
import { setCurrentTab } from '../slices/templatePreviewSlice';

export const generateTemplatePreview = createAsyncThunk('TemplatePreview/generateTemplatePreview',

    async ({ templateText, user, documentId, overrideJsonBlobText = '' }, { dispatch, rejectWithValue }) => {

        return generateTemplatePreviewApi(user, documentId, templateText, overrideJsonBlobText)
            .then(async (serverResponse) => {
                const response = await serverResponse.json();
                if (serverResponse.status === 400) {
                    return rejectWithValue(response);
                }

                dispatch(setCurrentTab(1))
                return response;
            })
            .catch(err => {
                dispatch(setToast({ message: 'Error generating template preview', severity: 'error', isOpen: true }));
                return rejectWithValue(err);
            });
    });

export const getDocumentDataByIdForTemplatePreview = createAsyncThunk('TemplatePreview/getDocumentDataByIdForTemplatePreview',

    async ({ demandId, user }, { dispatch, rejectWithValue }) => {
        dispatch(setCodeEditorContentFetched({ parentComponentName: 'PreviewTemplate', fetched: false }));
        return getDocumentApi(demandId, user)
            .then(async (response) => {
                const responseData = await response.json();
                if (responseData?.document) {
                    const formattedJson = JSON.stringify(responseData.document, null, 4);
                    dispatch(setInitialCodeEditorContent({ parentComponentName: 'PreviewTemplate', content: formattedJson }));
                    dispatch(setCurrentCodeEditorContent({ parentComponentName: 'PreviewTemplate', content: formattedJson }));
                    dispatch(setCodeEditorContentFetched({ parentComponentName: 'PreviewTemplate', fetched: true }));
                    dispatch(setToast({ message: 'Successfully imported document data', severity: 'success', isOpen: true }));
                    return formattedJson;
                } else {
                    dispatch(setInitialCodeEditorContent({ parentComponentName: 'PreviewTemplate', content: 'Error in loading JSON' }));
                    dispatch(setCurrentCodeEditorContent({ parentComponentName: 'PreviewTemplate', content: 'Error in loading JSON' }));
                    dispatch(setCodeEditorContentFetched({ parentComponentName: 'PreviewTemplate', fetched: true }));
                    dispatch(setToast({ message: 'Error fetching document data', severity: 'error', isOpen: true }));
                    return rejectWithValue({ message: 'Error fetching document data' });
                }
            })
            .catch(err => {
                dispatch(setCodeEditorContentFetched({ parentComponentName: 'PreviewTemplate', fetched: true }));
                dispatch(setToast({ message: 'Error fetching document data', severity: 'error', isOpen: true }));
                return rejectWithValue(err);
            });
    }
);
