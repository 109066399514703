import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
} from '@mui/material';
import { useConfirmationDialog } from '../dialogs/contexts/DialogContext';
import DialogTitleBanner from '../CommonComponents/Dialogs/DialogComponents/DialogTitleBanner';
import { ArrowBack } from '@mui/icons-material';

const BulkEditConfirmation = () => {
  const { bulkEditConfirmation, resetBulkEditConfirmation } = useConfirmationDialog();
  const { handleOkCallback, handleCancelCallback, selectedCount, source, rows, isFullScreen } =
    bulkEditConfirmation;

  const confirmationTextDictionary = {
    medicalTreatments: {
      headerText: `You are about to edit ${selectedCount} medical treatment${selectedCount > 1 ? 's' : ''}:`,
      bodyText: 'Are you sure you want to continue?',
    },
    editDemandDetails: {
      headerText: `You are about to edit ${selectedCount} value${selectedCount > 1 ? 's' : ''}. Are you sure you want to apply these changes?`,
    },
  };

  const handleSave = () => {
    handleOkCallback();
    resetBulkEditConfirmation();
  };

  const handleCancel = () => {
    resetBulkEditConfirmation();
    handleCancelCallback();
  };

  return (
    <>
      {isFullScreen && (
        <DialogTitleBanner
          title="Confirm selection"
          handleClose={handleCancel}
          CloseIcon={ArrowBack}
          actions={
            <>
              <Button
                variant="outlined"
                color="white"
                className="dark-bg-button-outlined"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="white"
                className="dark-bg-button-contained"
                onClick={handleSave}
              >
                Apply changes
              </Button>
            </>
          }
        />
      )}
      <DialogContent
        sx={{
          ...(isFullScreen && { display: 'flex', flexDirection: 'column', alignItems: 'center' }),
        }}
      >
        <Stack spacing={2} sx={{ ...(isFullScreen && { width: '50%' }) }}>
          <Box>
            <Typography variant="body1">
              {confirmationTextDictionary[source]?.headerText}
            </Typography>
          </Box>
          <TableContainer
            sx={{
              borderRadius: 1,
              borderLeft: 1,
              borderRight: 1,
              borderTop: 1,
              borderColor: 'divider',
            }}
          >
            <Table
              size="small"
              sx={{
                '&:last-child': {
                  borderBottom: 0,
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Field</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Old value</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>New value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.field}>
                    <TableCell>{row.headerName}</TableCell>
                    <TableCell>{row.oldValue}</TableCell>
                    <TableCell>{row.newValue}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {confirmationTextDictionary[source]?.bodyText && (
            <Typography variant="body1">{confirmationTextDictionary[source]?.bodyText}</Typography>
          )}
        </Stack>
      </DialogContent>

      {!isFullScreen && (
        <DialogActions>
          <Button color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="contained" color="secondary" onClick={handleSave}>
            Apply changes
          </Button>
        </DialogActions>
      )}
    </>
  );
};

export default BulkEditConfirmation;
