import { formatDate, formatDecimal } from '../../common';
import { buildColumnGenerator } from '../dataGridUtils';

/**
 * Medical Treatments Display
 * 
 * Defines how medicalTreatments data should be displayed
 * and formatted across the application
 */
export const medicalTreatmentsDisplay = {
  treatmentFacility: {
    type: 'string',
    displayName: 'Facility',
    transformInput: (value) => value ? value.toUpperCase() : '',
    getSortableValue: (value) => value ? value.toUpperCase() : '',
    formatDisplay: (value) => value ? value.toUpperCase() : '',
  },
  treatmentType: {
    type: 'string',
    displayName: 'Type',
  },
  treatmentDate: {
    type: 'date',
    displayName: 'Date of service',
    transformInput: (value) => value ? new Date(value) : 0,
    getSortableValue: (value) => value ? new Date(value) : 0,
    formatDisplay: (value) => value ? formatDate(value) : '',
    transformSortable: true,
  },
  billedAmount: {
    type: 'string',
    displayName: 'Charged amount',
    transformInput: (newValue) => formatDecimal(newValue),
    formatDisplay: (value) => formatDecimal(value),
  },
  treatments: {
    type: 'string',
    displayName: 'Treatments',
  },
  generatedPageNumber: {
    type: 'number',
    displayName: 'Page',
  },
  status: {
    type: 'singleSelect',
    displayName: 'Status',
  }
}

// Coluumn generator to ensure medicalTreatments data is shown consistently across app
export const getMedicalTreatmentsColumn = buildColumnGenerator(medicalTreatmentsDisplay)