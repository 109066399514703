import { Card, CardContent, CardHeader, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'

const DetailCard = ({ icon, label, action, children }) => {
    return (

        <Card
            sx={{
                height: '100%',
                p: 1
            }}
        >
            <CardHeader
                avatar={icon}
                action={action}
                title={label}
                titleTypographyProps={{
                    color: "primary",
                    sx: {
                        fontSize: '1.2rem', // Adjust the font size as needed

                    },
                }}
                sx={{
                    p: 0,
                }}
            />
            <CardContent
                sx={{ pt: 1, px: 0, '&:last-child': { paddingBottom: 1 } }}
            >
                {children}
            </CardContent>
        </Card >

    )
}

export default DetailCard