import React from 'react';
import { MenuItem, Tooltip, InputAdornment } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { TextField, Switches } from 'mui-rff';
import { InfoOutlined } from '@mui/icons-material';
import { MAX_DAYS_RELATIVE_DUE_DATE, formatDecimal } from '../../../common';
import {
    DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE, DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE,
    DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_1, DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_2, DEMAND_DETAILS_DEMAND_RESPONSE_CITY,
    DEMAND_DETAILS_DEMAND_RESPONSE_STATE, DEMAND_DETAILS_DEMAND_RESPONSE_ZIP_CODE, DEMAND_DETAILS_DISPLAY_DEMAND_AMOUNT,
    DAMAGES_SUMMARY_DEMAND_DAMAGES_AMOUNT, DEMAND_DETAILS_DISPLAY_POLICY_LIMIT_INDICATOR, DEMAND_DETAILS_DEMAND_OFFER_TO_SETTLE,
    DEMAND_DETAILS_USING_RELATIVE_DUE_DATE


} from '../Schemas/DemandDetails';
import { CLAIM_INFO_POLICY_LIMIT_UNDERSTOOD_BY_FIRM } from '../Schemas/ClaimInfo';
import { getStateLabelValues } from '../Helpers';
import { getCurrentDate } from '../../../common';
import { get } from 'lodash';

export const DemandResponseDueDateField = ({ form, userData }) => {
    return (
        <TextField
            name={DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE.path}
            fullWidth
            required={DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE.required}
            inputProps={{ min: getCurrentDate() }}
            label={DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE.label}
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
                form.change(DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE.path, e.target.value)
                form.change(DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE.path, '')
            }}
            InputProps={{
                endAdornment:
                    <Tooltip title="Using a fixed due date will set the due date to a specific date." arrow placement="top">
                        <div style={{ marginTop: '8px' }}>
                            <InfoOutlined color="action" />
                        </div>
                    </Tooltip>
            }}
            fieldProps={{ validate: (value) => DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE.validation(value, userData) }}
        />
    )
}

export const DemandResponseRelativeDueDateField = ({ form }) => {
    return (
        <TextField
            required={DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE.required}
            name={DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE.path}
            label={DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE.label}
            type="number"
            fullWidth
            onChange={(e) => {
                //clip the input values to the range (1, MAX_DAYS_RELATIVE_DUE_DATE)
                e.target.value = Math.min(Math.max(parseInt(e.target.value), 1), MAX_DAYS_RELATIVE_DUE_DATE);
                form.change(DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE.path, e.target.value);
                form.change(DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE.path, '');
            }}
            onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Filter out non-integer values
            }}
            fieldProps={{ validate: DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE.validation }}
        />
    )
}

export const DemandDetailsContactInfoFields = () => {
    return (
        <Grid container spacing={2}>
            <Grid xs={12} md={6}>
                <TextField
                    fullWidth
                    required={DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_1.required}
                    label={DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_1.label}
                    name={DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_1.path}
                />
            </Grid>
            <Grid xs={12} md={6}>
                <TextField
                    fullWidth
                    required={DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_2.required}
                    label={DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_2.label}
                    name={DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_2.path}
                />
            </Grid>
            <Grid xs={12} md={4}>
                <TextField
                    fullWidth
                    required={DEMAND_DETAILS_DEMAND_RESPONSE_CITY.required}
                    label={DEMAND_DETAILS_DEMAND_RESPONSE_CITY.label}
                    name={DEMAND_DETAILS_DEMAND_RESPONSE_CITY.path}
                />
            </Grid>
            <Grid xs={6} md={4}>
                <TextField
                    fullWidth
                    required={DEMAND_DETAILS_DEMAND_RESPONSE_STATE.required}
                    label={DEMAND_DETAILS_DEMAND_RESPONSE_STATE.label}
                    name={DEMAND_DETAILS_DEMAND_RESPONSE_STATE.path}
                    select
                >
                    {getStateLabelValues().map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid xs={6} md={4}>
                <TextField
                    fullWidth
                    required={DEMAND_DETAILS_DEMAND_RESPONSE_ZIP_CODE.required}
                    label={DEMAND_DETAILS_DEMAND_RESPONSE_ZIP_CODE.label}
                    name={DEMAND_DETAILS_DEMAND_RESPONSE_ZIP_CODE.path}
                />
            </Grid>
        </Grid>
    )
}

export const DisplayDemandAmountField = ({ form, values }) => {
    return (
        <Switches
            name={DEMAND_DETAILS_DISPLAY_DEMAND_AMOUNT.path}
            required={DEMAND_DETAILS_DISPLAY_DEMAND_AMOUNT.required}
            onChange={(e) => {
                const checked = e.target.checked;
                form.change(DEMAND_DETAILS_DISPLAY_DEMAND_AMOUNT.path, checked)
                if (!checked) {
                    form.change(CLAIM_INFO_POLICY_LIMIT_UNDERSTOOD_BY_FIRM.path, '');
                    form.change(DAMAGES_SUMMARY_DEMAND_DAMAGES_AMOUNT.path, '');
                    form.change(DEMAND_DETAILS_DISPLAY_POLICY_LIMIT_INDICATOR.path, false);
                } else {
                    form.change(DEMAND_DETAILS_DISPLAY_POLICY_LIMIT_INDICATOR.path, true);
                    form.change(DEMAND_DETAILS_DEMAND_OFFER_TO_SETTLE.path, get(values, CLAIM_INFO_POLICY_LIMIT_UNDERSTOOD_BY_FIRM.path, ''));
                }
            }}
            data={{ label: DEMAND_DETAILS_DISPLAY_DEMAND_AMOUNT.label, value: false }}
            color="secondary"
        />
    )
}

export const DemandDetailsDisplayPolicyLimitIndicatorField = ({ form, values }) => {

    return (
        <Switches
            name={DEMAND_DETAILS_DISPLAY_POLICY_LIMIT_INDICATOR.path}
            required={DEMAND_DETAILS_DISPLAY_POLICY_LIMIT_INDICATOR.required}
            onChange={(e) => {
                const checked = e.target.checked;
                form.change(DEMAND_DETAILS_DISPLAY_POLICY_LIMIT_INDICATOR.path, checked)
                if (!checked) {
                    form.change(DEMAND_DETAILS_DEMAND_OFFER_TO_SETTLE.path, '');
                }
                else {
                    form.change(
                        'demandDetails.demandOfferToSettleAmount',
                        values?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm
                    );
                }
            }}
            data={{ label: DEMAND_DETAILS_DISPLAY_POLICY_LIMIT_INDICATOR.label, value: false }}
            color="secondary"
        />
    )
}

export const DemandDetailsOfferToSettleField = ({ values }) => {
    return (
        <TextField
            fullWidth
            required={DEMAND_DETAILS_DEMAND_OFFER_TO_SETTLE.required}
            label={DEMAND_DETAILS_DEMAND_OFFER_TO_SETTLE.label}
            name={DEMAND_DETAILS_DEMAND_OFFER_TO_SETTLE.path}
            onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }}
            disabled={get(values, DEMAND_DETAILS_DISPLAY_POLICY_LIMIT_INDICATOR.path, false)}
        />
    )
}

export const DemandDetailsUsingRelativeDueDateField = ({ form }) => {
    return (
        <Switches
            name={DEMAND_DETAILS_USING_RELATIVE_DUE_DATE.path}
            onChange={(e) => {
                const checked = e.target.checked;
                form.change(DEMAND_DETAILS_USING_RELATIVE_DUE_DATE.path, checked)
                if (!checked) {
                    form.change(DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE.path, '');
                    form.change(DEMAND_DETAILS_USING_RELATIVE_DUE_DATE.path, false);
                }
                else {
                    form.change(DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE.path, '');
                    form.change(DEMAND_DETAILS_USING_RELATIVE_DUE_DATE.path, true);
                }
            }}
            data={{ label: DEMAND_DETAILS_USING_RELATIVE_DUE_DATE.label, value: true }}
            color="secondary"
        />
    )
}