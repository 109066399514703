import React, { useEffect, useState } from 'react';
import { Box, Stack, IconButton } from '@mui/material';
import Title from './Title';
import { MenuOpenRounded } from '@mui/icons-material';
import PDFViewerComponent from './PDFViewerComponent';
import { Resizable } from 're-resizable';
import { useDispatch, useSelector } from 'react-redux';
import { setPdfPanelOpen, setResizableWidth, setPdfInstance } from '../redux/slices/demandDomSlice';
import { isDemandS3UrlExpired } from '../common-document';
import PDFSkeletonLoader from './PDFSkeletonLoader';
import { apiSlice } from '../services/apiSlice';
import { checkExpiryTime } from '../services/pdf/helpers';
import { useGetPdfUrlQuery } from '../services/pdf/pdfApi';
import moment from 'moment';
import { ResizableHandle as ResizableCustomHandle } from "@prec-ui/components";

const ResizableWrapWithPdfViewer = ({
  children,
  currentPage = 0,
  currentBoundingBoxes = [],
  currentPageTrigger = 0,
  pdfHeight = '75vh',
  defaultWidth = '66%',
  documentId,
  isOpen = true,
  shouldDisplayPdf = true,
  dividerTopMargin = '0px',
}) => {

  const [transitionOnClose, setTransitionOnClose] = useState(false);
  const [_url, setUrl] = useState(null);
  const [_pdfStatus, setPdfStatus] = useState(null);

  const { demandS3RegenerationLoadingData, demandS3RegenerationSkeletonData } = useSelector((state) => state.PdfCustomSlice);
  const isRegenLoading = (Boolean(demandS3RegenerationLoadingData[documentId])) || (Boolean(demandS3RegenerationSkeletonData[documentId]));

  const determinePollingInterval = (status) => {
      if (!status || status === 'InProcess' || status === 'Initial') {
          return 2000; // Continue polling every 2 seconds if it's InProcess or Initial or null
      }
      return 0; // Stop polling if the status is Failed or Completed, use the existing URL if we were provided one (same as before)
  };

  const { data: { demandS3Url, status } = {}, error, isLoading: isPdfUrlLoading } = useGetPdfUrlQuery(documentId, {
      skip: !documentId || !shouldDisplayPdf || isRegenLoading,
      refetchOnReconnect: true,
      pollingInterval: determinePollingInterval(_pdfStatus)
  });

  const { expiryTime, isExpired, timeRemaining } = checkExpiryTime(demandS3Url);

  useEffect(() => {
    if (demandS3Url && _url !== demandS3Url) {
      setUrl(demandS3Url);
    } else if (!demandS3Url && _url) {
      setUrl(null);
    }
    if (status) {
      setPdfStatus(status);
    }
  }, [demandS3Url, status]);

  const isLoading = isPdfUrlLoading || isRegenLoading;

  const dispatch = useDispatch();
  const {
    resizableWidth,
    editAllDocumentsOpen,
    demandDetailsEditOpen,
    demandEditOpen,
    bulkDeleteOpen,
    bulkEditOpen,
    pdfPanelOpen,
  } = useSelector((state) => state.DemandDom);

  const currentTime = moment().unix();
  const demandS3Expired = isDemandS3UrlExpired(demandS3Url);

  useEffect(() => {
    if (demandS3Url && demandS3Expired && !anyModalsOpen) {
      dispatch(apiSlice.endpoints.getPdfUrl.initiate(documentId, { forceRefetch: true }));
      dispatch(setPdfPanelOpen(true));
    }
  }, [currentTime]);

  const anyModalsOpen =
    editAllDocumentsOpen || demandDetailsEditOpen || demandEditOpen || bulkDeleteOpen || bulkEditOpen;

  useEffect(() => {
    if (shouldDisplayPdf) {
      dispatch(setResizableWidth(defaultWidth));
      dispatch(setPdfPanelOpen(true));
      return () => {
        dispatch(setResizableWidth(defaultWidth));
        dispatch(setPdfPanelOpen(true));
      };
    }
  }, [defaultWidth, shouldDisplayPdf]);

  const handleDocumentPanelClose = (event) => {
    dispatch(setPdfPanelOpen(false));
    dispatch(setPdfInstance(null));
    setTransitionOnClose(true);
    dispatch(setResizableWidth('99.9%'));
  };

  const handleDocumentPanelOpen = (event) => {
    dispatch(setPdfPanelOpen(true));
    dispatch(setResizableWidth(defaultWidth));
  };

  const handleResizeStop = (e, direction, ref, d) => {
    const newWidth = resizableWidth + d.width;
    dispatch(setResizableWidth(newWidth));
  };

  const handleResizeStart = () => setTransitionOnClose(false)
  const widthWithoutPdfViewer = 'calc(100% + 7px)';
  const maxWidth = !shouldDisplayPdf ? widthWithoutPdfViewer : !demandS3Url && !isLoading ? '100%' : pdfPanelOpen ? '80%' : '97%';

  return (
    <>
      <Resizable
        enable={shouldDisplayPdf ? { left: pdfPanelOpen, right: pdfPanelOpen } : false}
        size={{ width: !shouldDisplayPdf ? widthWithoutPdfViewer : resizableWidth, height: '100%' }}
        minWidth={shouldDisplayPdf ? '25%' : widthWithoutPdfViewer}
        maxWidth={maxWidth}
        onResizeStop={handleResizeStop}
        onResizeStart={handleResizeStart}
        handleComponent={{
          right: (
            <ResizableCustomHandle
              marginTop={dividerTopMargin}
              borderWidth={'5px'}
            // other available props:
            // height
            // hoverColor
            />
          )

        }}
        style={{
          paddingRight: '8px',
          transition: transitionOnClose ? 'width .2s ease' : 'none'
        }}
      >
        {children}
      </Resizable >
      <Box sx={{ width: '100%', paddingLeft: '8px', borderLeft: pdfPanelOpen ? '' : '1px solid', borderColor: 'divider', display: shouldDisplayPdf ? 'block' : 'none' }}>
        <Box sx={{ width: '100%' }}>
          {pdfPanelOpen && (
            <>
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ mb: 1 }}>
                <Title>Demand</Title>
                <IconButton onClick={handleDocumentPanelClose}>
                  <MenuOpenRounded sx={{
                    transform: 'scaleX(-1)'
                  }} />
                </IconButton>
              </Stack>
              <div>
                {(isLoading || !demandS3Url) && (
                  <PDFSkeletonLoader height={pdfHeight} />
                )}
                {!isLoading && demandS3Url && (
                  <Box sx={{
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    overflow: 'hidden',
                    width: '100%',
                  }}>
                    {isOpen && (

                      <PDFViewerComponent
                        isOpen={isOpen}
                        currentPage={currentPage}
                        currentBoundingBoxes={currentBoundingBoxes}
                        currentPageTrigger={currentPageTrigger}
                        height={pdfHeight}
                      />
                    )}
                  </Box>
                )}

              </div>
            </>
          )}

          {!pdfPanelOpen && (
            <IconButton onClick={handleDocumentPanelOpen}>
              <MenuOpenRounded />
            </IconButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ResizableWrapWithPdfViewer;
