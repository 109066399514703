import { coverageList, stateLabelValues } from '../../common-data';
import { faker } from '@faker-js/faker';
import dayjs from 'dayjs';

export const getCurrentDate = () => dayjs().format("YYYY-MM-DD");

export const getSingleSelectAutoCompleteFilteredOptions = (options, params) => {
    const deDeupedOptions = deDupeOptionsByLabel(options)
    return uniqueBySearch(deDeupedOptions, params);
}

export const getMultiSelectAutoCompleteFilteredOptions = (options, params, currentOptions = []) => {
    const filteredByNotSelected = options.filter((option) => !((currentOptions || [])
        .some((currentOption) => currentOption.label === option.label)))
    const filteredBySearch = uniqueBySearch(filteredByNotSelected, params)
    return deDupeOptionsByLabel(filteredBySearch.length > 0 ? filteredBySearch : filteredByNotSelected)
}

// labels and values for state dropdown
export const getStateLabelValues = () => stateLabelValues;

export const deDupeOptionsByLabel = (options) => options.filter((option, index, self) => self.findIndex((t) => t.label === option.label) === index)
export const uniqueBySearch = (options, params) => options.filter((option) => option.label.toLowerCase().includes(params.inputValue.toLowerCase()))


export const autoFillCreateNewDemandForm = (form, values, attorney_auto_complete_data, carrier_auto_complete_data, user_autocomplete_data) => {

    const newAttorney = attorney_auto_complete_data[0];
    form.change('autoCompletes.primaryContact', newAttorney);

    const newCarrier = carrier_auto_complete_data[0] ? { label: carrier_auto_complete_data[0].label, value: carrier_auto_complete_data[0].value } : null;
    form.change('autoCompletes.carrier', newCarrier);
    //change recipientCarrier contactInfo explictly here b/c these fields are not fully controlled by autoCompletes.carrier
    form.change('recipientCarrier.contactInfo', newCarrier.value.contactInfo);

    form.change('sendingFirm.firmCaseNumber', faker.number.int().toString());
    form.change('claimInfo.claimNumber', faker.number.int().toString());

    const injuredPartyFullName = `${faker.person.firstName()} ${faker.person.lastName()}`;
    form.change('claimInfo.claimant.firstName', injuredPartyFullName);
    form.change('claimInfo.injuredPartyFullName', injuredPartyFullName);

    const tortfeasorFullName = `${faker.person.firstName()} ${faker.person.lastName()}`;
    form.change('claimInfo.insuredFirstName', tortfeasorFullName);
    form.change('claimInfo.tortfeasorFullName', tortfeasorFullName);
    form.change('claimInfo.claimCoverage', faker.helpers.arrayElement(coverageList).value);
    form.change('claimInfo.lossState', faker.helpers.arrayElement(stateLabelValues).value);
    form.change('claimInfo.lossCounty', faker.location.county());
    form.change('claimInfo.insuredPolicyLimitsAsUnderstoodByFirm', faker.number.int({ min: 100, max: 10000 }).toString());
    form.change('damagesSummary.demandDamagesAmount', faker.number.int({ min: 100, max: 10000 }).toString());
    form.change('demandDetails.demandOfferToSettleAmount', faker.number.int({ min: 100, max: 10000 }).toString());
    form.change('demandDetails.demandResponseDueDate', faker.date.future().toISOString().split('T')[0]);
    form.change('sendingFirm.firmTINNumber', faker.number.int({ min: 500, max: 999999999 }).toString());
    form.change('recipientAdjuster.firstName', faker.person.firstName());
    form.change('recipientAdjuster.lastName', faker.person.lastName());
    form.change('recipientAdjuster.contactInfo.phoneNumber', faker.phone.number('(###) ###-####'));
    form.change('recipientAdjuster.contactInfo.emailAddress', faker.internet.email());
    form.change('demandDetails.demandResponseAddress.street1', faker.location.streetAddress());
    form.change('demandDetails.demandResponseAddress.street2', faker.location.secondaryAddress());
    form.change('demandDetails.demandResponseAddress.city', faker.location.city());
    form.change('demandDetails.demandResponseAddress.state', faker.helpers.arrayElement(stateLabelValues).value);
    form.change('demandDetails.demandResponseAddress.zipCode', faker.location.zipCode());
}