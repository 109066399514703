import { createSlice } from '@reduxjs/toolkit'

const originalDomState = {
    aiPromptBuilderOpen: false,
    bulkEditOpen: false,
    createNewDemandOpen: false,
    createNewGlobalDemandOpen: false,
    demandDetailsEditOpen: false,
    demandEditOpen: false,
    documentEditS3Url: '',
    documentUploaderList: [],
    editAllDocumentsOpen: false,
    editDocumentPdfOpen: false,
    editOneDocumentFileName: '',
    fileDisabled: '',
    fileSaveStatusMap: {},
    isDocumentsUploading: false,
    isRedactorDirty: false,
    medicalExtractionDialogOpen: false,
    medicalsScrollFrameRef: null,
    medicalsTableIndex: 0,
    overrideReview: false,
    pdfInstance: null,
    pdfPanelOpen: true,
    pdfRedactionIndex: 0,
    redactDocumentOpen: false,
    resizableWidth: '66.6%'
}


const demandDomSlice = createSlice({
    name: 'DemandDom',
    initialState: originalDomState,
    reducers: {
        setEditAllDocumentsOpen: (state, action) => { state.editAllDocumentsOpen = action.payload },
        setMedicalsScrollFrameRef: (state, action) => { state.medicalsScrollFrameRef = action.payload },
        setDemandEditOpen: (state, action) => { state.demandEditOpen = action.payload },
        setEditDemandDetailsOpen: (state, action) => { state.demandDetailsEditOpen = action.payload },
        setMedicalExtractionDialogOpen: (state, action) => { state.medicalExtractionDialogOpen = action.payload },
        setPdfPanelOpen: (state, action) => { state.pdfPanelOpen = action.payload },
        setResizableWidth: (state, action) => { state.resizableWidth = action.payload },
        setBulkEditOpen: (state, action) => { state.bulkEditOpen = action.payload },
        setMedicalsTableIndex: (state, action) => { state.medicalsTableIndex = action.payload },
        setCreateNewDemandOpen: (state, action) => { state.createNewDemandOpen = action.payload },
        setCreateNewGlobalDemandOpen: (state, action) => { state.createNewGlobalDemandOpen = action.payload },
        setDocumentUploaderList: (state, action) => { state.documentUploaderList = action.payload },
        setIsDocumentsUploading: (state, action) => { state.isDocumentsUploading = action.payload },
        setEditOneDocumentFileName: (state, action) => { state.editOneDocumentFileName = action.payload },
        setDocumentEdit3Url: (state, action) => { state.documentEditS3Url = action.payload },
        setPdfInstance: (state, action) => { state.pdfInstance = action.payload },
        setRedactDocumentOpen: (state, action) => { state.redactDocumentOpen = action.payload },
        setEditDocumentPdfOpen: (state, action) => { state.editDocumentPdfOpen = action.payload },
        setFileDisabled: (state, action) => { state.fileDisabled = action.payload },
        setIsRedactorPdfDirty: (state, action) => { state.isRedactorDirty = action.payload },
        setPdfRedactionIndex: (state, action) => { state.pdfRedactionIndex = action.payload },
        setFileSaveStatusMap: (state, action) => { state.fileSaveStatusMap = action.payload },
        setAiPromptBuilderOpen: (state, action) => { state.aiPromptBuilderOpen = action.payload },
        setOverrideReview: (state, action) => {
            state.overrideReview = action.payload;
        },
    },
})

export const {
    setEditAllDocumentsOpen, setMedicalsScrollFrameRef, setDemandEditOpen,
    setEditDemandDetailsOpen, setMedicalExtractionDialogOpen, setPdfPanelOpen,
    setResizableWidth, setBulkEditOpen, setMedicalsTableIndex, setCreateNewDemandOpen,
    setDocumentUploaderList, setIsDocumentsUploading, setEditOneDocumentFileName,
    setDocumentEdit3Url, setPdfInstance, setRedactDocumentOpen,
    setEditDocumentPdfOpen, setFileDisabled, setIsRedactorPdfDirty,
    setPdfRedactionIndex, setFileSaveStatusMap, setAiPromptBuilderOpen,
    setOverrideReview, setCreateNewGlobalDemandOpen
} = demandDomSlice.actions

export default demandDomSlice.reducer;