

import React from 'react';
import { TextField, Switches } from 'mui-rff';
import { MenuItem, InputAdornment } from '@mui/material';
import { successFieldInputProps, successFieldStyle } from '../../Styles';
import { getCurrentDate, autoFillCreateNewDemandForm } from '../Helpers'
import { formatDecimal } from '../../../common'
import { get } from 'lodash';
import {
    CLAIM_INFO_CLAIMANT_FIRSTNAME,
    CLAIM_INFO_CLIENT_IS_INJURED_PARTY,
    CLAIM_INFO_CLAIM_NUMBER, CLAIM_INFO_INJURED_PARTY,
    CLAIM_INFO_INSURED_FIRST_NAME,
    CLAIM_INFO_CLIENT_IS_TORTFEASOR,
    CLAIM_INFO_TORTFEASOR,
    CLAIM_INFO_DATE_OF_LOSS,
    CLAIM_INFO_CLAIM_COVERAGE,
    CLAIM_INFO_LOSS_STATE,
    CLAIM_INFO_LOSS_COUNTY,
    CLAIM_INFO_POLICY_LIMIT_UNDERSTOOD_BY_FIRM
} from '../Schemas/ClaimInfo'
import { SENDING_FIRM_CASE_NUMBER } from '../Schemas/SendingFirm'
import { DEMAND_DETAILS_DISPLAY_POLICY_LIMIT_INDICATOR, DEMAND_DETAILS_DEMAND_OFFER_TO_SETTLE } from '../Schemas/DemandDetails'

// 'claimInfo.claimNumber'
export const ClaimInfoClaimNumberField = () => {
    return (
        <TextField
            name={CLAIM_INFO_CLAIM_NUMBER.path}
            label={CLAIM_INFO_CLAIM_NUMBER.label}
            required={CLAIM_INFO_CLAIM_NUMBER.required}
            fieldProps={{ validate: CLAIM_INFO_CLAIM_NUMBER.validation }}
            fullWidth
        />
    )
}

// 'claimInfo.claimant.firstName'
export const ClaimInfoClaimantFirstNameField = ({ form, values, attorney_auto_complete_data, carrier_auto_complete_data, user_data_for_auto_completes, clientNameMatchesIntegration }) => {
    return (
        <TextField
            fullWidth
            required={CLAIM_INFO_CLAIMANT_FIRSTNAME.required}
            label={CLAIM_INFO_CLAIMANT_FIRSTNAME.label}
            name={CLAIM_INFO_CLAIMANT_FIRSTNAME.path}
            sx={clientNameMatchesIntegration ? successFieldStyle : {}}
            onChange={(e) => {
                if (get(values, CLAIM_INFO_CLIENT_IS_INJURED_PARTY.path)) {
                    form.change(CLAIM_INFO_INJURED_PARTY.path, e.target.value);
                }
                form.change(CLAIM_INFO_CLAIMANT_FIRSTNAME.path, e.target.value)

                if (get(values, SENDING_FIRM_CASE_NUMBER.path) === "42" && get(values, CLAIM_INFO_CLAIM_NUMBER.path) === "42" && e.target.value === "Precedent") {
                    autoFillCreateNewDemandForm(form, values, attorney_auto_complete_data, carrier_auto_complete_data, user_data_for_auto_completes)
                }
            }}
            InputProps={clientNameMatchesIntegration && successFieldInputProps}
            fieldProps={{ validate: CLAIM_INFO_CLAIMANT_FIRSTNAME.validation }}
        />
    )
}

// 'claimInfo.clientIsInjuredParty'
export const ClaimInfoClientIsInjuredPartyField = ({ form, values }) => {
    return (
        <Switches
            name={CLAIM_INFO_CLIENT_IS_INJURED_PARTY.path}
            onChange={(e) => {
                const checked = e.target.checked;
                form.change(CLAIM_INFO_CLIENT_IS_INJURED_PARTY.path, checked)
                if (checked) {
                    form.change(CLAIM_INFO_INJURED_PARTY.path, get(values, CLAIM_INFO_CLAIMANT_FIRSTNAME.path))
                } else {
                    form.change(CLAIM_INFO_INJURED_PARTY.path, '')
                }
            }}
            data={{ label: CLAIM_INFO_CLIENT_IS_INJURED_PARTY.label, value: true }}
            color="secondary"
        />
    )
}

// 'claimInfo.injuredPartyFullName'
export const ClaimInfoInjuredPartyFullNameField = () => {
    return (
        <TextField
            fullWidth
            required={CLAIM_INFO_INJURED_PARTY.required}
            label={CLAIM_INFO_INJURED_PARTY.label}
            name={CLAIM_INFO_INJURED_PARTY.path}
            fieldProps={{ validate: CLAIM_INFO_INJURED_PARTY.validation }}
        />
    )
}

// 'claimInfo.insuredFirstName'
export const ClaimInfoInsuredFirstNameField = ({ form, values, insuredNameMatchesIntegration}) => {
    return (
        <TextField
            fullWidth
            required={CLAIM_INFO_INSURED_FIRST_NAME.required}
            label={CLAIM_INFO_INSURED_FIRST_NAME.label}
            name={CLAIM_INFO_INSURED_FIRST_NAME.path}
            onChange={(e) => {
                form.change(CLAIM_INFO_INSURED_FIRST_NAME.path, e.target.value)

                if (values.claimInfo?.insuredIsTortfeasor) {
                    form.change(CLAIM_INFO_TORTFEASOR.path, e.target.value)
                }
            }}
            fieldProps={{ validate: CLAIM_INFO_INSURED_FIRST_NAME.validation }}
            InputProps={insuredNameMatchesIntegration && successFieldInputProps}
            sx={insuredNameMatchesIntegration ? successFieldStyle : {}}
        />
    )
}

// 'claimInfo.insuredIsTortfeasor'
export const ClaimInfoClientIsTortfeasorField = ({ form, values }) => {
    return (
        <Switches
            name={CLAIM_INFO_CLIENT_IS_TORTFEASOR.path}
            onChange={(e) => {
                const checked = e.target.checked;
                form.change(CLAIM_INFO_CLIENT_IS_TORTFEASOR.path, checked)
                if (checked) {
                    form.change(CLAIM_INFO_TORTFEASOR.path, get(values, CLAIM_INFO_INSURED_FIRST_NAME.path))
                } else {
                    form.change(CLAIM_INFO_TORTFEASOR.path, '')
                }
            }}
            data={{ label: CLAIM_INFO_CLIENT_IS_TORTFEASOR.label, value: true }}
            color="secondary"
        />
    )
}

// 'claimInfo.tortfeasorFullName'
export const ClaimInfoTortfeasorField = () => {
    return (
        <TextField
            name={CLAIM_INFO_TORTFEASOR.path}
            label={CLAIM_INFO_TORTFEASOR.label}
            required={CLAIM_INFO_TORTFEASOR.required}
            fieldProps={{ validate: CLAIM_INFO_TORTFEASOR.validation }}
            fullWidth
        />
    )
}

// 'claimInfo.dateOfLoss'
export const ClaimInfoDateOfLossField = ({dateOfLossMatchesIntegration}) => {
    return (
        <TextField
            name={CLAIM_INFO_DATE_OF_LOSS.path}
            inputProps={{ max: getCurrentDate() }}
            label={CLAIM_INFO_DATE_OF_LOSS.label}
            type={CLAIM_INFO_DATE_OF_LOSS.fieldType}
            InputLabelProps={{ shrink: true }}
            required={CLAIM_INFO_DATE_OF_LOSS.required}
            fullWidth={true}
            fieldProps={{ validate: CLAIM_INFO_DATE_OF_LOSS.validation }}
            InputProps={dateOfLossMatchesIntegration && successFieldInputProps}
            sx={dateOfLossMatchesIntegration ? successFieldStyle : {}}
        />
    )
}

// 'claimInfo.claimCoverage'
export const ClaimInfoClaimCoverageField = ({ coverageList }) => {
    return (
        <TextField
            label={CLAIM_INFO_CLAIM_COVERAGE.label}
            name={CLAIM_INFO_CLAIM_COVERAGE.path}
            fullWidth
            required={CLAIM_INFO_CLAIM_COVERAGE.required}
            fieldProps={{ validate: CLAIM_INFO_CLAIM_COVERAGE.validation }}
            select
        >
            {coverageList.map((option) => (

                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>

            ))}
        </TextField>
    )
}

// 'claimInfo.lossState'
export const ClaimInfoLossStateField = ({ stateLabelValues, lossStateMatchesIntegration }) => {
    return (
        <TextField
            name={CLAIM_INFO_LOSS_STATE.path}
            label={CLAIM_INFO_LOSS_STATE.label}
            select
            required={CLAIM_INFO_LOSS_STATE.required}
            fieldProps={{ validate: CLAIM_INFO_LOSS_STATE.validation }}
            fullWidth
            InputProps={lossStateMatchesIntegration && successFieldInputProps}
            sx={lossStateMatchesIntegration ? successFieldStyle : {}}
        >
            {stateLabelValues.map((option, idx) => (

                <MenuItem key={idx} value={option.value}>
                    {option.label}
                </MenuItem>

            ))}
        </TextField>
    )
}

// 'claimInfo.lossCounty'
export const ClaimInfoLossCountyField = ({ countyList }) => {
    return (
        <TextField
            label={CLAIM_INFO_LOSS_COUNTY.label}
            name={CLAIM_INFO_LOSS_COUNTY.path}
            fullWidth
            required={CLAIM_INFO_LOSS_COUNTY.required}
            fieldProps={{ validate: CLAIM_INFO_LOSS_COUNTY.validation }}
        />
    )
}

// 'claimInfo.insuredPolicyLimitsAsUnderstoodByFirm'
export const ClaimInfoPolicyLimitUnderstoodByFirmField = ({ form, values }) => {
    return (
        <TextField
            required={CLAIM_INFO_POLICY_LIMIT_UNDERSTOOD_BY_FIRM.required}
            label={CLAIM_INFO_POLICY_LIMIT_UNDERSTOOD_BY_FIRM.label}
            name={CLAIM_INFO_POLICY_LIMIT_UNDERSTOOD_BY_FIRM.path}
            onChange={(e) => {
                if (get(values, DEMAND_DETAILS_DISPLAY_POLICY_LIMIT_INDICATOR.path)) {
                    form.change(DEMAND_DETAILS_DEMAND_OFFER_TO_SETTLE.path, e.target.value)
                }
                form.change(CLAIM_INFO_POLICY_LIMIT_UNDERSTOOD_BY_FIRM.path, e.target.value)
            }}
            onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }}
        />
    )
}