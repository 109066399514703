
export const SENDING_FIRM_CASE_NUMBER = {
    path: 'sendingFirm.firmCaseNumber',
    label: 'Matter number or case ID',
    dataType: 'string',
    fieldType: 'text',
    required: true,
    validation: (value) => !value ? `Matter number or case ID is required` : null
}

export const SENDING_FIRM_TIN = {
    path: 'sendingFirm.firmTINNumber',
    label: 'Tax ID',
    required: false,
    dataType: 'string',
    fieldType: 'text',
    validation: undefined
}

export const SENDING_FIRM_PRIMARY_CONTACT = {
    path: 'autoCompletes.primaryContact',
    label: 'Primary contact',
    required: true,
    dataType: 'string',
    fieldType: 'text',
    validation: (value) => !value ? `Primary contact is required` : null
}

export const SENDING_FIRM_APPROVER_IS_PRIMARY_CONTACT = {
    path: 'mutators.attorneyIsPrimaryContact',
    label: 'Primary contact will approve demand',
    required: false,
    dataType: 'boolean',
    fieldType: 'switch',
    validation: undefined
}

export const SENDING_FIRM_APPROVER = {
    path: 'autoCompletes.attorney',
    label: 'Approver',
    required: true,
    dataType: 'string',
    fieldType: 'text',
    validation: undefined
}

export const SENDING_FIRM_COLLABORATORS = {
    path: 'autoCompletes.collaborators',
    label: 'Collaborators',
    required: false,
    dataType: 'list',
    fieldType: 'text',
    validation: undefined
}