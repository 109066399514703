import moment from 'moment';

export const CLAIM_INFO_CLAIM_NUMBER = {
    path: 'claimInfo.claimNumber',
    label: 'Claim number',
    dataType: 'string',
    fieldType: 'text',
    required: false,
    validation: undefined
}

export const CLAIM_INFO_CLAIMANT_FIRSTNAME = {
    path: 'claimInfo.claimant.firstName',
    label: 'Client',
    dataType: 'string',
    fieldType: 'text',
    required: true,
    validation: (value) => !value ? `Client name is required` : null
}

export const CLAIM_INFO_CLIENT_IS_INJURED_PARTY = {
    path: 'claimInfo.clientIsInjuredParty',
    label: 'Client is injured party',
    dataType: 'boolean',
    fieldType: 'switch',
    required: false,
    validation: undefined
}

export const CLAIM_INFO_INJURED_PARTY = {
    path: 'claimInfo.injuredPartyFullName',
    label: 'Injured party',
    dataType: 'string',
    fieldType: 'text',
    required: true,
    validation: (value) => !value ? `Injured party name is required` : null
}

export const CLAIM_INFO_INSURED_FIRST_NAME = {
    path: 'claimInfo.insuredFirstName',
    label: 'Insured',
    dataType: 'string',
    fieldType: 'text',
    required: true,
    validation: (value) => !value ? `Insured name is required` : null
}

export const CLAIM_INFO_CLIENT_IS_TORTFEASOR = {
    path: 'claimInfo.insuredIsTortfeasor',
    label: 'Insured is tortfeasor',
    dataType: 'boolean',
    fieldType: 'switch',
    required: false,
    validation: undefined
}

export const CLAIM_INFO_TORTFEASOR = {
    path: 'claimInfo.tortfeasorFullName',
    label: 'Tortfeasor',
    dataType: 'string',
    fieldType: 'text',
    required: true,
    validation: (value) => !value ? `Tortfeasor name is required` : null
}

export const CLAIM_INFO_DATE_OF_LOSS = {
    path: 'claimInfo.dateOfLoss',
    label: 'Date of loss',
    dataType: 'date',
    fieldType: 'date',
    required: true,
    validation: (value) => {
        if (!value) return 'Date of loss is required';
        if (!moment(value, 'YYYY-MM-DD', true).isValid()) return 'Invalid date';
        if (moment(value).isAfter(moment())) return 'Date of loss cannot be in the future';
        if (moment(value).isBefore(moment().subtract(50, 'years'))) return 'Date of loss must be more recent';
        return null;
    }
}

export const CLAIM_INFO_CLAIM_COVERAGE = {
    path: 'claimInfo.claimCoverage',
    label: 'Coverage',
    dataType: 'string',
    fieldType: 'text',
    required: false,
    validation: undefined
}

export const CLAIM_INFO_LOSS_STATE = {
    path: 'claimInfo.lossState',
    label: 'Loss state',
    dataType: 'string',
    fieldType: 'text',
    required: true,
    validation: (value) => !value ? `Loss state is required` : null
}

export const CLAIM_INFO_LOSS_COUNTY = {
    path: 'claimInfo.lossCounty',
    label: 'Loss county',
    dataType: 'string',
    fieldType: 'text',
    required: false,
    validation: undefined
}

export const CLAIM_INFO_POLICY_LIMIT_UNDERSTOOD_BY_FIRM = {
    path: 'claimInfo.insuredPolicyLimitsAsUnderstoodByFirm',
    label: 'Insured policy limit',
    required: false,
    fieldType: 'text',
    dataType: 'string'
}

