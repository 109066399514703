import { Box, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import NoDeliveryIcon from '../CommonComponents/Chips/CustomIcons/NoDeliveryIcon'
import { theme } from '../Theme'

const KeyValuePair = ({ label, value, value2, validation, callToAction, useKeyOptionalStyles, carrierName, demandIsDeliverable = true }) => {
    const keyDefaultStyle = { fontWeight: 400, textOverflow: "ellipsis", whiteSpace: 'nowrap', overflow: 'hidden' }
    const keyOptionalStyles = { textOverflow: "ellipsis", whiteSpace: 'nowrap', overflow: 'hidden' }
    const keyStyles = useKeyOptionalStyles ? { ...keyDefaultStyle, ...keyOptionalStyles } : keyDefaultStyle
    const valueStyles = { textOverflow: "ellipsis", whiteSpace: 'nowrap', overflow: 'hidden' }

    return (
        <Stack>

            <Typography
                variant="caption"
                color={theme.palette.text.secondary}
                sx={keyStyles}
            >
                {label}
            </Typography>


            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {!demandIsDeliverable &&

                    <Tooltip
                        title={`Demand will not be delivered to ${carrierName}`}
                        arrow
                        placement='top'
                    >
                        <Stack>
                            <NoDeliveryIcon color="info" />
                        </Stack>
                    </Tooltip>

                }
                <Tooltip title={value2 ? `${value} ${value2}` : value} arrow placement="top-start">
                    <Typography variant="body1" sx={valueStyles}>
                        {value} {value2}
                    </Typography>
                </Tooltip>
                {validation}
                {callToAction}
            </Box>
        </Stack>
    )
}

export default KeyValuePair