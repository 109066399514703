import React from 'react';
import { Box, Button, Card, CardContent, CardHeader, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DeleteOutline, PersonAddOutlined, PersonOutlined } from '@mui/icons-material';
import { TextField, Switches } from 'mui-rff';

const ClientSection = ({ index, onRemove, form, values }) => {
  const clientPrefix = `clients[${index}]`;
  const client = values?.clients?.[index] || {};

  return (
    <>
      <Card
        sx={{ p: 0, width: '100%' }}
      >
        <CardHeader
          sx={{
            borderBottom: '1px solid',
            borderColor: 'divider'
          }}
          avatar={<PersonOutlined color="action" />}
          title={<Typography variant="body1">Client {index + 1}</Typography>}
          action={
            <Tooltip
              title={values.clients?.length === 1 ? "At least one client is required for global demands" : ""}
              arrow
              placement='left'
            >
              <span>
                <IconButton
                  disabled={values.clients?.length === 1}
                  onClick={() => onRemove(index)}
                  size="small"
                >
                  <DeleteOutline />
                </IconButton>
              </span>
            </Tooltip>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid xs={12} lg={6}>
              <TextField
                fullWidth
                required
                label="Matter number or case ID"
                name={`${clientPrefix}.sendingFirm.firmCaseNumber`}
                fieldProps={{ validate: (value) => value ? undefined : `Matter number or case ID is required` }}
              />
            </Grid>

            <Grid xs={12} lg={6}>
              <TextField
                fullWidth
                required
                label="Client name"
                name={`${clientPrefix}.claimInfo.claimant.firstName`}
                fieldProps={{ validate: (value) => (value ? undefined : `Client name is required`) }}
                onChange={(e) => {
                  const value = e.target.value;
                  form.change(`${clientPrefix}.claimInfo.claimant.firstName`, value);
                  if (client?.claimInfo?.clientIsInjuredParty) {
                    form.change(`${clientPrefix}.claimInfo.injuredPartyFullName`, value);
                  }
                }}
              />
            </Grid>

            <Grid xs={12} lg={6}>
              <Switches
                name={`${clientPrefix}.claimInfo.clientIsInjuredParty`}
                data={{
                  label: `Client ${index + 1} is injured party`,
                  value: true
                }}
                color="secondary"
                onChange={(e) => {
                  const checked = e.target.checked;
                  form.change(`${clientPrefix}.claimInfo.clientIsInjuredParty`, checked);
                  if (checked) {
                    form.change(
                      `${clientPrefix}.claimInfo.injuredPartyFullName`,
                      client?.claimInfo?.claimant?.firstName || ''
                    );
                  } else {
                    form.change(`${clientPrefix}.claimInfo.injuredPartyFullName`, '');
                  }
                }}
              />
            </Grid>

            {!client?.claimInfo?.clientIsInjuredParty && (
              <Grid xs={12} lg={6}>
                <Box
                  sx={{
                    pl: { xs: 6, lg: 0 }
                  }}
                >
                  <TextField
                    fullWidth
                    required
                    label="Injured party"
                    name={`${clientPrefix}.claimInfo.injuredPartyFullName`}
                    fieldProps={{ validate: (value) => (value ? undefined : `Injured party name is required`) }}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>

  );
};

const MultiClientForm = ({ form, values }) => {
  const handleAddClient = () => {
    const currentClients = values.clients || [];
    form.change('clients', [
      ...currentClients,
      {
        claimInfo: {
          clientIsInjuredParty: true,
          claimant: {
            firstName: ''
          },
          injuredPartyFullName: ''
        },
        sendingFirm: {
          firmCaseNumber: ''
        }
      }
    ]);
  };

  const handleRemoveClient = (index) => {
    const newClients = [...(values.clients || [])];
    newClients.splice(index, 1);
    form.change('clients', newClients);
  };

  return (
    <Stack
      spacing={2}
      sx={{ width: '100%' }}
      alignItems={'flex-end'}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <Typography variant="body1" color="primary">Clients</Typography>
        <Button
          color='secondary'
          startIcon={<PersonAddOutlined />}
          onClick={handleAddClient}
          variant="outlined"
        >
          Add client
        </Button>
      </Stack>

      {values.clients?.map((client, index) => (
        <ClientSection
          key={index}
          index={index}
          onRemove={handleRemoveClient}
          form={form}
          values={values}
        />
      ))}

      {values.clients.length > 1 &&
        <Button
          color='secondary'
          startIcon={<PersonAddOutlined />}
          onClick={handleAddClient}
          variant="outlined"
        >
          Add client
        </Button>
      }

      {(!values.clients || values.clients.length === 0) && (
        <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', py: 2 }}>
          No clients added. Click "Add client" to begin.
        </Typography>
      )}
    </Stack>
  );
};

export default MultiClientForm;