import { createSlice } from '@reduxjs/toolkit';

const originalEditDocumentState = {
    sequencedDocumentFiles: [],
    selectedRows: [],
    areDocumentFilesSaving: false,
};

const EditDocumentsSlice = createSlice({
    name: 'EditDocuments',
    initialState: originalEditDocumentState,
    reducers: {
        setSequencedDocumentFiles: (state, action) => {
            state.sequencedDocumentFiles = action.payload;
        },
        mapNewFilesToSequence: (state, action) => {
            const newFilesList = action.payload;
            const currentSequencedFiles = [...state.sequencedDocumentFiles];
            
            const sequencePositionMap = new Map(
                currentSequencedFiles.map((file, index) => [file.fileEntityId, index])
            );
        
            const sortedNewFiles = [...newFilesList].sort((a, b) => {
                const posA = sequencePositionMap.has(a.fileEntityId) ? sequencePositionMap.get(a.fileEntityId) : Infinity;
                const posB = sequencePositionMap.has(b.fileEntityId) ? sequencePositionMap.get(b.fileEntityId) : Infinity;
                return posA - posB;
            });
            
            state.sequencedDocumentFiles = sortedNewFiles;
        },
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload
        },
        setAreDocumentFilesSaving: (state, action) => {
            state.areDocumentFilesSaving = action.payload;
        },
    },
});

export const {
    setSequencedDocumentFiles,
    mapNewFilesToSequence,
    setSelectedRows,
    setAreDocumentFilesSaving,
} = EditDocumentsSlice.actions;

export default EditDocumentsSlice.reducer;