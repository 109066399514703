import { useState, useRef, useEffect } from "react";
import { Tooltip, Typography } from "@mui/material";

const TruncatedText = ({ text }) => {
    const textRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        const checkTruncation = () => {
            if (textRef.current) {
                setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth);
            }
        };

        checkTruncation();
        window.addEventListener("resize", checkTruncation);

        return () => window.removeEventListener("resize", checkTruncation);
    }, []);

    return (
        <Tooltip title={isTruncated ? text : ""} disableHoverListener={!isTruncated}>
            <Typography
                color="primary"
                variant="h5"
                ref={textRef}
                noWrap
                sx={{
                    display: "block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: '35vw'
                }}
            >
                {text}
            </Typography>
        </Tooltip>
    );
};

export default TruncatedText;
