import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import { TableCell } from '@tiptap/extension-table-cell';
import { TableHeader } from '@tiptap/extension-table-header';
import { TableRow } from '@tiptap/extension-table-row';
import {
  TableImproved,
} from 'mui-tiptap';
import { Indent } from './CustomExtensions/indent.ts';
import AiGenerate from './CustomExtensions/aiGenerate.js';
import PageBreak from './CustomExtensions/pageBreak.js';
import Link from '@tiptap/extension-link';

export const getEditorExtensions = () => [
  StarterKit,
  Underline,
  TextAlign.configure({ types: ['paragraph'] }),
  Indent,
  AiGenerate.extend({}), // extending with empty object so that a new instance of the extension with its storage is created per editor
  TextStyle,
  TableImproved.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
  PageBreak,
  Link.configure({
    openOnClick: false,
    autolink: false,
    linkOnPaste: false
  })
];