import { isValidPhoneNumber, isValidEmail } from '../../ValidationHelpers';

// recipientCarrier?.carrierCommonName,
export const RECIPIENT_CARRIER_COMMON_NAME = {
    path: 'autoCompletes.carrier',
    label: 'Carrier',
    dataType: 'string',
    fieldType: 'autocomplete',
    required: true,
    validation: (value) => !value ? 'Carrier is required' : null
}

// recipientCarrier?.contactInfo?.faxNumber,
export const RECIPIENT_CARRIER_FAX_NUMBER = {
    path: 'recipientCarrier.contactInfo.faxNumber',
    label: 'Carrier fax number',
    dataType: 'string',
    fieldType: 'text',
    required: false,
    validation: value => value && !isValidPhoneNumber(value) ? 'Invalid fax number' : null
}

// recipientCarrier?.contactInfo?.emailAddress,
export const RECIPIENT_CARRIER_EMAIL_ADDRESS = {
    path: 'recipientCarrier.contactInfo.emailAddress',
    label: 'Carrier email',
    dataType: 'string',
    fieldType: 'text',
    required: false,
    validation: value => value && !isValidEmail(value) ? 'Invalid email' : null
}

// recipientCarrier?.contactInfo?.phoneNumber,
export const RECIPIENT_CARRIER_PHONE_NUMBER = {
    path: 'recipientCarrier.contactInfo.phoneNumber',
    label: 'Carrier phone',
    dataType: 'string',
    fieldType: 'text',
    required: false,
    validation: value => value && !isValidPhoneNumber(value) ? 'Invalid phone number' : null
}

// mutators.showCarrierContactInfo
export const RECIPIENT_CARRIER_SHOW_CONTACT_INFO = {
    path: 'mutators.showCarrierContactInfo',
    label: 'Carrier contact info (optional)',
    dataType: 'boolean',
    fieldType: 'switch',
    required: false
}

// recipientCarrier?.contactInfo?.address.street1,
export const RECIPIENT_CARRIER_ADDRESS_1 = {
    path: 'recipientCarrier.contactInfo.address.street1',
    label: 'Address line 1',
    dataType: 'string',
    fieldType: 'text',
    required: false
}

// recipientCarrier?.contactInfo?.address.street2,
export const RECIPIENT_CARRIER_ADDRESS_2 = {
    path: 'recipientCarrier.contactInfo.address.street2',
    label: 'Address line 2',
    dataType: 'string',
    fieldType: 'text',
    required: false
}

// recipientCarrier?.contactInfo?.address.city,
export const RECIPIENT_CARRIER_CITY = {
    path: 'recipientCarrier.contactInfo.address.city',
    label: 'City',
    dataType: 'string',
    fieldType: 'text',
    required: false
}

// recipientCarrier?.contactInfo?.address.state,
export const RECIPIENT_CARRIER_STATE = {
    path: 'recipientCarrier.contactInfo.address.state',
    label: 'State',
    dataType: 'string',
    fieldType: 'select',
    required: false,
}

// recipientCarrier?.contactInfo?.address.zipCode,
export const RECIPIENT_CARRIER_ZIP_CODE = {
    path: 'recipientCarrier.contactInfo.address.zipCode',
    label: 'Zip',
    dataType: 'string',
    fieldType: 'text',
    required: false
}

