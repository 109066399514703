export default {
    "REGION": "us-east-1",
    "USER_POOL_ID": "us-east-1_vZX3e58fT",
    "USER_POOL_APP_CLIENT_ID": "ud3hhef9o4kijg37nt1i02aaj",
    "ROOT_API_URL": "https://b575s7pcj0.execute-api.us-east-1.amazonaws.com/exchange-beta-documents-api-prod",
    "MFA_ISSUER": "Precedent-exchange-beta",
    "S3_DEPLOYMENT_BUCKET": "exchange-beta-precedent-exchange-document-spa",
    "RUM_IDENTITY_POOL_ID": "us-east-1:706a7792-6e0a-4e39-bb7b-aaac80127662",
    "RUM_APP_MONITOR_ID": "ddf50c82-5c55-43d2-9feb-349ab7dcf678",
    "ENV_STAGE": "beta"
}
