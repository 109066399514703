import { WarningAmberOutlined } from "@mui/icons-material";
import { Box, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { formatDate, getCurrentDate } from "../../common";
import { medicalTreatmentsDisplay } from "../../models/medicalTreatments/display";
import moment from "moment";
import { mapPageInsightStatusToDisplayStatus } from "../insights";


export const renderTreatmentFacilityCell = ({
  documentData,
  viewType,
  viewPage,
}) => ({ row: { treatmentDate, treatmentFacility, boundingBox, generatedPageNumber }}) => {
  const today = getCurrentDate()
  const dateOfLoss = documentData?.claimInfo.dateOfLoss;
  const { formatDisplay } = medicalTreatmentsDisplay.treatmentFacility

  const display = formatDisplay(treatmentFacility)

  const warningTooltipTitle = treatmentDate < dateOfLoss && 
    `Date of service is prior to date of loss: ${formatDate(new Date(dateOfLoss))}` ||
    (treatmentDate > today && 'Date of service is in the future')

  return treatmentFacility && (
    <Box>
      {viewType === 'carrier' ? (
         display
      ) : (
        <Stack direction="row" spacing={1} alignItems={'center'}>

          <Tooltip
            title={warningTooltipTitle}
            arrow
            placement="top-end"
          >
            {!!warningTooltipTitle && <WarningAmberOutlined color="warning" />}
          </Tooltip>
          <Typography
            variant="tableP1"
            color="secondary"
            onClick={(e) => {
              viewPage(generatedPageNumber, [boundingBox]);
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            {display}
          </Typography>
        </Stack>
      )}
    </Box>
  )
}

export const renderTreatmentDateCell = () => ({ row: { treatmentDate }}) => {
  const { formatDisplay, transformInput } = medicalTreatmentsDisplay.treatmentDate

  return  <Typography variant="tableP1">{formatDisplay(transformInput(treatmentDate))}</Typography>
}

export const renderTreatementDateEditCell = () => ({ id, field, api, row: { treatmentDate } }) => {
  const { formatDisplay, transformInput } = medicalTreatmentsDisplay.treatmentDate
  const currentDate = getCurrentDate()
  const defaultValue = moment(formatDisplay(transformInput(treatmentDate))).format('YYYY-MM-DD')

  return <TextField
            type="date"
            id="date_of_service"
            inputProps={{ max: currentDate}}
            defaultValue={defaultValue}
            fullWidth
            onChange={(event) => {
              api.setEditCellValue({ id, field, value: event.target.value }, event);
            }}
          />
}

export const renderStatusCell = () => ({ row: { status }}) => (
  <Typography variant="tableP1">{mapPageInsightStatusToDisplayStatus[status]}</Typography>
)
