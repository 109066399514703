import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Authenticator, useAuthenticator, ThemeProvider, Heading } from "@aws-amplify/ui-react";
import { Box, Container, Stack, Toolbar, Typography } from "@mui/material";
import AuthInstructions from "./AuthInstructions";
import Countdown from "./Countdown";
import awsExports from "../aws-exports";
import { userHasRole } from "../common";
import "@aws-amplify/ui-react/styles.css";

const NewLoginComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { route, authStatus } = useAuthenticator();
  const { userData } = useSelector((state) => state.User);

  const isAuthenticated = authStatus === "authenticated";
  const isConfiguring = authStatus === "configuring";
  const isSetupTOTPRoute = route === "setupTotp";
  const isForceNewPasswordRoute = route === "forceNewPassword";

  const from = location.state?.from?.pathname;

  // Handle redirection after login
  useEffect(() => {
    if (isAuthenticated && Object.keys(userData ?? {}).length > 0) {
      const defaultRoute = from
        ? from
        : userHasRole("PrecedentCaller", userData)
          ? "/contact-log-inventory"
          : "/inventory";
      navigate(defaultRoute);
    }
  }, [isAuthenticated, userData, from, navigate]);

  // Trim whitespace in username/password fields
  const trimFields = () => {
    const fields = document.querySelectorAll('input[name="username"], input[type="password"]');
    fields.forEach((field) => {
      if (field?.value) {
        field.value = field.value.replace(/\s/g, "").trim();
      }
    });
  };

  useEffect(() => {
    const handleKeyUp = () => trimFields();
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  // Add password rules dynamically
  useEffect(() => {
    if (isForceNewPasswordRoute) {
      const passwordFields = document.querySelectorAll('div[class*="amplify-passwordfield"]');
      if (Array.isArray(passwordFields) && passwordFields.length > 0) {
        const passwordRules = document.querySelector('div.password-rules') || document.createElement("div");
        passwordRules.className = "password-rules";
        passwordRules.innerHTML = `
          <ul style="margin-bottom: 0">
            <li>At least 8 characters</li>
            <li>1 number</li>
            <li>1 special character</li>
          </ul>
        `;
        passwordFields[0].after(passwordRules);
        passwordFields[1]?.style?.setProperty("margin-top", "-20px");
      }
    }
  }, [isForceNewPasswordRoute]);

  return (
    <Box sx={{ mt: 20 }}>
      <Toolbar />
      <Container maxWidth="md">
        <Stack justifyContent="space-around" sx={{ mt: 5 }}>
          <ThemeProvider theme={loginTheme}>
            {!isSetupTOTPRoute && (
              <Authenticator hideSignUp={true} components={components} formFields={formFields} />
            )}
            {isSetupTOTPRoute && (
              <Box>
                <Typography sx={{ textAlign: "center", mb: 1 }} variant="h4">
                  Set up multi-factor authentication
                </Typography>
                <Countdown seconds={900} />
                <AuthInstructions
                  qrCode={
                    <Authenticator hideSignUp={true} components={components} formFields={formFields} />
                  }
                />
              </Box>
            )}
          </ThemeProvider>
        </Stack>
      </Container>
    </Box>
  );
};

export default NewLoginComponent;

// Amplify Custom Theme
const loginTheme = {
  name: "precedent-theme",
  tokens: {
    colors: {
      brand: {
        primary: {
          "90": "#2C537B",
          "100": "#4077b0",
        },
      },
    },
    components: {
      button: {
        fontWeight: { value: "400" },
        primary: {
          backgroundColor: { value: "{colors.brand.primary[100]}" },
          _hover: {
            backgroundColor: { value: "{colors.brand.primary[90]}" },
          },
          _focus: {
            backgroundColor: { value: "{colors.brand.primary[100]}" },
          },
          _active: {
            backgroundColor: { value: "{colors.brand.primary[100]}" },
          },
        },
      },
    },
  },
};

// Amplify Custom Form Fields
const formFields = {
  setupTotp: {
    QR: {
      totpIssuer: awsExports.MFA_ISSUER,
    },
    confirmation_code: {
      label: "",
      placeholder: "6-digit passcode from Precedent-Exchange-prod",
      isRequired: true,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: "Enter the code from your Authenticator application.",
      placeholder: "Enter your Authenticator code:",
      isRequired: true,
    },
  },
};

// Amplify Custom Components
const components = {
  Header() {
    return null;
  },
  SetupTotp: {
    Header() {
      return null;
    },
  },
  ConfirmSignIn: {
    Header() {
      return (
        <Heading level={3}>
          Authenticator information
        </Heading>
      );
    },
  },
};
