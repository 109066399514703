import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { PersonOutline as PersonOutlineIcon, Language as LanguageIcon, MoreHorizOutlined } from '@mui/icons-material';
import { setCreateNewGlobalDemandOpen, setCreateNewDemandOpen } from '../../redux/slices/demandDomSlice';
import CustomMuiDialogWrapper from '../../CommonComponents/CustomMuiDialogWrapper';
import { Transition } from '../../Transition';
import ComposeDemand from './ComposeDemandForm';
import ComposeGlobalDemand from './ComposeGlobalDemandForm';
import { userHasPermission } from '../../common';
import { useGetCustomerConfigValueQuery } from '../../services/customerConfigs/customerConfigsApi';

const ComposeDemandButtonMenuList = () => {
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.User);
    const hasComposePermission = userHasPermission('DemandCompose', userData);

    const { data: demandNotesConfig, demandNotesConfigError, isDemandNotesConfigLoading } = useGetCustomerConfigValueQuery({ configKey: 'useDemandNotesEnabled', customerId: null });
    const { data: canComposeGlobalDemandConfig, canComposeGlobalDemandConfigError, isCanComposeGlobalDemandConfigLoading } = useGetCustomerConfigValueQuery({ configKey: 'canComposeGlobalDemand', customerId: null });

    const useDemandNotesEnabled = Boolean(demandNotesConfig?.configValue);
    const canComposeGlobalDemand = Boolean(canComposeGlobalDemandConfig?.configValue);

    const { createNewDemandOpen, createNewGlobalDemandOpen } = useSelector((state) => state.DemandDom);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        if (canComposeGlobalDemand) {
            setAnchorEl(event.currentTarget);
        } else {
            dispatch(setCreateNewDemandOpen(true));
        }
    };

    const handleClose = () => setAnchorEl(null);


    // Don't render anything if user doesn't have permission
    if (!hasComposePermission) {
        return null;
    }

    return (
        <div>
            <Button
                variant="contained"
                color="secondary"
                endIcon={canComposeGlobalDemand ? <MoreHorizOutlined /> : null}
                onClick={handleClick}
            >
                Compose demand
            </Button>

            {canComposeGlobalDemand && (
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    <MenuItem
                        onClick={() => {
                            dispatch(setCreateNewDemandOpen(true));
                            handleClose();
                        }}
                    >
                        <ListItemIcon> <PersonOutlineIcon fontSize="small" /> </ListItemIcon>
                        <ListItemText>Single client</ListItemText>
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            dispatch(setCreateNewGlobalDemandOpen(true));
                            handleClose();
                        }}
                    >
                        <ListItemIcon> <LanguageIcon fontSize="small" /> </ListItemIcon>
                        <ListItemText>Global</ListItemText>
                    </MenuItem>
                </Menu>
            )}

            <CustomMuiDialogWrapper
                open={createNewDemandOpen || createNewGlobalDemandOpen}
                fullWidth={true}
                fullScreen={true}
                TransitionComponent={Transition}
            >
                {createNewGlobalDemandOpen && (
                    <ComposeGlobalDemand
                        useDemandNotesEnabled={useDemandNotesEnabled}>
                    </ComposeGlobalDemand>
                )}

                {createNewDemandOpen && (
                    <ComposeDemand
                        useDemandNotesEnabled={useDemandNotesEnabled}>
                    </ComposeDemand>
                )}
            </CustomMuiDialogWrapper>
        </div>
    );
}

export default ComposeDemandButtonMenuList;