import React from 'react';
import { useSelector } from 'react-redux';
import {
  Stack,
  Button,
  DialogContent,
  DialogActions,
  Alert,
  AlertTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Typography,
} from '@mui/material';
import { useGetAllFilesByDocumentIdQuery } from '../services/documentFiles/documentFilesApi';
import { useConfirmationDialog } from './contexts/DialogContext';

const ReviewDocumentsDialog = () => {
  const { reviewDocumentsConfirmation, resetReviewDocumentsConfirmation } = useConfirmationDialog();
  const { handleOk, documentId } = reviewDocumentsConfirmation;

  const { integratedCase } = useSelector((state) => state.Integrations);
  const { claimNumber } = integratedCase;

  const { data: documentFiles = [] } = useGetAllFilesByDocumentIdQuery(documentId, { skip: !documentId });

  const previousIntegratedDocuments = documentFiles.filter((document) => document?.fileEntityData?.isExternalFile);

  const handleSave = () => {
    handleOk();
    resetReviewDocumentsConfirmation();
  };

  return (
    <>
      <DialogContent>
        <Stack spacing={2}>
          <Alert severity="warning">
            <AlertTitle>Review documents</AlertTitle>
            You linked a new Clio matter ({claimNumber}) to this demand. Consider removing the{' '}
            {previousIntegratedDocuments.length} document{previousIntegratedDocuments.length > 1 ? 's' : ''} from the
            previous matter.
          </Alert>

          <TableContainer
            sx={{
              borderRadius: 1,
              borderLeft: 1,
              borderRight: 1,
              borderTop: 1,
              borderColor: 'divider',
            }}
          >
            <Table
              size="small"
              sx={{
                '&:last-child': {
                  borderBottom: 0,
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Documents from previous matter</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {previousIntegratedDocuments.map((doc) => (
                  <TableRow key={doc.fileEntityId}>
                    <TableCell>{doc.fileEntityData.fileName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell sx={{ textAlign: 'right', py: 1 }}>
                    <Typography variant="tableP1">Total rows: {previousIntegratedDocuments.length}</Typography>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={resetReviewDocumentsConfirmation}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={handleSave}>
          Continue
        </Button>
      </DialogActions>
    </>
  );
};

export default ReviewDocumentsDialog;
