import React, { useState, useEffect, useMemo } from 'react';
import { TextField } from 'mui-rff';
import { useNavigate } from 'react-router-dom';
import { Stack, DialogContent, DialogTitle, Typography, Divider, Collapse, Alert, AlertTitle, FormHelperText, IconButton, Container, Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Close } from '@mui/icons-material';
import { Form } from 'react-final-form';
import ConfirmationDialog from '../../dialogs/ConfirmationDialog';
import Title from '../../CommonComponents/Title';
import { DiscardWork } from '../../dialogs/DialogText';
import { setCreateNewGlobalDemandOpen } from '../../redux/slices/demandDomSlice';
import { fetchCustomerData } from '../../redux/thunks/customerData';
import { useDispatch, useSelector } from 'react-redux';
import { getAvailableLiveTemplatesForCustomer, getConfigValue } from '../../api';
import { isLawFirmUser, isPrecedentUser, isLawFirmAdmin, isLawFirmApprover, isPrecedentAdmin } from '../../common-roles';
import { getCurrentDate, cleanNumber, normalizeEmailAddress, formatPhoneNumber } from '../../common';
import { coverageList, stateLabelValues } from '../../common-data';
import { parseNestedObject } from '../../helpers/formHelpers';
import { buildCarrierAutoCompleteData, loadCarriers, NO_TEMPLATE, buildUserAutoCompleteData, joinNames, getUserValues, getInitialCollaborators } from './FormHelpers';
import { handleDemandCreationSave } from './Utilities';
import { theme } from '../../Theme';
import { useSaveGlobalDocumentMutation } from '../../services/globalDemands/globalDemandsApi';
import { useLazyGetDocumentDataQuery } from '../../services/documentData/documentDataApi';
import SaveAnimationDialog from '../../dialogs/SaveAnimationDialog';
import { setToast } from '../../redux/slices/globalToastSlice';
import CreateNewDemandSkeleton from './ComposeDemandFormSkeleton';
import { get, merge } from 'lodash';
// import RequiredFieldsTooltip from './RequiredFieldsTooltip';
import { CUSTOMER_SPECIAL_NOTES } from '../../document-fields';
import { useFetchAuthorFormDataQuery } from '../../services/authFormData/authFormDataApi';
import { DemandTemplateField } from '../../FormFieldsAndSchemas/Demand/FieldComponents/MetadataFields';
import { ClaimInfoClaimNumberField, ClaimInfoInjuredPartyFullNameField, ClaimInfoInsuredFirstNameField, ClaimInfoClientIsTortfeasorField, ClaimInfoTortfeasorField, ClaimInfoDateOfLossField, ClaimInfoClaimCoverageField, ClaimInfoLossStateField, ClaimInfoLossCountyField, ClaimInfoPolicyLimitUnderstoodByFirmField } from '../../FormFieldsAndSchemas/Demand/FieldComponents/ClaimInfoFields';
import { RecipientCarrierCommonName, RecipientCarrierFaxNumber, RecipientCarrierEmailAddress, RecipientCarrierContactInfoSwitch, RecipientCarrierContactInfo } from '../../FormFieldsAndSchemas/Demand/FieldComponents/RecipientCarrierFields';
import { DemandResponseDueDateField, DemandResponseRelativeDueDateField, DemandDetailsContactInfoFields, DisplayDemandAmountField, DemandDetailsDisplayPolicyLimitIndicatorField, DemandDetailsOfferToSettleField, DemandDetailsUsingRelativeDueDateField } from '../../FormFieldsAndSchemas/Demand/FieldComponents/DemandDetailsFields';
import { ShowRecipientAdjusterContactInfoField, RecipientAdjusterContactInfoFields } from '../../FormFieldsAndSchemas/Demand/FieldComponents/RecipientAdjusterFields';
import { SendingFirmTINField, SendingFirmCaseTeamFields } from '../../FormFieldsAndSchemas/Demand/FieldComponents/SendingFirmFields';
import MultiClientFields from '../../FormFieldsAndSchemas/Demand/FieldComponents/MultiClientFields';
import { BasicLightColoredSaveButton } from './CommonComponents/Buttons';
import { setConfirmationDialog } from '../../redux/slices/globalDialogueSlice';


const CreateNewDemand = ({ useDemandNotesEnabled }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //redux state
    const { userData, user } = useSelector((state) => state.User);
    const { customerId } = userData;
    const { customerData } = useSelector((state) => state.Customer);
    //rtk query
    const [saveDocumentData] = useSaveGlobalDocumentMutation();
    const [triggerGetDocumentData] = useLazyGetDocumentDataQuery();

    const { data: authorFormData = {} } = useFetchAuthorFormDataQuery({ ...isPrecedentUser(userData) && { customerId } });
    //local state
    const [carriersData, setCarriersData] = useState([]);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [demandErrorState, setDemandErrorState] = useState({ show: false, severity: 'error', title: '', message: '' });
    const [dialogState, setDialogState] = useState({ discardChanges: false });
    const [availableTemplates, setAvailableTemplates] = useState([]);
    const [defaultCustomerTemplate, setDefaultCustomerTemplate] = useState(NO_TEMPLATE);
    const [defaultRelativeDueDate, setDefaultRelativeDueDate] = useState(0);
    const [formDataLoaded, setFormDataLoaded] = useState(false);
    //data mapping
    const { users = [], approvers = [] } = authorFormData;

    const approverIds = approvers?.map(approver => approver.userId) || [];
    const attorneys = users ? users.filter(user => approverIds.includes(user.userId)) : [];

    const attorney_auto_complete_data = buildUserAutoCompleteData(attorneys);
    const user_data_for_auto_completes = buildUserAutoCompleteData(users);
    const primaryContact_auto_complete_data = user_data_for_auto_completes;
    const carrier_auto_complete_data = buildCarrierAutoCompleteData(carriersData);
    const collaborators_auto_complete_data = user_data_for_auto_completes;
    const defaultCollaboratorsUserIds = customerData?.defaultCollaboratorsUserIds || [];
    //useEffects
    useEffect(() => { dispatch(fetchCustomerData({ customerId, user })); }, [customerId]);

    useEffect(() => {
        if (!user) return;

        const fetchData = async () => {
            try {
                loadCarriers(user, setCarriersData)
                // Await all data fetching promises
                const [templatesResponse, defaultTemplateResponse, relativeDueDateResponse] = await Promise.all([
                    getAvailableLiveTemplatesForCustomer(user),
                    getConfigValue('defaultTemplateId', null, user),
                    getConfigValue('defaultRelativeDueDate', null, user),
                ]);

                if (templatesResponse?.templates) {
                    setAvailableTemplates([{ templateId: NO_TEMPLATE, templateName: NO_TEMPLATE }, ...templatesResponse.templates]);
                }
                if (defaultTemplateResponse) setDefaultCustomerTemplate(defaultTemplateResponse);
                if (relativeDueDateResponse) setDefaultRelativeDueDate(relativeDueDateResponse);

                setFormDataLoaded(true);

            } catch (error) {
                dispatch(setToast({ isOpen: true, message: 'Failed to load data', severity: 'error' }));
            }
        };

        fetchData();

    }, [user]);

    const currentTemplate = availableTemplates.find(template => template.templateId === defaultCustomerTemplate);
    const currentTemplateDemandConfig = currentTemplate?.defaultDemandConfig || {};

    const handleSaveSuccessCallback = (documentId) => {
        if (!documentId) {
            dispatch(setToast({ isOpen: true, message: 'Failed to create demand', severity: 'error' }));
        }
        if (isLawFirmUser(userData)) {
            navigate('/l/' + documentId);
        } else if (isPrecedentUser(userData)) {
            navigate('/a/' + documentId);
        }
        dispatch(setCreateNewGlobalDemandOpen(false));
        triggerGetDocumentData({ documentId, pollPdfStatus: true });

    }

    const handleClose = () => dispatch(setCreateNewGlobalDemandOpen(false));

    const isPrimaryContactApprover = (userId) => {
        const primaryContact = users.find((user) => user.userId === userId);
        return isLawFirmAdmin(primaryContact) || isLawFirmApprover(primaryContact) || isPrecedentAdmin(primaryContact);
    };

    const initialValues = useMemo(() => {
        const currentDate = getCurrentDate();
        currentTemplateDemandConfig?.demandIsDeliverable && delete currentTemplateDemandConfig.demandIsDeliverable;

        const initialPrimaryContact = { label: joinNames(userData), value: getUserValues(userData), userId: userData.userId };
        const initialCollaborators = getInitialCollaborators(defaultCollaboratorsUserIds, initialPrimaryContact, users);

        return merge(
            {
                metadata: {
                    demandTemplateId: defaultCustomerTemplate,
                    clientTemplateId: NO_TEMPLATE
                },
                clients: [
                    {
                        claimInfo: {
                            clientIsInjuredParty: true,
                            claimant: { firstName: '' },
                            injuredPartyFullName: ''
                        },
                        sendingFirm: {
                            firmCaseNumber: ''
                        }
                    }
                ],
                sendingFirm: {
                    caseManagers: [],
                },
                claimInfo: {
                    dateOfLoss: currentDate,
                    clientIsInjuredParty: true,
                    insuredIsTortfeasor: true,
                    claimant: { firstName: '' },
                },
                demandDetails: {
                    displayDemandAmount: false,
                    policyLimitDemandIndicator: false,
                    demandResponseRelativeDueDate: defaultRelativeDueDate ?? '',
                    usingRelativeDueDate: defaultRelativeDueDate == 0 ? false : true
                },
                mutators: {
                    attorneyIsPrimaryContact: isPrimaryContactApprover(initialPrimaryContact.userId)
                },
                autoCompletes: {
                    primaryContact: initialPrimaryContact,
                    collaborators: initialCollaborators
                }
            },
            currentTemplateDemandConfig,
        );
    }, [users, userData, defaultCustomerTemplate, defaultCollaboratorsUserIds]);

    const handleSaveClick = (values) => {
        //if only one client, show confirmation dialog
        if (values?.clients?.length > 1) {
            handleConfirmedSave(values, false);
        } else {
            dispatch(setConfirmationDialog({
                open: true,
                data: {
                    title: "Global demand will be created with one (1) client.",
                    text: "Are you sure you want to save this as a global demand?",
                    confirmText: "Save",
                    cancelText: "Cancel",
                    hasAlert: true,
                    alertSeverity: "warning",
                    alertText: "Global demand with only one (1) client"
                },
                callbacks: {
                    handleConfirm: () => {
                        //closing dialog is handled by the dialog itself on confirm
                        handleConfirmedSave(values, false);
                    },
                    handleCancel: () => {
                        //any custom logic here; closing dialog is handled by the dialog itself on cancel
                    }
                }
            }));
        }
    }


    const handleConfirmedSave = async (values, sendForAiProcessing = false) => {

        const body = parseNestedObject({
            ...values,
            documentType: 'COMPOSITEDEMAND',
            damagesSummary: {
                ...values.damagesSummary,
                demandDamagesAmount: values?.damagesSummary?.demandDamagesAmount ? cleanNumber(values.damagesSummary.demandDamagesAmount) : ''
            },
            demandDetails: {
                ...values.demandDetails,
                demandOfferToSettleAmount: values?.demandDetails?.demandOfferToSettleAmount ? cleanNumber(values.demandDetails.demandOfferToSettleAmount) : ''
            },
            sendingFirm: {
                ...values.sendingFirm,
                //use autoComplete values to populate respective fields on sendingFirm object onSave
                attorney: values?.mutators?.attorneyIsPrimaryContact ? values?.autoCompletes?.primaryContact?.value : values?.autoCompletes?.attorney?.value,
                primaryContact: values?.autoCompletes?.primaryContact?.value,
                caseManagers: (values?.autoCompletes?.collaborators || []).map(collaborator => collaborator.value)
            },
            claimInfo: {
                ...values.claimInfo,
                insuredPolicyLimitsAsUnderstoodByFirm: values?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm ? cleanNumber(values.claimInfo.insuredPolicyLimitsAsUnderstoodByFirm) : ''
            },
            defaultDeliveryInformationEnabled: values?.autoCompletes?.carrier?.value?.defaultDeliveryInformationEnabled,
            recipientCarrier: {
                //use autoComplete values to populate respective fields on recipientCarrier object onSave
                ...values?.recipientCarrier,
                carrierCustomerAliasId: values?.autoCompletes?.carrier?.value?.carrierCustomerAliasId,
                carrierCommonName: values?.autoCompletes?.carrier?.value?.carrierCommonName || values?.recipientCarrier?.carrierCommonName,
                //use recipientCarrier.contactInfo values to populate respective fields b/c these fields are not fully controlled by autoComplete selection
                contactInfo: { ...values?.recipientCarrier?.contactInfo }
            },
            recipientAdjuster: {
                ...values.recipientAdjuster,
                contactInfo: {
                    ...values?.recipientAdjuster?.contactInfo,
                    emailAddress: values?.recipientAdjuster?.contactInfo?.emailAddress ? normalizeEmailAddress(values.recipientAdjuster.contactInfo.emailAddress) : '',
                    phoneNumber: values?.recipientAdjuster?.contactInfo?.phoneNumber ? formatPhoneNumber(values.recipientAdjuster.contactInfo.phoneNumber) : ''
                }
            },
            customerSpecialNotes: values?.customerSpecialNotes ? values.customerSpecialNotes : ''
        });

        delete body.autoCompletes;  //remove autoCompletes from document body before submitting
        delete body.mutators;  //remove mutators from document body before submitting

        handleDemandCreationSave({
            isGlobalDemand: true,
            data: body,
            dispatch,
            saveDocumentData,
            setIsSaving: setFormSubmitting,
            callbacks: {
                handleSaveSuccessCallback,
                handleClose
            }
        });

    }

    const handleCloseButtonClick = (dirty) => dirty ? setDialogState({ ...dialogState, discardChanges: true }) : handleClose();

    const confirmationDialogProps = {
        dialogOpen: dialogState.discardChanges,
        dialogText: DiscardWork.text,
        cancelButtonText: DiscardWork.cancelText,
        okButtonText: DiscardWork.confirmText,
        handleCancel: () => {
            setDialogState({ ...dialogState, discardChanges: false });
        }
    }

    return (
        <>
            <Form
                onSubmit={() => null}
                initialValues={initialValues}
                render={({ handleSubmit, form, values, valid, errors, dirty, touched }) => {

                    const carrierId = values?.autoCompletes?.carrier?.value?.carrierId;

                    return (
                        <form id="createDemand" onSubmit={handleSubmit}>

                            <SaveAnimationDialog
                                isSaving={formSubmitting}
                                message={"Submitting demand..."}
                            />

                            <DialogTitle className='dialog-title-blue' sx={{ height: "60px", width: "100%", zIndex: 1000 }} display="flex" alignItems="center" position={"fixed"}>
                                <Stack direction={"row"} style={{ width: "100%" }} alignItems={"center"} justifyContent={"space-between"}>
                                    <Stack direction={"row"} spacing={2} alignItems={"center"}>

                                        <IconButton
                                            onClick={e => handleCloseButtonClick(dirty)} aria-label="close"
                                            className='dark-bg-icon-button'
                                        >
                                            <Close color={'white'} />
                                        </IconButton>

                                        <Title color={theme.palette.white.main}>Compose global demand</Title>

                                    </Stack>

                                    <Button
                                        disabled={!valid || formSubmitting || !dirty || !formDataLoaded}
                                        values={values}
                                        color='white'
                                        variant='contained'
                                        className='dark-bg-button-contained'
                                        onClick={() => {
                                            setDemandErrorState({ ...demandErrorState, show: false });
                                            handleSaveClick(values);
                                        }}
                                    >
                                        Save and edit before AI processing
                                    </Button>

                                    {/* <BasicLightColoredSaveButton
                                        disabled={!valid || formSubmitting || !dirty || !formDataLoaded}
                                        values={values}
                                        clickHandler={() => {
                                            setDemandErrorState({ ...demandErrorState, show: false });
                                            handleSaveClick(values);
                                        }}
                                        label="Save and edit before AI processing"
                                    /> */}

                                </Stack>
                            </DialogTitle>

                            <DialogContent sx={{ my: '0px', py: '0px', overflow: 'visible' }}>
                                <Container maxWidth="md">
                                    <Grid container spacing={2} sx={{ mt: 8 }}>
                                        {demandErrorState.show &&
                                            <Grid xs={12}>
                                                <Collapse in={demandErrorState.show}>
                                                    <Alert severity={demandErrorState.severity}>
                                                        <AlertTitle>{demandErrorState.title}</AlertTitle>
                                                        {demandErrorState.message}
                                                    </Alert>
                                                </Collapse>
                                            </Grid>
                                        }
                                        <Grid xs={12} sx={{ paddingX: 0, pb: 5, marginX: 0, }} >
                                            <fieldset style={{ paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0 }} disabled={formSubmitting}>
                                                <>
                                                    {!formDataLoaded ?
                                                        <CreateNewDemandSkeleton />
                                                        :
                                                        <Stack spacing={2} divider={<Divider flexItem />} >

                                                            <Stack spacing={2}>
                                                                <Alert
                                                                    severity='info'
                                                                >
                                                                    <AlertTitle>
                                                                        Documents
                                                                    </AlertTitle>
                                                                    Upload documents to the global and client demands after you complete this form

                                                                </Alert>
                                                                <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>
                                                                    Select template
                                                                </Typography>
                                                                <DemandTemplateField
                                                                    form={form}
                                                                    values={values}
                                                                    availableTemplates={availableTemplates}
                                                                    isClientTemplate={false}
                                                                    isGlobalDemand={true}
                                                                />

                                                                <DemandTemplateField
                                                                    form={form}
                                                                    values={values}
                                                                    availableTemplates={availableTemplates}
                                                                    isClientTemplate={true}
                                                                    isGlobalDemand={true}
                                                                />
                                                            </Stack>

                                                            <Stack>

                                                                <Typography variant="body1" color="primary" sx={{ mb: 1.5 }} >
                                                                    Case details
                                                                </Typography>

                                                                <Grid container spacing={2}>

                                                                    <Grid xs={12} lg={6}>
                                                                        <ClaimInfoClaimNumberField />
                                                                    </Grid>

                                                                    <Grid xs={12} lg={6}>
                                                                        <ClaimInfoInsuredFirstNameField form={form} values={values} />
                                                                    </Grid>



                                                                    <Grid xs={12} lg={6}>
                                                                        <ClaimInfoClientIsTortfeasorField form={form} values={values} />
                                                                    </Grid>

                                                                    {!get(values, 'claimInfo.insuredIsTortfeasor') &&
                                                                        <Grid xs={12} lg={6}>

                                                                            <Box
                                                                                sx={{
                                                                                    pl: { xs: 6, lg: 0 }
                                                                                }}
                                                                            >
                                                                                <ClaimInfoTortfeasorField form={form} values={values} />
                                                                            </Box>

                                                                        </Grid>
                                                                    }





                                                                </Grid>
                                                            </Stack>
                                                            {/* clients */}
                                                            <Grid xs={12} md={12}>
                                                                <Stack spacing={1} >

                                                                    <Stack>
                                                                        <MultiClientFields form={form} values={values} />
                                                                    </Stack>

                                                                    {!get(values, 'claimInfo.clientIsInjuredParty') &&
                                                                        <ClaimInfoInjuredPartyFullNameField />
                                                                    }

                                                                </Stack>
                                                            </Grid>

                                                            {/* loss details */}
                                                            <Stack>
                                                                <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Loss details</Typography>
                                                                <Grid container spacing={2}>

                                                                    <Grid xs={12} md={6}>
                                                                        <ClaimInfoDateOfLossField />
                                                                    </Grid>

                                                                    <Grid xs={12} md={6}>
                                                                        <ClaimInfoClaimCoverageField coverageList={coverageList} />
                                                                    </Grid>

                                                                    <Grid xs={12} md={6}>
                                                                        <ClaimInfoLossStateField stateLabelValues={stateLabelValues} />
                                                                    </Grid>

                                                                    <Grid xs={12} md={6}>
                                                                        <ClaimInfoLossCountyField />
                                                                    </Grid>

                                                                </Grid>
                                                            </Stack>

                                                            <Stack>
                                                                <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Carrier details</Typography>
                                                                <Grid container spacing={2}>

                                                                    <Grid xs={12}>
                                                                        <RecipientCarrierCommonName
                                                                            form={form}
                                                                            values={values}
                                                                            carrier_auto_complete_data={carrier_auto_complete_data}
                                                                        />
                                                                    </Grid>

                                                                    {!carrierId &&
                                                                        <>
                                                                            <Grid xs={12} md={6}>
                                                                                <RecipientCarrierFaxNumber />
                                                                            </Grid>

                                                                            <Grid xs={12} md={6}>
                                                                                <RecipientCarrierEmailAddress />
                                                                            </Grid>
                                                                        </>
                                                                    }
                                                                    <Grid xs={12}>
                                                                        <RecipientCarrierContactInfoSwitch form={form} />
                                                                    </Grid>

                                                                    {values?.mutators?.showCarrierContactInfo &&
                                                                        <RecipientCarrierContactInfo />
                                                                    }

                                                                    <Grid xs={12}>
                                                                        < ShowRecipientAdjusterContactInfoField form={form} />
                                                                    </Grid>

                                                                    {values?.mutators?.showAdjusterContactInfo &&
                                                                        <RecipientAdjusterContactInfoFields />
                                                                    }
                                                                </Grid>
                                                            </Stack>

                                                            {/* demand details */}
                                                            <Stack>
                                                                <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Demand details</Typography>
                                                                <Grid container spacing={2}>

                                                                    <Grid xs={12} md={6}>
                                                                        <DisplayDemandAmountField form={form} values={values} />
                                                                    </Grid>

                                                                    <Grid xs={12} md={6}>
                                                                        {get(values, 'demandDetails.displayDemandAmount') !== false && (
                                                                            <DemandDetailsDisplayPolicyLimitIndicatorField form={form} values={values} />
                                                                        )}
                                                                    </Grid>

                                                                    <Grid xs={12} md={6}>
                                                                        <ClaimInfoPolicyLimitUnderstoodByFirmField form={form} values={values} />
                                                                    </Grid>

                                                                    <Grid xs={12} md={6}>
                                                                        <DemandDetailsOfferToSettleField values={values} />
                                                                    </Grid>

                                                                    <Grid xs={12} md={12}>
                                                                        <SendingFirmTINField />
                                                                    </Grid>

                                                                    <Grid xs={12} md={6}>
                                                                        {values?.demandDetails?.usingRelativeDueDate === true ?
                                                                            <DemandResponseRelativeDueDateField form={form} />
                                                                            :
                                                                            <DemandResponseDueDateField form={form} userData={userData} />
                                                                        }
                                                                    </Grid>

                                                                    <Grid xs={12} md={6}>
                                                                        <DemandDetailsUsingRelativeDueDateField form={form} />
                                                                    </Grid>
                                                                </Grid>
                                                            </Stack>
                                                            {/* case team */}
                                                            <Stack>
                                                                <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Case team</Typography>
                                                                <SendingFirmCaseTeamFields
                                                                    primaryContact_auto_complete_data={primaryContact_auto_complete_data}
                                                                    attorney_auto_complete_data={attorney_auto_complete_data}
                                                                    collaborators_auto_complete_data={collaborators_auto_complete_data}
                                                                    form={form}
                                                                    values={values}
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    isPrimaryContactApprover={isPrimaryContactApprover}
                                                                    userData={userData}
                                                                />
                                                            </Stack>

                                                            {/* response address */}
                                                            <Stack>
                                                                <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Response address</Typography>

                                                                <DemandDetailsContactInfoFields />

                                                            </Stack>

                                                            {useDemandNotesEnabled &&
                                                                <Stack>
                                                                    <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Notes</Typography>
                                                                    <Grid container spacing={1}>
                                                                        <Grid xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                required={CUSTOMER_SPECIAL_NOTES.required}
                                                                                label={CUSTOMER_SPECIAL_NOTES.label}
                                                                                name={CUSTOMER_SPECIAL_NOTES.path}
                                                                                inputProps={{ maxLength: 50 }}
                                                                            />
                                                                            <FormHelperText>{values?.customerSpecialNotes ? values.customerSpecialNotes.length : 0}/50</FormHelperText>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Stack>
                                                            }
                                                        </Stack>
                                                    }
                                                </>
                                            </fieldset>

                                        </Grid>
                                    </Grid>
                                </Container>
                            </DialogContent>
                            <ConfirmationDialog
                                handleOk={() => {
                                    setDialogState({ ...dialogState, discardChanges: false });
                                    handleClose();
                                }}
                                {...confirmationDialogProps}
                            />
                        </form >
                    )
                }
                }>
            </Form >
        </ >
    )
}

export default CreateNewDemand;
