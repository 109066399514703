import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { fetchAuthSession, getCurrentUser } from '@aws-amplify/auth';
import AuthenticationLoadingSpinner from './Authentication/AuthenticationLoadingSpinner';
import { MedicalsViewer } from './MedicalsComponents/MedicalsViewer';
import { MedicalsAdminViewer } from './MedicalsComponents/MedicalsAdminViewer';
import { MedicalsLawFirmViewer } from './MedicalsComponents/MedicalsLawFirmViewer';
import InventoryList from './InventoryList';
import { AdjusterList } from './AdjusterList';
import { AdjusterViewer } from './AdjusterViewer';
import CustomersView from './CustomerManagement/CustomersView';
import { CustomerManagementView } from './CustomerManagement/CustomerManagementView';
import { ContactManagementView } from './ContactManagement/ContactManagement';
import ContactLogInventoryView from './ContactLogInventoryView';
import LoginComponent from './Authentication/LoginComponent';
import { ShortCodeResolver } from './ShortCodeResolver';
import { Error404Page } from './Error404Page';
import { LAW_FIRM_ROLES, PRECEDENT_ADMIN_ROLE, PRECEDENT_ROLES } from './common-roles';
import HeaderAppBar from './HeaderAppBar';
import GlobalDialogues from './GlobalDialogues/Index';
import GlobalToasts from './GlobalToasts/Index';
import { setUser, setUserData, resetUserState } from './redux/slices/userSlice';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getUserApi } from './api';
import { observeUserIdentityEstablished } from './observability/observability';
import SessionManager from './Authentication/SessionManager';

const RouterComponent = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { authStatus } = useAuthenticator();
  const { userData } = useSelector((state) => state.User);

  const [loading, setLoading] = useState(true);
  const isAuthenticated = authStatus === "authenticated";

  useEffect(() => {
    const initializeUser = async () => {
      await dispatch(resetUserState())
      if (isAuthenticated) {
        try {
          const authSession = await fetchAuthSession();
          const currentUser = await getCurrentUser();
          const accessToken = authSession?.tokens?.accessToken || "";
          const username = currentUser?.username || authSession?.userSub || "";

          const authUser = {  //format expected payload for our pre-existing getUserApi call 
            username,
            signInUserSession: {
              accessToken: {
                jwtToken: accessToken.toString(),
              }
            }
          }

          dispatch(setUser(authUser));
          const userDataWithAttributes = await getUserApi(authUser);
          dispatch(setUserData(userDataWithAttributes));
          observeUserIdentityEstablished(userDataWithAttributes); // Observability record user
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
      setLoading(false);
    };

    if (authStatus !== "configuring") {
      initializeUser();
    }
  }, [isAuthenticated, authStatus, dispatch]);

  const renderProtectedRoute = (Component, requiredRoles = []) => {
    const currentRoute = location.pathname;

    if (loading) {
      return <AuthenticationLoadingSpinner />;
    }

    if (!isAuthenticated && currentRoute !== '/login') {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    // If roles are empty, assume they haven't loaded yet; show a spinner.
    if (requiredRoles.length && (!userData?.roles || userData.roles.length === 0)) {
      return <AuthenticationLoadingSpinner />;
    }

    if (
      requiredRoles.length &&
      !requiredRoles.some((role) => userData?.roles?.includes(role))
    ) {
      return <Navigate to="/404" replace />;
    }

    return (
      <SessionManager>
        <Component />
      </SessionManager>
    );
  }

  return (
    <>
      <HeaderAppBar />
      <GlobalDialogues />
      <GlobalToasts />

      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<LoginComponent />} />
        <Route path="/login" element={<LoginComponent />} />
        <Route path="/m/:documentId" element={<MedicalsViewer />} />
        <Route path="/404" element={<Error404Page />} />
        <Route path="/:shortCode" element={<ShortCodeResolver />} />

        {/* Protected Routes */}
        <Route
          path="/inventory"
          element={renderProtectedRoute(InventoryList, [...PRECEDENT_ROLES, ...LAW_FIRM_ROLES])}
        />
        <Route
          path="/firm-inventory"
          element={<Navigate replace to="/inventory" />}
        />
        <Route
          path="/contact-log-inventory"
          element={renderProtectedRoute(ContactLogInventoryView, PRECEDENT_ROLES)}
        />
        <Route
          path="/a/:documentId"
          element={renderProtectedRoute(MedicalsAdminViewer, PRECEDENT_ROLES)}
        />
        <Route
          path="/l/:documentId"
          element={renderProtectedRoute(
            MedicalsLawFirmViewer,
            [...LAW_FIRM_ROLES, PRECEDENT_ADMIN_ROLE]
          )}
        />
        <Route path="/adjuster" element={renderProtectedRoute(AdjusterList)} />
        <Route path="/adjuster/d/:documentId" element={renderProtectedRoute(AdjusterViewer)} />
        <Route
          path="/customer-management"
          element={renderProtectedRoute(CustomersView, [PRECEDENT_ADMIN_ROLE])}
        />
        <Route
          path="/customer-management/:customerId"
          element={renderProtectedRoute(CustomerManagementView, [PRECEDENT_ADMIN_ROLE])}
        />
        <Route
          path="/contact-management"
          element={renderProtectedRoute(ContactManagementView, [PRECEDENT_ADMIN_ROLE])}
        />

        {/* Catch-All Route */}
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </>
  );
};

export default RouterComponent;
