import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Stack, TextField, DialogContent, Alert, AlertTitle } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ArrowBack } from '@mui/icons-material';
import {
  useGetIntegrationMetadataQuery,
  useLazyPostSearchQuery,
  useLazyGetMatterQuery,
} from '../../../services/integrations/integrationsApi';
import { setIntegrationSearch } from '../../../redux/slices/globalDialogueSlice';
import {
  setIntegratedCase,
  setIntegrationDocumentSelectionModal,
  setPendingSelectedCase,
} from '../../../redux/slices/integrationsSlice';
import SearchResultsTable from './Components/SearchResultsTable';
import DialogTitleBanner from '../../../CommonComponents/Dialogs/DialogComponents/DialogTitleBanner';

const IntegrationSearchModal = () => {
  const dispatch = useDispatch();

  const { data: integrationMetadata = {} } = useGetIntegrationMetadataQuery();
  const { displayName: integrationName, integrationId, displayLabels = [] } = integrationMetadata;

  const [
    searchQuery,
    { data: searchResults = [], isFetching: isSearchLoading, isUninitialized: isNotSearched },
  ] = useLazyPostSearchQuery();

  const [getMatter, { isLoading: isMatterLoading }] = useLazyGetMatterQuery();

  const {
    integrationSearch: {
      data: { source },
    },
  } = useSelector((state) => state.GlobalDialogues);

  const [searchText, setSearchText] = useState('');
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const handleSearch = () => {
    if (searchText) {
      searchQuery({ integrationId, queryField: 'claimNumber', queryText: searchText });
    }
  };

  const handleClose = () => {
    dispatch(setIntegrationSearch({ open: false, data: {} }));
  };

  const handleSelectCase = async () => {
    const selectedCase = searchResults.find((result) => result.id === rowSelectionModel[0]) ?? {};
    // We need to query the integration to get the case details.
    const { data: matterDetails } = await getMatter({ integrationId, matterId: selectedCase.id });

    if (matterDetails !== undefined) {
      if (source === 'edit') {
        dispatch(setPendingSelectedCase({ ...matterDetails }));
      } else {
        dispatch(setIntegratedCase({ ...matterDetails }));
        dispatch(
          setIntegrationDocumentSelectionModal({
            open: true,
            data: { allowChangingLinkedCase: true },
          })
        );
      }
      handleClose();
    }
  };

  const shouldLimitRows = searchResults?.length > 50;

  const isSelectDisabled = rowSelectionModel.length === 0;

  const getHelperText = () =>
    displayLabels.find(({ labelId }) => labelId === 'search_helper_text')?.labelText ?? '';

  return (
    <>
      <DialogTitleBanner
        title={`Search ${integrationName}`}
        handleClose={handleClose}
        CloseIcon={ArrowBack}
        actions={
          <LoadingButton
            loading={isMatterLoading}
            disabled={isSelectDisabled}
            color="white"
            variant="contained"
            className="dark-bg-button-contained"
            onClick={handleSelectCase}
          >
            Select case
          </LoadingButton>
        }
      />

      <DialogContent>
        {shouldLimitRows && (
          <Alert severity="warning" sx={{ mt: 2 }}>
            <AlertTitle>More than 50 search results</AlertTitle>
            Try another search to narrow the results
          </Alert>
        )}

        <Stack spacing={2} sx={{ width: '40%', alignItems: 'flex-start' }} direction="row">
          <TextField
            variant="outlined"
            fullWidth={true}
            autoFocus
            helperText={getHelperText()}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
          />

          <Button
            variant="outlined"
            color="secondary"
            disabled={!searchText}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Stack>

        {!isNotSearched && (
          <SearchResultsTable
            rows={searchResults}
            isLoading={isSearchLoading}
            shouldLimitRows={shouldLimitRows}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
          />
        )}
      </DialogContent>
    </>
  );
};

export default IntegrationSearchModal;
