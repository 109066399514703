import { searchCarriersApi } from '../../api';
import moment from 'moment';
import { isValidEmail } from '../../common';
import { isValidPhoneNumber } from '../../Validations/validationList';
import { some, isObject } from 'lodash';
import { isPrecedentUser } from '../../common-roles';
import { green } from '@mui/material/colors';
import { InputAdornment } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';


export const joinNames = nameObject => `${nameObject?.firstName ? nameObject?.firstName : ''}${nameObject?.lastName ? ' ' + nameObject?.lastName : ''}`

export const blankContactInfoObject = {
    contactInfo: {
        phoneNumber: '',
        emailAddress: '',
        faxNumber: '',
        address: {
            fullAddress: '',
            street1: '',
            street2: '',
            city: '',
            state: '',
            zipCode: '',
        }
    }
}

export const buildUserAutoCompleteData = (users) =>
    //from the onset, map the user data for autoComplete element to the format expected by attorney and primary fields on save document {label: 'John Doe', value: {...}}
    //then just use the autoCompletes.attorney and autoCompletes.primaryContact to set the values of respective fields sendingFirm.attorney and sendingFirm.primaryContact fields onSave
    users?.map(user => {
        return {
            label: joinNames(user),
            value: getUserValues(user),
            userId: user.userId,
        }
    }) || [];

export const buildCarrierAutoCompleteData = (carriers) => {
    return carriers?.reduce((acc, carrier) => {

        const aliasOptions = carrier?.aliases?.map(aliasObj => {

            return {
                label: aliasObj?.alias || '',
                value: {
                    ...getCurrentCarrierValues(carrier),
                    carrierCommonName: aliasObj?.alias || '',
                }
            }
        }) || [];

        return [...acc, ...aliasOptions];
    }, []) || [];
}

export const buildCarrierAliasOptionsList = (carriers) => {  //to do remove when editDemand form is deprecated

    const carriersAliases = carriers?.map(carrier => {
        return {
            label: carrier?.name || '',
            value: carrier ? carrier : {}
        }
    }) || [];
    return carriersAliases.sort(((a, b) => a.label.localeCompare(b.label)));
};

export const NO_TEMPLATE = 'I will provide my own demand letter (No template)';

export const getCurrentUserOption = (userData) => ({ label: joinNames(userData), value: getUserValues(userData), userId: userData.userId });

export const getUserValues = (user) => {
    const sendingFirmAttorneyObject = {
        ...blankContactInfoObject,
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        contactInfo: {
            ...blankContactInfoObject.contactInfo,
            phoneNumber: user?.phone || user?.contactInfo?.phoneNumber || '',
            emailAddress: user?.emailAddress || user?.contactInfo?.emailAddress || '',
        }
    }
    return sendingFirmAttorneyObject;
}

export const getCurrentCarrierValues = (carrier) => {
    const defaultDeliveryChannelType = carrier?.defaultDeliveryChannelType || '';

    const isDefaultDeliveryInformationEnabled = () => {
        if (defaultDeliveryChannelType === 'email') {
            return Boolean(carrier?.deliveryChannels?.find(channel => channel.deliveryChannelType === 'email')?.deliveryDestinationAddress)
        } else if (defaultDeliveryChannelType === 'fax') {
            return Boolean(carrier?.deliveryChannels?.find(channel => channel.deliveryChannelType === 'fax')?.deliveryDestinationAddress)
        } else {
            return false;
        }
    }
    return {
        carrierCommonName: carrier.name,
        carrierCustomerAliasId: matchByAlias(carrier.name, carrier)?.aliasId || '',
        defaultDeliveryInformationEnabled: isDefaultDeliveryInformationEnabled(),
        contactInfo: {
            emailAddress: carrier?.deliveryChannels ? carrier.deliveryChannels.filter(channel => channel.deliveryChannelType === 'email')[0]?.deliveryDestinationAddress || '' : '',
            faxNumber: carrier?.deliveryChannels ? carrier.deliveryChannels.filter(channel => channel.deliveryChannelType === 'fax')[0]?.deliveryDestinationAddress || '' : '',
            phoneNumber: carrier?.contactInfo?.phoneNumber || '',
            address: carrier.address
        }
    }
}

export const splitName = fullName => {
    const lastSpaceIndex = fullName.lastIndexOf(" ");
    const firstName = fullName.substring(0, lastSpaceIndex).trim();
    const lastName = fullName.substring(lastSpaceIndex + 1).trim();
    return { firstName, lastName }
}

export const matchCarrierByAlias = (aliasName, carriers) => {
    if (aliasName && carriers) {
        return carriers.find((carrier) => {
            return carrier.aliases.find((alias) => aliasName.toLowerCase() === alias.alias.toLowerCase());
        });
    }
    return null;
}

export const matchByAlias = (aliasName, carrier) => carrier.aliases.find((alias) => aliasName.toLowerCase() === alias.alias.toLowerCase());

export const loadCarriers = async (user, setCarriersData) => {
    const response = await searchCarriersApi(user, "");
    if (response.status == 200) {
        const responseData = await response.json();
        if (responseData.length > 0) {
            setCarriersData(responseData);
        }
    }
    else {
        console.log(`Status code ${response.status} returned from get searchCarriersApi call.`)
    }
};

export const getMatchByName = (value, attorneys) => attorneys.find(attorney => joinNames(attorney) === value);

export const mapCustomerDemandFieldsToDocumentFields = (customerDemandFields = [], documentDemandFields = []) =>
    [...customerDemandFields]
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(({ fieldName, defaultValue, dataType }) => {
            const fieldValue =
                documentDemandFields.find((documentField) => documentField.fieldName === fieldName)?.fieldValue ??
                defaultValue;
            return { fieldName, fieldValue, dataType };
        });

export const hasTruthyValue = (obj) => {
    return some(obj, (value) => {
        if (isObject(value)) {
            return hasTruthyValue(value);
        }
        return !!value;
    });
}

export const validate = ({ values, userData }) => {
    const isPrecedent = isPrecedentUser(userData);
    if (!values) return true;
    const errors = {
        sendingFirm: { attorney: {}, primaryContact: {}, firmCaseNumber: '' },
        claimInfo: { claimant: { firstName: '' }, insuredFirstName: '', injuredPartyFullName: '', tortfeasorFullName: '', dateOfLoss: '', lossState: '' },
        demandDetails: { demandResponseDueDate: '' },
        recipientCarrier: { carrierCommonName: '', contactInfo: { phoneNumber: '', faxNumber: '', emailAddress: '' } },
        recipientAdjuster: { contactInfo: { phoneNumber: '', emailAddress: '' } },
        autoCompletes: { attorney: '', primaryContact: '', carrier: '', collaborators: '' }
    };
    !values?.sendingFirm?.firmCaseNumber && (errors.sendingFirm.firmCaseNumber = 'Required');
    !values?.claimInfo?.claimant?.firstName && (errors.claimInfo.claimant.firstName = 'Required');
    !values?.claimInfo?.insuredFirstName && (errors.claimInfo.insuredFirstName = 'Required');
    !values?.claimInfo?.clientIsInjuredParty && !values?.claimInfo?.injuredPartyFullName && (errors.claimInfo.injuredPartyFullName = 'Required');
    !values?.claimInfo?.insuredIsTortfeasor && !values?.claimInfo?.tortfeasorFullName && (errors.claimInfo.tortfeasorFullName = 'Required');
    !values?.claimInfo?.dateOfLoss && (errors.claimInfo.dateOfLoss = 'Required');
    values?.claimInfo?.dateOfLoss && !moment(values.claimInfo.dateOfLoss, 'YYYY-MM-DD', true).isValid() && (errors.claimInfo.dateOfLoss = 'Invalid date');
    values?.claimInfo?.dateOfLoss && moment(values.claimInfo.dateOfLoss).isAfter(moment()) && (errors.claimInfo.dateOfLoss = 'Date of loss cannot be in the future');
    values?.claimInfo?.dateOfLoss && moment(values.claimInfo.dateOfLoss).isBefore(moment().subtract(50, 'years')) && (errors.claimInfo.dateOfLoss = 'Date of loss must be more recent');
    values?.demandDetails?.demandResponseDueDate && !moment(values.demandDetails.demandResponseDueDate, 'YYYY-MM-DD', true).isValid() && (errors.demandDetails.demandResponseDueDate = 'Invalid date');
    values?.demandDetails?.demandResponseDueDate && !isPrecedent && moment(values.demandDetails.demandResponseDueDate).isBefore(moment()) && (errors.demandDetails.demandResponseDueDate = 'Due date cannot be in the past');
    values?.demandDetails?.demandResponseDueDate && !isPrecedent && moment(values.demandDetails.demandResponseDueDate).isAfter(moment().add(50, 'years')) && (errors.demandDetails.demandResponseDueDate = 'Due date must be more recent');
    values?.recipientCarrier?.contactInfo?.emailAddress && !isValidEmail(values.recipientCarrier.contactInfo.emailAddress) && (errors.recipientCarrier.contactInfo.emailAddress = 'Invalid email');
    values?.recipientAdjuster?.contactInfo?.phoneNumber && !isValidPhoneNumber(values.recipientAdjuster.contactInfo.phoneNumber) && (errors.recipientAdjuster.contactInfo.phoneNumber = 'Invalid phone number');
    values?.recipientAdjuster?.contactInfo?.emailAddress && !isValidEmail(values.recipientAdjuster.contactInfo.emailAddress) && (errors.recipientAdjuster.contactInfo.emailAddress = 'Invalid email');
    values?.recipientCarrier?.contactInfo?.faxNumber && !isValidPhoneNumber(values.recipientCarrier.contactInfo.faxNumber) && (errors.recipientCarrier.contactInfo.faxNumber = 'Invalid fax number');
    values?.recipientCarrier?.contactInfo?.phoneNumber && !isValidPhoneNumber(values.recipientCarrier.contactInfo.phoneNumber) && (errors.recipientCarrier.contactInfo.phoneNumber = 'Invalid phone number');
    values?.claimInfo?.lossState === 'none' && (errors.claimInfo.lossState = 'Required');
    !values?.autoCompletes?.primaryContact && (errors.autoCompletes.primaryContact = 'Required');
    !values?.autoCompletes?.attorney && !values?.mutators?.attorneyIsPrimaryContact && (errors.autoCompletes.attorney = 'Required');
    !values?.autoCompletes?.carrier && (errors.autoCompletes.carrier = 'Required');
    const hasErrors = hasTruthyValue(errors);
    return hasErrors ? errors : true
}



export const successFieldStyle = {
    '& .MuiOutlinedInput-root': {
        backgroundColor: green[50],
        '& fieldset': {
            borderColor: 'success.dark',
        },
        '&:hover fieldset': {
            borderColor: 'success.dark',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'success.dark',
        },
        '& .MuiInputBase-input': {
            color: 'success.dark',
        },
    }
    ,
    '& .MuiInputLabel-root': {
        color: 'success.dark'
    }
};


export const successFieldInputProps = {
    endAdornment: (
        <InputAdornment position="end">
            <CheckCircle color="success" fontSize="small" />
        </InputAdornment>
    )
}

export const getInitialCollaborators = (defaultCollaboratorsUserIds, initialPrimaryContact, users) => {
  const initialCollaborators = defaultCollaboratorsUserIds
    .map((userId) => {
      const user = users.find((user) => user.userId === userId);
      return { label: joinNames(user), value: getUserValues(user), userId };
    })
    .filter(({ userId }) => userId !== initialPrimaryContact.userId);
  initialCollaborators.push(initialPrimaryContact);

  return initialCollaborators;
};

