import { ArrowBack } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import React from 'react'
import TruncatedText from './TruncatedText';
import { useLocation, useNavigate } from 'react-router-dom';

const DemandBreadCrumb = ({ label, statusChip, callsToAction }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const handleBackNavClick = () => (location.state?.from ? navigate(location.state.from) : navigate('/inventory'));
    return (
        <Box sx={{
            position: 'fixed',
            zIndex: 10,
            top: 60,
            left: 5,
            right: 15,
            backgroundColor: '#FFFFFF'
        }}>
            <Stack
                justifyContent={'space-between'}
                direction={"row"}
                pt={2}
                sx={{
                    width: '100%',
                }}>
                <Stack
                    spacing={1}
                    direction={'row'}
                    alignItems="center"
                >

                    <IconButton onClick={handleBackNavClick}>
                        <ArrowBack />
                    </IconButton>
                    <TruncatedText text={label} />

                    <Box sx={{ pl: 1 }}>
                        {statusChip}
                    </Box>
                </Stack>

                {callsToAction}

            </Stack>
        </Box>
    )
}

export default DemandBreadCrumb