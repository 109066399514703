import { reduce, isEqual, isObject } from 'lodash';
import { formatDate } from '../../common';
import { setToast } from '../../redux/slices/globalToastSlice';
import { setPdfUrlPollingLoadingSkeleton } from '../../services/pdf/customSlice';

export const getDifferencesFromNestedObjects = (obj1, obj2) => {
  const differenceDictionary = reduce(obj1, (result, value, key) => {
    if (!isEqual(value, obj2[key])) {
      result[key] = isObject(value) && isObject(obj2[key])
        ? getDifferencesFromNestedObjects(value, obj2[key]) // Recurse for nested objects
        : value
    }
    return result;
  }, {});
  return differenceDictionary;
}

export const handleDemandEditingSave = async ({
  data = {},
  callbacks = {},
  dispatch,
  saveDocumentData,
  triggerGetDocumentData,
  documentId,
  closeAfterSave
}) => {

  try {
    callbacks?.setIsSaving(true);
    const documentSaveSuccess = await saveDocumentData({ data }).unwrap();
    documentSaveSuccess && dispatch(setToast({ message: 'Demand updated successfully', severity: 'success', isOpen: true, }));
    const documentRefetchSuccess = await triggerGetDocumentData({ documentId, pollPdfStatus: true, refetchDocumentData: false });
    //async wait 1 second to allow initialValues to repopulate on form
    await new Promise((resolve) => setTimeout(resolve, 1000));
    documentRefetchSuccess && callbacks?.setIsSaving(false);
    closeAfterSave && callbacks.handleClose();
  } catch (error) {
    dispatch(setToast({ message: `Error updating demand: ${error.message}`, severity: 'error', isOpen: true, }));
    callbacks.handleClose();
    callbacks?.setIsSaving(false);
  }
};

export const handleDemandCreationSave = async ({
  data = {},
  dispatch,
  saveDocumentData,
  callbacks = {},
  setIsSaving,
  isGlobalDemand = false,
}) => {
  setIsSaving(true);
  try {
    const documentSaveSuccess = await saveDocumentData({ data }).unwrap();
    const { documentId } = documentSaveSuccess;
    dispatch(setPdfUrlPollingLoadingSkeleton({ [documentId]: true }));

    if (!isGlobalDemand) {
      callbacks.uploadFiles(documentId)
    }

    await new Promise((resolve) => setTimeout(resolve, 2000));
    callbacks.handleSaveSuccessCallback(documentId);
    setIsSaving(false);
  } catch (error) {
    dispatch(setToast({
      message: `Error creating demand`,
      severity: 'error',
      isOpen: true,
    }));
    setIsSaving(false);
    callbacks?.handleClose();
  }
}

// For AI narrative generation
export const parseLines = (value) => {
  value = value.replace(/(\\n)/g, "\n");
  if (value.startsWith('"') && value.endsWith('"'))  // remove end quotes
    value = value.substring(1, value.length - 1);
  return value;
}

export const formatPageNumbers = (pageNumbers) => {
  const formattedList = [];
  let consecutiveRangeStart;
  let previousNumber;
  pageNumbers.forEach((pageNumber) => {
    if (consecutiveRangeStart && pageNumber === previousNumber + 1) {
      formattedList[formattedList.length - 1] = `${consecutiveRangeStart}-${pageNumber}`;
    } else {
      formattedList.push(pageNumber);
      consecutiveRangeStart = pageNumber;
    }
    previousNumber = pageNumber;
  });
  return formattedList.join(', ');
};

export const formatDateRange = (dates) => {
  const sortedDates = dates
    .filter(Boolean) // Filter out undefined dates
    .map((dateString) => new Date(dateString))
    .sort((a, b) => a.getTime() - b.getTime());

  return sortedDates.every((date) => date.getTime() === sortedDates[0].getTime())
    ? `${formatDate(sortedDates[0])}`
    : `${formatDate(sortedDates[0])} - ${formatDate(sortedDates[sortedDates.length - 1])}`;
};
