import awsExports from '../aws-exports';
import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { setToast } from '../redux/slices/globalToastSlice';
import { fetchAuthSession } from '@aws-amplify/auth';

export const AI_ENDPOINT_NAMES = [
    'getAiEnabledFields',
    'getAiDataLoaders',
    'generateNarrativeForField',
    'generateNarrativeAdmin',
    'loadAiData'
];

export const baseUrl = awsExports.ROOT_API_URL;

export const prepareHeaders = async (headers, { getState }) => {
    try {
        const authSession = await fetchAuthSession();
        const token = authSession?.tokens?.accessToken || '';
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
    } catch (error) {
        console.error('Error fetching auth token:', error);
    }
    return headers;
};

export const staggeredBaseQueryWithBailOut = retry(
    async (args, api, extraOptions) => {
        const isAiEndpoint = AI_ENDPOINT_NAMES.includes(api.endpoint);
        const result = await fetchBaseQuery({ baseUrl, prepareHeaders })(args, api, extraOptions);

        // If it's an AI endpoint and the status is 202, trigger a retry.
        if (isAiEndpoint && result.meta?.response?.status === 202) {
            console.log('Retrying on 202 status code');
            // Mark the response as not ok to signal the retry middleware.
            result.meta.response.ok = false;
            return result;
        }

        // For all other cases (non-AI endpoints or AI endpoints with errors other than 202),
        // immediately bail out if the response is not ok.
        if (!result.meta?.response?.ok) {
            return retry.fail(result.meta.response);
        }

        return result;
    },
    {
        maxRetries: 20,
    }
);

const buildErrorMessage = (errors) => {
    return errors.reduce((acc, curr) => {
        const { error, associationId } = curr;
        const associationMessage = associationId ? `Association ID: ${associationId}` : '';
        const errorStatus = error.status ? `Status: ${error.status}` : '';
        const errorMessage = error?.data?.message ? `Message: ${error.data.message}` : '';
        return `${acc} ${associationMessage} ${errorStatus} ${errorMessage}\n`;
    }, '');
};

export const errorDispatchToast = ({ errors, dispatch, rejectWithValue, rejectThunk }) => {
    const message = buildErrorMessage(errors);
    dispatch(setToast({ message, severity: 'error', isOpen: true }));
    return rejectThunk ? rejectWithValue(errors) : null;
};
