import { GridColumnMenu, GridColumnMenuManageItem } from '@mui/x-data-grid-pro'
import React from 'react'

const ColumnMenu = (props) => {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuSortItem: null,
                columnMenuPinningItem: null,
                columnMenuColumnsItem: null
            }}
            slotProps={{
                columnMenuFilterItem: {
                    displayOrder: 0,
                },

            }}
        />
    )
}

export default ColumnMenu

