import { createSlice } from '@reduxjs/toolkit';
import DemandScoreApiSlice from './demandScoreApi';

const initialState = {
    demandScoreData: {},
    demandScoreDataLoading: false,
    demandScoreDataError: null
};

export const DemandScoreSlice = createSlice({
    name: 'DemandScore',
    initialState,
    reducers: {
        setDemandScoreData: (state, action) => {
            state.demandScoreData = action.payload;
        },
        setDemandScoreError: (state, action) => {
            state.demandScoreDataError = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // This matcher will listen for actions fulfilled by the generateDemandScore endpoint and funnel queries, lazy queries, etc into the same reducer...
            // ... for simplified useSelector calls
            .addMatcher(
                (action) => {
                    return DemandScoreApiSlice.endpoints.generateDemandScore?.matchFulfilled(action);
                },
                (state, action) => {
                    state.demandScoreData = action.payload;
                    state.demandScoreDataLoading = false;
                }
            )
            .addMatcher(
                (action) => {
                    return DemandScoreApiSlice.endpoints.generateDemandScore?.matchPending(action);
                },
                (state, action) => {
                    state.demandScoreDataLoading = true;
                    state.demandScoreDataError = null;
                },
            )
            .addMatcher(
                (action) => {
                    return DemandScoreApiSlice.endpoints.generateDemandScore?.matchRejected(action);
                },
                (state, action) => {
                    state.demandScoreDataError = action.error;
                    state.demandScoreDataLoading = false;
                },
            );
    }
});

export const {
    setDemandScoreData,
    setDemandScoreError
} = DemandScoreSlice.actions;

export default DemandScoreSlice.reducer;