import React from 'react';
import {
  Folder as FolderIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Image as ImageIcon,
  LocalHospital as LocalHospitalIcon,
  Description as DescriptionIcon,
} from '@mui/icons-material';
import { CLAIM_INFO_CLAIMANT_FIRSTNAME, CLAIM_INFO_INSURED_FIRST_NAME, CLAIM_INFO_LOSS_STATE, CLAIM_INFO_DATE_OF_LOSS , CLAIM_INFO_TORTFEASOR, CLAIM_INFO_INJURED_PARTY } from '../../../FormFieldsAndSchemas/Demand/Schemas/ClaimInfo';
import { SENDING_FIRM_CASE_NUMBER } from '../../../FormFieldsAndSchemas/Demand/Schemas/SendingFirm';
import { setIsNewCaseSelected } from '../../../redux/slices/integrationsSlice';
import { setToast } from '../../../redux/slices/globalToastSlice';

export const parseQueryStringToObject = (queryString) => {
  const queryParams = new URLSearchParams(queryString);
  const authSuccess = queryParams.get('authSuccess') && queryParams.get('authSuccess') === 'true';
  const integrationState =
    queryParams.get('integrationState') && queryParams.get('integrationState').replaceAll("'", '"');
  const integrationStateJson = JSON.parse(integrationState);

  return {
    ...(authSuccess && { authSuccess }),
    ...(integrationState && { integrationState: integrationStateJson }),
  };
};

export const getFileIcon = (fileName) => {
  if (fileName.endsWith('.pdf')) return <InsertDriveFileIcon color="action" />;
  if (fileName.endsWith('.jpeg')) return <ImageIcon color="action" />;
  if (fileName.endsWith('.docx')) return <DescriptionIcon color="action" />;
  return <InsertDriveFileIcon color="action" />;
};

export const getFolderIcon = (folder) => {
  switch (folder) {
    case 'Medical attachments':
      return <LocalHospitalIcon color="secondary" />;
    default:
      return <FolderIcon color="secondary" />;
  }
};

export const prefillDemand = ({
  integrationName,
  claimInfo,
  claimNumber,
  form,
  formValues,
  showToast = true
}) => (dispatch) => {
  const claimantName = claimInfo?.claimant?.name
  const insuredName = claimInfo?.insured?.name
  const lossState = claimInfo?.lossState
  const dateOfLoss = claimInfo?.dateOfLoss

  const insuredIsTortfeasorFormValue = formValues?.claimInfo?.insuredIsTortfeasor
  const clientIsInjuredPartyFormValue = formValues?.claimInfo?.clientIsInjuredParty

  claimNumber && form.change(SENDING_FIRM_CASE_NUMBER.path, claimNumber);
  lossState && form.change(CLAIM_INFO_LOSS_STATE.path, lossState);
  dateOfLoss &&  form.change(CLAIM_INFO_DATE_OF_LOSS.path, dateOfLoss);

  if(claimantName) {
    form.change(CLAIM_INFO_CLAIMANT_FIRSTNAME.path, claimantName);
    clientIsInjuredPartyFormValue && form.change(CLAIM_INFO_INJURED_PARTY.path, claimantName)
  }

  if(insuredName) {
    form.change(CLAIM_INFO_INSURED_FIRST_NAME.path, insuredName);
    insuredIsTortfeasorFormValue && form.change(CLAIM_INFO_TORTFEASOR.path, claimInfo?.insured?.name)
  }

  showToast && dispatch(setToast({ isOpen: true, message: `Successfully imported details from ${integrationName}`, severity: 'success' }));
}