import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Button, DialogContent, DialogTitle, Typography, MenuItem, Box, Divider, Snackbar, Collapse, Alert, AlertTitle, Paper, FormHelperText, IconButton, ListItemIcon, ListItemText, Menu } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LoadingButton } from "@mui/lab";
import { AutoAwesome, Close, CloudUploadOutlined, EditOutlined, MoreHorizOutlined } from '@mui/icons-material';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import DocumentUploader from '../DocumentUploader';
import ConfirmationDialog from '../../dialogs/ConfirmationDialog';
import Title from '../../CommonComponents/Title';
import { DiscardWork } from '../../dialogs/DialogText';
import { setCreateNewDemandOpen, setDocumentUploaderList } from '../../redux/slices/demandDomSlice';
import { uploadFiles } from '../../redux/thunks/demandData';
import { fetchCustomerData } from '../../redux/thunks/customerData';
import { useDispatch, useSelector } from 'react-redux';
import { getAvailableLiveTemplatesForCustomer, getConfigValue } from '../../api';
import { isLawFirmUser, isPrecedentUser, isLawFirmAdmin, isLawFirmApprover, isPrecedentAdmin } from '../../common-roles';
import { getCurrentDate, cleanNumber, normalizeEmailAddress, formatPhoneNumber } from '../../common';
import { coverageList, stateLabelValues } from '../../common-data';
import { DOCUMENT_UPLOAD_FILE_TYPES, MAX_FILE_SIZE } from '../../common-document';
import { parseNestedObject } from '../../helpers/formHelpers';
import { buildCarrierAutoCompleteData, loadCarriers, validate, NO_TEMPLATE, buildUserAutoCompleteData, getCurrentUserOption, joinNames, getUserValues, getInitialCollaborators } from './FormHelpers';
import { handleDemandCreationSave } from './Utilities';
import { theme } from '../../Theme';
import { useSaveDocumentDataMutation } from '../../services/documentData/documentDataApi';
import { useGetIntegrationMetadataQuery, useLazyGetMatterQuery } from '../../services/integrations/integrationsApi';
import { useSaveFormStateMutation, useLazyGetFormStateQuery, useDeleteFormStateMutation, } from '../../services/formStateManagement/formStateManagementApi';
import SaveAnimationDialog from '../../dialogs/SaveAnimationDialog';
import { setToast } from '../../redux/slices/globalToastSlice';
import { setDocumentStatus, setIsAiProcessingForCreateDocument } from '../../redux/slices/documentSlice';
import CreateNewDemandSkeleton from './ComposeDemandFormSkeleton';
import { merge, get } from 'lodash';
import RequiredFieldsTooltip from './RequiredFieldsTooltip';
import { CUSTOMER_SPECIAL_NOTES } from '../../document-fields';
import { useFetchAuthorFormDataQuery } from '../../services/authFormData/authFormDataApi';
import SearchIntegrationButton from './Integrations/SearchIntegrationButton';
import { resetIntegrationState, setIsNewCaseSelected } from '../../redux/slices/integrationsSlice';
import UploadAdditionalDocumentsDropdown from './UploadAdditionalDocumentsDropdown';
//
import { SendingFirmCaseNumberField, SendingFirmTINField, SendingFirmCaseTeamFields } from '../../FormFieldsAndSchemas/Demand/FieldComponents/SendingFirmFields';
import { DemandTemplateField } from '../../FormFieldsAndSchemas/Demand/FieldComponents/MetadataFields';
import { ClaimInfoClaimNumberField, ClaimInfoClaimantFirstNameField, ClaimInfoClientIsInjuredPartyField, ClaimInfoInjuredPartyFullNameField, ClaimInfoInsuredFirstNameField, ClaimInfoClientIsTortfeasorField, ClaimInfoTortfeasorField, ClaimInfoDateOfLossField, ClaimInfoClaimCoverageField, ClaimInfoLossStateField, ClaimInfoLossCountyField, ClaimInfoPolicyLimitUnderstoodByFirmField } from '../../FormFieldsAndSchemas/Demand/FieldComponents/ClaimInfoFields';
import { RecipientCarrierCommonName, RecipientCarrierFaxNumber, RecipientCarrierEmailAddress, RecipientCarrierContactInfoSwitch, RecipientCarrierContactInfo } from '../../FormFieldsAndSchemas/Demand/FieldComponents/RecipientCarrierFields';
import { DemandResponseDueDateField, DemandResponseRelativeDueDateField, DemandDetailsContactInfoFields, DisplayDemandAmountField, DemandDetailsDisplayPolicyLimitIndicatorField, DemandDetailsOfferToSettleField, DemandDetailsUsingRelativeDueDateField } from '../../FormFieldsAndSchemas/Demand/FieldComponents/DemandDetailsFields';
import { ShowRecipientAdjusterContactInfoField, RecipientAdjusterContactInfoFields } from '../../FormFieldsAndSchemas/Demand/FieldComponents/RecipientAdjusterFields';
import { DamagesSummaryDemandDamagesAmountField } from '../../FormFieldsAndSchemas/Demand/FieldComponents/DamagesSummaryFields';
import { CLAIM_INFO_CLAIMANT_FIRSTNAME, CLAIM_INFO_INSURED_FIRST_NAME, CLAIM_INFO_LOSS_STATE, CLAIM_INFO_DATE_OF_LOSS } from '../../FormFieldsAndSchemas/Demand/Schemas/ClaimInfo';
import { SENDING_FIRM_CASE_NUMBER } from '../../FormFieldsAndSchemas/Demand/Schemas/SendingFirm';
import { DEMAND_DETAILS_DISPLAY_DEMAND_AMOUNT } from '../../FormFieldsAndSchemas/Demand/Schemas/DemandDetails';
//
import { parseQueryStringToObject, prefillDemand } from './Integrations/helpers';
import { setIntegratedCase } from '../../redux/slices/integrationsSlice';

const isUserAnApprover = (users, userId) => {
    const user = users.find((user) => user.userId === userId);
    return isLawFirmAdmin(user) || isLawFirmApprover(user) || isPrecedentAdmin(user);
};

const COMPOSE_DEMAND_FORM_STATE_ID = 'compose-demand-form';

const CreateNewDemand = ({ useDemandNotesEnabled }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //redux state
    const { userData, user } = useSelector((state) => state.User);
    const { customerId } = userData;
    const { customerData } = useSelector((state) => state.Customer);

    const { documentUploaderList } = useSelector(state => state.DemandDom); //todo: create a separate slice for document uploads
    const hasUploadErrors = documentUploaderList.some((file) => file.error);

    const { integratedCase: { id: matterId, claimNumber, claimInfo, sendingFirm }, isNewCaseSelected } = useSelector((state) => state.Integrations);
    const isIntegrationCaseLinked = Boolean(matterId && claimNumber);

    //rtk query
    const [saveDocumentData] = useSaveDocumentDataMutation();

    const { data: integrationMetadata } = useGetIntegrationMetadataQuery();
    const { displayName: integrationName, integrationId } = integrationMetadata || {};

    const { data: authorFormData = {} } = useFetchAuthorFormDataQuery({ ...isPrecedentUser(userData) && { customerId } });

    const [getFormState, { data: formState, isLoading: isFormStateLoading, isSuccess: isFormStateLoadSuccess }] = useLazyGetFormStateQuery();
    const [saveFormState] = useSaveFormStateMutation();
    const [deleteFormState] = useDeleteFormStateMutation();

    //local state
    const [carriersData, setCarriersData] = useState([]);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [demandErrorState, setDemandErrorState] = useState({ show: false, severity: 'error', title: '', message: '' });
    const [dialogState, setDialogState] = useState({ discardChanges: false });
    const [toastState, setToastState] = useState({ show: false, severity: 'info', message: '' })
    const [availableTemplates, setAvailableTemplates] = useState([]);
    const [defaultCustomerTemplate, setDefaultCustomerTemplate] = useState(NO_TEMPLATE);
    const [defaultRelativeDueDate, setDefaultRelativeDueDate] = useState(0);
    const [formDataLoaded, setFormDataLoaded] = useState(false);
    const [integrationDataLoaded, setIntegrationDataLoaded] = useState(false);
    const [anchorEl, setAnchorEl] = useState();

    //configs
    const isEditBeforeAiProcessingEnabled = Boolean(customerData?.canEditBeforeAiProcessing);
    const showIntegration = Boolean(integrationId);

    //data mapping
    const { users = [], approvers = [] } = authorFormData;

    const attorney_auto_complete_data = buildUserAutoCompleteData(approvers);
    const user_data_for_auto_completes = buildUserAutoCompleteData(users);
    const primaryContact_auto_complete_data = user_data_for_auto_completes;
    const carrier_auto_complete_data = buildCarrierAutoCompleteData(carriersData);
    const collaborators_auto_complete_data = user_data_for_auto_completes;
    const defaultCollaboratorsUserIds = customerData?.defaultCollaboratorsUserIds || [];
    const allDataLoaded = formDataLoaded && integrationDataLoaded;
    const useSavedForm = !isFormStateLoading && isFormStateLoadSuccess && formState && Object.keys(formState.state).length > 0;
    const [getMatter, { data: matterDetails, isLoading: isMatterLoading }] = useLazyGetMatterQuery();

    //useEffects
    useEffect(() => {
        dispatch(resetIntegrationState());

        const queryParams = parseQueryStringToObject(window.location.search);
        if (queryParams?.integrationState?.form === 'compose') {
            getFormState(COMPOSE_DEMAND_FORM_STATE_ID);
        }

        return () => {
            dispatch(setCreateNewDemandOpen(false));
            dispatch(resetIntegrationState());
            if (queryParams?.integrationState?.form === 'compose') {
                deleteFormState(COMPOSE_DEMAND_FORM_STATE_ID);
            }
            window.history.replaceState({}, '', window.location.pathname);
        }
    }, []);

    useEffect(() => {
        setIntegrationDataLoaded(integrationMetadata !== undefined);
    }, [integrationMetadata]);

    useEffect(() => {
        // Only proceed if we have all the required data
        if (!integrationDataLoaded || !formDataLoaded)
            return;

        // Check of we have any integration related query params.  If so, then set the case data which
        // triggers the proper state changes to highlight fields and enable the integration CTAs.
        const queryParams = parseQueryStringToObject(window.location.search);
        if (queryParams.integrationState?.form === 'linkMatter') {
            getMatter({ integrationId: integrationId, matterId: queryParams.integrationState.id });
        }
    }, [formDataLoaded, integrationDataLoaded]);

    useEffect(() => { dispatch(fetchCustomerData({ customerId, user })); }, [customerId]);

    useEffect(() => {
        if (!user) return;

        const fetchData = async () => {
            try {
                loadCarriers(user, setCarriersData);

                // Await all data fetching promises
                const [templatesResponse, defaultTemplateResponse, relativeDueDateResponse] = await Promise.all([
                    getAvailableLiveTemplatesForCustomer(user),
                    getConfigValue('defaultTemplateId', null, user),
                    getConfigValue('defaultRelativeDueDate', null, user),
                ]);

                if (templatesResponse?.templates) {
                    setAvailableTemplates([{ templateId: NO_TEMPLATE, templateName: NO_TEMPLATE }, ...templatesResponse.templates]);
                }
                if (defaultTemplateResponse) setDefaultCustomerTemplate(defaultTemplateResponse);
                if (relativeDueDateResponse) setDefaultRelativeDueDate(relativeDueDateResponse);

                setFormDataLoaded(true);

            } catch (error) {
                dispatch(setToast({ isOpen: true, message: 'Failed to load data', severity: 'error' }));
            }
        };

        fetchData();

    }, [user]);

    useEffect(() => {
        if (matterDetails !== undefined) {
            dispatch(setIntegratedCase({ ...matterDetails }));
        }
    }, matterDetails);

    const currentTemplate = availableTemplates.find(template => template.templateId === defaultCustomerTemplate);
    const currentTemplateDemandConfig = currentTemplate?.defaultDemandConfig || {};

    const handleSaveSuccessCallback = (documentId) => {
        if (!documentId) {
            dispatch(setToast({ isOpen: true, message: 'Failed to create demand', severity: 'error' }));
            dispatch(setCreateNewDemandOpen(false));
        }
        if (isLawFirmUser(userData)) {
            dispatch(setCreateNewDemandOpen(false));
            navigate('/l/' + documentId);
        } else if (isPrecedentUser(userData)) {
            dispatch(setCreateNewDemandOpen(false));
            navigate('/a/' + documentId);
        }
    }

    const isPrimaryContactApprover = (userId) => {
        const primaryContact = users.find((user) => user.userId === userId);
        return isLawFirmAdmin(primaryContact) || isLawFirmApprover(primaryContact) || isPrecedentAdmin(primaryContact);
    };

    const handleClose = () => dispatch(setCreateNewDemandOpen(false))
    const closeMenu = () => setAnchorEl(null);

    const initialValues = useMemo(() => {
        const currentDate = getCurrentDate();
        currentTemplateDemandConfig?.demandIsDeliverable && delete currentTemplateDemandConfig.demandIsDeliverable;

        const initialPrimaryContact = getCurrentUserOption(userData);
        const initialCollaborators = getInitialCollaborators(defaultCollaboratorsUserIds, initialPrimaryContact, users);

        return merge(
            {
                metadata: {
                    demandTemplateId: defaultCustomerTemplate,
                },
                sendingFirm: {
                    caseManagers: [],
                    firmCaseNumber: claimNumber || ''

                },
                claimInfo: {
                    clientIsInjuredParty: true,
                    insuredIsTortfeasor: true,
                    claimant: {
                        firstName: claimInfo?.claimant?.name
                    },
                    insuredFirstName: claimInfo?.insured?.name,
                    lossState: claimInfo?.lossState,
                    dateOfLoss: claimInfo?.dateOfLoss || currentDate
                },
                demandDetails: {
                    displayDemandAmount: false,
                    policyLimitDemandIndicator: false,
                    demandResponseRelativeDueDate: defaultRelativeDueDate ?? '',
                    usingRelativeDueDate: defaultRelativeDueDate == 0 ? false : true
                },
                mutators: {
                    attorneyIsPrimaryContact: isUserAnApprover(users, initialPrimaryContact.userId)
                },
                autoCompletes: {
                    primaryContact: initialPrimaryContact,
                    collaborators: initialCollaborators
                }
            },
            currentTemplateDemandConfig,
        );
    }, [users, userData, defaultCustomerTemplate, defaultCollaboratorsUserIds]);

    const handleSaveClick = async (values, sendForAiProcessing = true) => {
        const body = parseNestedObject({
            ...values,
            documentType: 'COMPOSITEDEMAND',
            damagesSummary: {
                ...values.damagesSummary,
                demandDamagesAmount: values?.damagesSummary?.demandDamagesAmount ? cleanNumber(values.damagesSummary.demandDamagesAmount) : ''
            },
            demandDetails: {
                ...values.demandDetails,
                demandOfferToSettleAmount: values?.demandDetails?.demandOfferToSettleAmount ? cleanNumber(values.demandDetails.demandOfferToSettleAmount) : ''
            },
            sendingFirm: {
                ...values.sendingFirm,
                //use autoComplete values to populate respective fields on sendingFirm object onSave
                attorney: values?.mutators?.attorneyIsPrimaryContact ? values?.autoCompletes?.primaryContact?.value : values?.autoCompletes?.attorney?.value,
                primaryContact: values?.autoCompletes?.primaryContact?.value,
                caseManagers: (values?.autoCompletes?.collaborators || []).map(collaborator => collaborator.value)
            },
            claimInfo: {
                ...values.claimInfo,
                insuredPolicyLimitsAsUnderstoodByFirm: values?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm ? cleanNumber(values.claimInfo.insuredPolicyLimitsAsUnderstoodByFirm) : ''
            },
            defaultDeliveryInformationEnabled: values?.autoCompletes?.carrier?.value?.defaultDeliveryInformationEnabled,
            recipientCarrier: {
                //use autoComplete values to populate respective fields on recipientCarrier object onSave
                ...values?.recipientCarrier,
                carrierCustomerAliasId: values?.autoCompletes?.carrier?.value?.carrierCustomerAliasId,
                carrierCommonName: values?.autoCompletes?.carrier?.value?.carrierCommonName || values?.recipientCarrier?.carrierCommonName,
                //use recipientCarrier.contactInfo values to populate respective fields b/c these fields are not fully controlled by autoComplete selection
                contactInfo: { ...values?.recipientCarrier?.contactInfo }
            },
            recipientAdjuster: {
                ...values.recipientAdjuster,
                contactInfo: {
                    ...values?.recipientAdjuster?.contactInfo,
                    emailAddress: values?.recipientAdjuster?.contactInfo?.emailAddress ? normalizeEmailAddress(values.recipientAdjuster.contactInfo.emailAddress) : '',
                    phoneNumber: values?.recipientAdjuster?.contactInfo?.phoneNumber ? formatPhoneNumber(values.recipientAdjuster.contactInfo.phoneNumber) : ''
                }
            },
            customerSpecialNotes: values?.customerSpecialNotes ? values.customerSpecialNotes : '',
            ...(isIntegrationCaseLinked && {
                caseManagementMetadata: {
                    sourceSystemId: integrationId,
                    matterId: claimNumber,
                    matterTechId: matterId,
                },
            }
            ),
        });
        delete body.autoCompletes;  //remove autoCompletes from document body before submitting
        delete body.mutators;  //remove mutators from document body before submitting

        if (sendForAiProcessing) {
            dispatch(setDocumentStatus('AwaitingMedicalExtraction'));
            dispatch(setIsAiProcessingForCreateDocument(true));
        }

        handleDemandCreationSave({
            data: body,
            dispatch,
            saveDocumentData,
            setIsSaving: setFormSubmitting,
            callbacks: {
                handleSaveSuccessCallback,
                uploadFiles: (documentId) => dispatch(uploadFiles({ documentId, user, sendForAiProcessing, shouldFetchDocumentData: false })),
            }
        });
    }

    const haveFilesUploaded = documentUploaderList.length > 0;

    const handleCloseButtonClick = (dirty) => dirty ? setDialogState({ ...dialogState, discardChanges: true }) : handleClose();

    const confirmationDialogProps = {
        dialogOpen: dialogState.discardChanges,
        dialogText: DiscardWork.text,
        cancelButtonText: DiscardWork.cancelText,
        okButtonText: DiscardWork.confirmText,
        handleCancel: () => {
            setDialogState({ ...dialogState, discardChanges: false });
        }
    }

    const handleFileProcessing = (inputFiles) => {
        const files = [...inputFiles].map((file) => {
            // Spread operator does not work on file object.
            file.isExternalFile = false;
            if (file.size > MAX_FILE_SIZE) {
                file.error = 'File size cannot exceed 150MB';
            }
            return file;
        });

        const newFileList = [...documentUploaderList, ...files];
        //iterate through new file list and if files have same name- remove the duplicate
        const uniqueFiles = newFileList.filter((file, index, self) =>
            index === self.findIndex((t) => (
                t.name === file.name
            ))
        )

        return uniqueFiles;
    };

    const handleSaveFormState = (values) => {
        const payload = {
            state: values
        };
        saveFormState({ stateId: COMPOSE_DEMAND_FORM_STATE_ID, payload });
    }

    return (
        <>
            <Form
                validate={(values) => validate({ values, userData })}
                onSubmit={() => null}
                initialValues={useSavedForm ? formState.state : initialValues}
                render={({ handleSubmit, form, values, valid, errors, dirty, touched }) => {
                    const carrierId = values?.autoCompletes?.carrier?.value?.carrierId;

                    if (isNewCaseSelected) {
                        dispatch(
                            prefillDemand({
                                integrationName,
                                claimInfo,
                                claimNumber,
                                form,
                                formValues: values
                            })
                        )
                        dispatch(setIsNewCaseSelected(false));
                    }

                    const sendingFirmCaseNumberMatchesIntegration = isIntegrationCaseLinked && get(values, SENDING_FIRM_CASE_NUMBER.path) === claimNumber;
                    const clientNameMatchesIntegration = isIntegrationCaseLinked && get(values, CLAIM_INFO_CLAIMANT_FIRSTNAME.path) === claimInfo?.claimant?.name;
                    const insuredNameMatchesIntegration = isIntegrationCaseLinked && claimInfo?.insured?.name && get(values, CLAIM_INFO_INSURED_FIRST_NAME.path) === claimInfo?.insured?.name;
                    const dateOfLossMatchesIntegration = isIntegrationCaseLinked && get(values, CLAIM_INFO_DATE_OF_LOSS.path) === claimInfo?.dateOfLoss;
                    const lossStateMatchesIntegration = isIntegrationCaseLinked && claimInfo?.lossState && get(values, CLAIM_INFO_LOSS_STATE.path) === claimInfo?.lossState;

                    return (
                        <form id="createDemand" onSubmit={handleSubmit}>
                            <SaveAnimationDialog
                                isSaving={formSubmitting}
                                message={"Submitting demand..."}
                            />

                            <DialogTitle className='dialog-title-blue' sx={{ height: "60px", width: "100%", zIndex: 1000 }} display="flex" alignItems="center" position={"fixed"}>
                                <Stack direction={"row"} style={{ width: "100%" }} alignItems={"center"} justifyContent={"space-between"}>
                                    <Stack direction={"row"} spacing={2} alignItems={"center"}>

                                        <IconButton
                                            onClick={e => handleCloseButtonClick(dirty || haveFilesUploaded)} aria-label="close"
                                            className='dark-bg-icon-button'
                                        >
                                            <Close color={'white'} />
                                        </IconButton>

                                        <Title color={theme.palette.white.main}>Compose single client demand</Title>

                                    </Stack>

                                    {/* large viewport primary buttons */}
                                    <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"flex-end"} sx={{ display: { xs: 'none', md: 'flex' } }}
                                    >
                                        <UploadAdditionalDocumentsDropdown
                                            handleFileProcessing={handleFileProcessing}
                                            DOCUMENT_UPLOAD_FILE_TYPES={DOCUMENT_UPLOAD_FILE_TYPES}
                                        />

                                        <RequiredFieldsTooltip values={values} touched={touched} errors={errors} valid={valid} hasUploadErrors={hasUploadErrors}>

                                            {isEditBeforeAiProcessingEnabled && (
                                                <LoadingButton
                                                    disabled={!valid || formSubmitting || !haveFilesUploaded || hasUploadErrors || (!useSavedForm && !dirty)}
                                                    onClick={() => {
                                                        setDemandErrorState({ ...demandErrorState, show: false });
                                                        handleSaveClick(values, false)
                                                    }}
                                                    variant="outlined"
                                                    color="white"
                                                    className='dark-bg-button-outlined'
                                                >
                                                    <span>Save and edit before AI processing</span>
                                                </LoadingButton>
                                            )}
                                        </RequiredFieldsTooltip>
                                        <RequiredFieldsTooltip values={values} touched={touched} errors={errors} valid={valid} hasUploadErrors={hasUploadErrors}>
                                            <LoadingButton
                                                disabled={!valid || formSubmitting || !haveFilesUploaded || hasUploadErrors || (!useSavedForm && !dirty)}
                                                className='dark-bg-button-contained'
                                                onClick={() => {
                                                    setDemandErrorState({ ...demandErrorState, show: false });
                                                    handleSaveClick(values, true)
                                                }}
                                                variant="contained"
                                                color="white"
                                            >
                                                Submit for AI processing
                                            </LoadingButton>
                                        </RequiredFieldsTooltip>

                                    </Stack>

                                    {/* small viewport primary buttons (in a menu) */}
                                    <Button
                                        variant="contained"
                                        color="white"
                                        onClick={(event) => {
                                            setAnchorEl(event.currentTarget);
                                        }}
                                        endIcon={<MoreHorizOutlined />}
                                        sx={{
                                            display: {
                                                xs: 'flex',
                                                md: 'none'
                                            }
                                        }}
                                    >
                                        Actions
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={closeMenu}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >

                                        {haveFilesUploaded &&
                                            <Box>
                                                <input
                                                    accept={DOCUMENT_UPLOAD_FILE_TYPES}
                                                    style={{ display: 'none' }}
                                                    id="raised-button-file"
                                                    multiple
                                                    type="file"

                                                    onChange={(e) => {
                                                        const uniqueFiles = handleFileProcessing(e.target.files);
                                                        dispatch(setDocumentUploaderList(uniqueFiles));
                                                    }}

                                                />
                                                <label htmlFor="raised-button-file">
                                                    <MenuItem key="upload-docs"
                                                        onClick={closeMenu}
                                                    >
                                                        <ListItemIcon>
                                                            <CloudUploadOutlined />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Upload documents" />
                                                    </MenuItem>
                                                </label>
                                            </Box>
                                        }

                                        <RequiredFieldsTooltip errors={errors} values={values} touched={touched} valid={valid} hasUploadErrors={hasUploadErrors} >
                                            {isEditBeforeAiProcessingEnabled &&
                                                <MenuItem
                                                    key="edit-before-ai-processing"
                                                    disabled={!valid || formSubmitting || !haveFilesUploaded || hasUploadErrors || (!useSavedForm && !dirty)}
                                                    onClick={() => {
                                                        setDemandErrorState({ ...demandErrorState, show: false });
                                                        handleSaveClick(values, false)
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <EditOutlined />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Save and edit before AI processing" />
                                                </MenuItem>
                                            }

                                            <MenuItem
                                                key="submit for AI processing"
                                                disabled={!valid || formSubmitting || !haveFilesUploaded || hasUploadErrors || (!useSavedForm && !dirty)}
                                                onClick={() => {
                                                    setDemandErrorState({ ...demandErrorState, show: false });
                                                    handleSaveClick(values, true)
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <AutoAwesome />
                                                </ListItemIcon>
                                                <ListItemText primary="Submit for AI processing" />
                                            </MenuItem>
                                        </RequiredFieldsTooltip>
                                    </Menu>

                                </Stack>
                            </DialogTitle>

                            <DialogContent sx={{ my: '0px', py: '0px', overflow: 'visible' }}>
                                <Grid container spacing={2} sx={{ mt: 8 }}>
                                    {demandErrorState.show &&
                                        <Grid xs={12}>
                                            <Collapse in={demandErrorState.show}>
                                                <Alert severity={demandErrorState.severity}>
                                                    <AlertTitle>{demandErrorState.title}</AlertTitle>
                                                    {demandErrorState.message}
                                                </Alert>
                                            </Collapse>
                                        </Grid>
                                    }
                                    {/* Left Column */}
                                    <Grid xs={12} sm={12} md={12} lg={6} xl={6} sx={{ paddingX: 0, pb: 5, marginX: 0, }} >
                                        <fieldset style={{ paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0 }} disabled={formSubmitting}>

                                            <>
                                                {!allDataLoaded ?

                                                    <CreateNewDemandSkeleton />
                                                    :
                                                    <Stack spacing={2} divider={<Divider flexItem />} >

                                                        {showIntegration &&
                                                            <SearchIntegrationButton
                                                                source="compose"
                                                                handleSaveFormState={() => handleSaveFormState(values)}
                                                            />
                                                        }
                                                        <Stack spacing={2}>
                                                            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>
                                                                Select template
                                                            </Typography>
                                                            <DemandTemplateField
                                                                form={form}
                                                                values={values}
                                                                availableTemplates={availableTemplates}
                                                                isClientTemplateField={false}
                                                                isGlobalDemand={false}
                                                            />
                                                        </Stack>

                                                        <Stack>

                                                            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }} >
                                                                Case details
                                                            </Typography>

                                                            <Grid container spacing={2}>

                                                                <Grid xs={12} md={6}>
                                                                    <SendingFirmCaseNumberField sendingFirmCaseNumberMatchesIntegration={sendingFirmCaseNumberMatchesIntegration} />
                                                                </Grid>

                                                                <Grid xs={12} md={6}>
                                                                    <ClaimInfoClaimNumberField />
                                                                </Grid>

                                                                <Grid xs={12} md={12}>
                                                                    <Stack spacing={1} >

                                                                        <ClaimInfoClaimantFirstNameField
                                                                            form={form}
                                                                            values={values}
                                                                            attorney_auto_complete_data={attorney_auto_complete_data}
                                                                            carrier_auto_complete_data={carrier_auto_complete_data}
                                                                            user_data_for_auto_completes={user_data_for_auto_completes}
                                                                            clientNameMatchesIntegration={clientNameMatchesIntegration}
                                                                        />

                                                                        <ClaimInfoClientIsInjuredPartyField form={form} values={values} />

                                                                        {!values.claimInfo?.clientIsInjuredParty &&
                                                                            <Box
                                                                                sx={{
                                                                                    pl: 6
                                                                                }}
                                                                            >
                                                                                <ClaimInfoInjuredPartyFullNameField />
                                                                            </Box>
                                                                        }

                                                                    </Stack>
                                                                </Grid>

                                                                <Grid xs={12} md={12}>
                                                                    <Stack spacing={1}>

                                                                        <ClaimInfoInsuredFirstNameField form={form}
                                                                            values={values}
                                                                            insuredNameMatchesIntegration={insuredNameMatchesIntegration}
                                                                        />

                                                                        <ClaimInfoClientIsTortfeasorField form={form} values={values} />

                                                                        {!values.claimInfo?.insuredIsTortfeasor &&
                                                                            <Box
                                                                                sx={{ pl: 6 }}
                                                                            >
                                                                                <ClaimInfoTortfeasorField form={form} values={values} />
                                                                            </Box>
                                                                        }

                                                                    </Stack>
                                                                </Grid>
                                                            </Grid>
                                                        </Stack>

                                                        {/* loss details */}
                                                        <Stack>
                                                            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Loss details</Typography>
                                                            <Grid container spacing={2}>

                                                                <Grid xs={12} md={6}>
                                                                    <ClaimInfoDateOfLossField dateOfLossMatchesIntegration={dateOfLossMatchesIntegration} />
                                                                </Grid>

                                                                <Grid xs={12} md={6}>
                                                                    <ClaimInfoClaimCoverageField coverageList={coverageList} />
                                                                </Grid>

                                                                <Grid xs={12} md={6}>
                                                                    <ClaimInfoLossStateField stateLabelValues={stateLabelValues} lossStateMatchesIntegration={lossStateMatchesIntegration} />
                                                                </Grid>

                                                                <Grid xs={12} md={6}>
                                                                    <ClaimInfoLossCountyField />
                                                                </Grid>
                                                            </Grid>

                                                        </Stack>

                                                        <Stack>
                                                            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Carrier details</Typography>
                                                            <Grid container spacing={2}>

                                                                <Grid xs={12}>

                                                                    <RecipientCarrierCommonName
                                                                        form={form}
                                                                        values={values}
                                                                        carrier_auto_complete_data={carrier_auto_complete_data}
                                                                    />

                                                                </Grid>

                                                                {!carrierId &&
                                                                    <>
                                                                        <Grid xs={12} md={6}>
                                                                            <RecipientCarrierFaxNumber />
                                                                        </Grid>

                                                                        <Grid xs={12} md={6}>
                                                                            <RecipientCarrierEmailAddress />
                                                                        </Grid>
                                                                    </>
                                                                }
                                                                <Grid xs={12}>
                                                                    <RecipientCarrierContactInfoSwitch form={form} />
                                                                </Grid>

                                                                {values?.mutators?.showCarrierContactInfo &&
                                                                    <RecipientCarrierContactInfo />
                                                                }

                                                                <Grid xs={12}>
                                                                    <ShowRecipientAdjusterContactInfoField form={form} />
                                                                </Grid>

                                                                {values?.mutators?.showAdjusterContactInfo &&
                                                                    <RecipientAdjusterContactInfoFields form={form} />
                                                                }
                                                            </Grid>
                                                        </Stack>

                                                        {/* demand details */}
                                                        <Stack>
                                                            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Demand details</Typography>
                                                            <Grid container spacing={2}>
                                                                <Grid xs={12} md={6}>
                                                                    <DisplayDemandAmountField form={form} values={values} />
                                                                </Grid>

                                                                <Grid xs={12} md={6}>
                                                                    {get(values, DEMAND_DETAILS_DISPLAY_DEMAND_AMOUNT.path) !== false && (
                                                                        <DemandDetailsDisplayPolicyLimitIndicatorField form={form} values={values} />
                                                                    )}
                                                                </Grid>

                                                                <Grid xs={12} md={6}>
                                                                    <ClaimInfoPolicyLimitUnderstoodByFirmField form={form} values={values} />
                                                                </Grid>

                                                                <Grid xs={12} md={6}>
                                                                    <DemandDetailsOfferToSettleField values={values} />
                                                                </Grid>
                                                                <Grid xs={12} md={6}>
                                                                    <Stack direction={"row"} spacing={2}>
                                                                        <DamagesSummaryDemandDamagesAmountField />
                                                                    </Stack>
                                                                </Grid>


                                                                <Grid xs={12} md={6}>
                                                                    <SendingFirmTINField />
                                                                </Grid>

                                                                <Grid xs={12} md={6}>
                                                                    {values?.demandDetails?.usingRelativeDueDate === true ?

                                                                        <DemandResponseRelativeDueDateField form={form} />
                                                                        :
                                                                        <DemandResponseDueDateField form={form} userData={userData} />
                                                                    }
                                                                </Grid>

                                                                <Grid xs={12} md={6}>
                                                                    <DemandDetailsUsingRelativeDueDateField form={form} />
                                                                </Grid>

                                                            </Grid>
                                                        </Stack>

                                                        {/* case team */}
                                                        <Stack>
                                                            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Case team</Typography>
                                                            <SendingFirmCaseTeamFields
                                                                primaryContact_auto_complete_data={primaryContact_auto_complete_data}
                                                                attorney_auto_complete_data={attorney_auto_complete_data}
                                                                collaborators_auto_complete_data={collaborators_auto_complete_data}
                                                                form={form}
                                                                values={values}
                                                                touched={touched}
                                                                errors={errors}
                                                                isPrimaryContactApprover={isPrimaryContactApprover}
                                                                userData={userData}
                                                            />
                                                        </Stack>

                                                        {/* response address */}
                                                        <Stack>
                                                            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Response address</Typography>

                                                            <DemandDetailsContactInfoFields />

                                                        </Stack>

                                                        {useDemandNotesEnabled &&
                                                            <Stack>
                                                                <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Notes</Typography>
                                                                <Grid container spacing={1}>
                                                                    <Grid xs={12}>
                                                                        <TextField
                                                                            fullWidth
                                                                            required={CUSTOMER_SPECIAL_NOTES.required}
                                                                            label={CUSTOMER_SPECIAL_NOTES.label}
                                                                            name={CUSTOMER_SPECIAL_NOTES.path}
                                                                            inputProps={{ maxLength: 50 }}
                                                                        />
                                                                        <FormHelperText>{values?.customerSpecialNotes ? values.customerSpecialNotes.length : 0}/50</FormHelperText>
                                                                    </Grid>
                                                                </Grid>
                                                            </Stack>
                                                        }
                                                    </Stack>
                                                }
                                            </>
                                        </fieldset>

                                    </Grid>
                                    {/* Right Column */}
                                    <Grid xs={12} sm={12} md={12} lg={6} xl={6} sx={{ pb: 0 }}>
                                        <Stack spacing={2} sx={{ width: "100%", top: 72, position: "sticky" }}>
                                            <fieldset disabled={formSubmitting}>
                                                <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Documents</Typography>
                                                <DocumentUploader />
                                            </fieldset>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <ConfirmationDialog
                                handleOk={() => {
                                    setDialogState({ ...dialogState, discardChanges: false });
                                    handleClose();
                                }}
                                {...confirmationDialogProps}
                            />
                        </form>
                    )
                }
                }>
            </Form>
            <Snackbar
                open={toastState.show}
                autoHideDuration={7000}
                onClose={e => setToastState({ ...toastState, show: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Paper elevation={8}>
                    <Alert onClose={e => setToastState({ ...toastState, show: false })} severity={toastState.severity} sx={{ width: '100%' }}>{toastState.message}</Alert>
                </Paper>
            </Snackbar>

        </ >
    )
}

export default CreateNewDemand;
