import { apiSlice } from '../apiSlice';

const customerConfigsApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({

        // POST /getConfigValue
        getCustomerConfigValue: builder.query({
            query: ({ configKey, customerId = null }) => ({
                url: '/getConfigValue',
                method: 'POST',
                body: JSON.stringify({
                    configKey: configKey,
                    ...(customerId && { customerId })
                }),
            }),
        }),
    }),
});

export const {
    useGetCustomerConfigValueQuery,
} = customerConfigsApi;

export default customerConfigsApi;
