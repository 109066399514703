import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDemandNotesApi } from '../api';
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  CircularProgress,
  Alert,
} from '@mui/material';
import { Transition } from '../Transition';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';
import { setDocumentDataLoading } from '../redux/slices/documentSlice';
import { fetchDocumentData } from '../redux/thunks/documentData';
import { fetchInventoryDemandData } from '../redux/thunks/demandData';
import { setLoading } from '../redux/slices/inventorySlice';
import { setToast } from '../redux/slices/globalToastSlice';

const EditNotesDialog = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showError, setShowError] = useState(false);

  const dispatch = useDispatch();

  const { editDemandNotes } = useSelector((state) => state.GlobalDialogues);
  const { open, data, isOpenFromInventory } = editDemandNotes;
  const { documentId, user, initialNote } = data;

  const { documentData } = useSelector((state) => state.Document);
  const documentDataNote = documentData?.customerSpecialNotes || '';

  const { pagination, filterState, contactData } = useSelector((state) => state.Inventory);

  const [note, setNote] = useState(initialNote || documentDataNote);

  const refreshData = () => {
    if (isOpenFromInventory) {
      const requestBody = {};
      requestBody['page'] = pagination.page;
      requestBody['page_size'] = pagination.pageSize;
      requestBody['sort_column'] = pagination.sort;
      requestBody['sort_direction'] = pagination.sortDirection;
      requestBody['quick_filter'] = filterState.activeQuickFilter;
      requestBody['attorney_name_filter'] = contactData.selectedAttorneys;
      requestBody['column_filter_model'] = filterState.filterModel;
      requestBody['active_filter'] = filterState.filterToggleValue === 'active';
      dispatch(fetchInventoryDemandData({ requestBody, token: user.signInUserSession.accessToken.jwtToken }));
      dispatch(setLoading(true));
    } else {
      dispatch(setDocumentDataLoading(documentId));
      dispatch(fetchDocumentData({ documentId, user }));
    }
  };

  const handleCloseDialog = () => dispatch(resetGlobalDialogues());

  const handleSetNotes = () => {
    setIsSubmitting(true);
    setDemandNotesApi(documentId, user, note)
      .then((response) => {
        if (response.status === 200) {
          refreshData();
          handleCloseDialog();
          setShowError(false);
          dispatch(setToast({ isOpen: true, severity: 'success', message: 'The demand notes have been updated.' }));
        } else {
          setShowError(true);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth={'xs'} TransitionComponent={Transition}>
      <DialogTitle>Edit demand notes</DialogTitle>
      <DialogContent>
        <Stack spacing={2} mt={1}>
          {showError && <Alert severity="error">There was an error updating the demand notes. Please try again.</Alert>}
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Notes"
            helperText={`${note.length}/500 characters`}
            inputProps={{ maxLength: 500 }}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSetNotes}
          variant="contained"
          color="secondary"
          disabled={note === (initialNote || documentDataNote)}
        >
          {isSubmitting ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditNotesDialog;
