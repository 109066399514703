import React from 'react'
import { useSelector } from 'react-redux'
import { Chip, Stack, CircularProgress } from '@mui/material'

const StrengthOfCaseTags = () => {
    const { demandScoreDataLoading, demandScoreDataError, demandScoreData } = useSelector((state) => state.DemandScore);

    const getDemandTags = () => {
        if (!demandScoreData?.insights) return [];
        const demandTagsObject = demandScoreData.insights.find(item => item.score_type === "DEMAND_TAGS");
        return demandTagsObject?.demand_tags || [];
    };

    const tags = getDemandTags();
    const hasTags = tags && tags.length > 0;

    return (
        <Stack direction="row" spacing={1}>
            <Stack
                direction="row"
                flexWrap="wrap"
                alignItems={"center"}
                sx={{
                    ml: 0,
                    rowGap: .5,  // Space between rows
                    '& > *': {
                        marginRight: '8px', // Space between chips
                    },
                    '& > *:last-child': { marginRight: 0 }, // Remove right margin for the last chip
                }}
            >
                {demandScoreDataLoading && !hasTags ? (
                    // Show a default chip with spinner when loading and no chips are present
                    <Chip
                        variant="outlined"
                        label={
                            <Stack direction="row" spacing={1} alignItems="center">
                                <CircularProgress size={16} />
                                <span>Loading</span>
                            </Stack>
                        }
                    />
                ) : demandScoreDataLoading && hasTags ? (
                    // Show spinner inside all existing chips when loading starts with chips present
                    tags.map((tag, index) => (
                        <Chip
                            variant='outlined'
                            label={
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <CircularProgress size={16} />
                                    <span>{tag?.tagName || ''}</span>
                                </Stack>
                            }
                            key={index}
                        />
                    ))
                ) : hasTags ? (
                    // Show normal chips when not loading and tags are present
                    tags.map((tag, index) => (
                        <Chip
                            variant='outlined'
                            label={tag?.tagName || ''}
                            key={index}
                        />
                    ))
                ) : (
                    // No chips to display when not loading and no tags are present
                    null
                )}
            </Stack>
        </Stack>
    )
}

export default StrengthOfCaseTags