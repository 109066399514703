import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  TextField,
  Stack,
  CircularProgress,
  Typography,
} from '@mui/material';
import { reloadMedicals } from '../api';
import { useDispatch, useSelector } from 'react-redux';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';
import { setToast } from '../redux/slices/globalToastSlice';
import { convertUTCDateTimeToLocalDate, convertUTCDateTimeToLocalTime } from '../common';
import moment from 'moment';
import { apiSlice } from "../services/apiSlice";

const ReimportMedicals = () => {
  const [batch, setBatch] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();
  const { batches } = useSelector((state) => state.Document);
  const { reimportMedicals } = useSelector((state) => state.GlobalDialogues);
  const { open, data } = reimportMedicals;
  const { documentId, user } = data;
  const { demandScoreData } = useSelector((state) => state.DemandScore);
  const currentVersion = demandScoreData?.version || 1;

  const handleBatchChange = (event, newValue) => setBatch(newValue);

  const handleReimport = () => {
    setIsSaving(true);
    reloadMedicals(documentId, batch.batchEntityId, user)
      .then(() => {
        dispatch(setToast({ isOpen: true, severity: 'success', message: 'Batch successfully reimported.' }));
        dispatch(apiSlice.endpoints.generateDemandScore.initiate({ documentId: documentId, version: currentVersion + 1 }, { forceRefetch: true }));
        handleClose();
      })
      .catch(() =>
        dispatch(
          setToast({ isOpen: true, severity: 'error', message: 'Batch could not be reimported. Please try again.' })
        )
      );
  };

  const handleClose = () => dispatch(resetGlobalDialogues());

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Reimport medicals</DialogTitle>

      <DialogContent>
        <Stack spacing={2} mt={2}>
          <Autocomplete
            id="select-batch"
            options={batches.map(({ batchEntityId, createdDate }) => ({
              batchEntityId,
              createdDate: moment.utc(createdDate),
            }))}
            value={batch}
            onChange={handleBatchChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select batch"
                helperText={
                  batch?.createdDate
                    ? `${convertUTCDateTimeToLocalDate(batch.createdDate)} ${convertUTCDateTimeToLocalTime(batch.createdDate)}`
                    : ''
                }
              />
            )}
            getOptionLabel={(option) => option.batchEntityId}
            isOptionEqualToValue={(option, value) => option.batchEntityId === value.batchEntityId}
            renderOption={({ key, ...props }, option) => (
              <li key={key} {...props}>
                <Stack>
                  {option.batchEntityId}
                  <Typography variant="tableP1">
                    {convertUTCDateTimeToLocalDate(option.createdDate)}{' '}
                    {convertUTCDateTimeToLocalTime(option.createdDate)}
                  </Typography>
                </Stack>
              </li>
            )}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>

        <Button disabled={!batch} onClick={handleReimport} variant="contained" color="secondary">
          {isSaving ? <CircularProgress size={24} /> : 'Reimport'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReimportMedicals;
