import React from 'react';
import { useEditor } from '@tiptap/react';
import { getEditorExtensions } from './editorConfiguration.js';
import PopulatedEditor from './PopulatedEditor.jsx';

const ConfiguredRichTextEditor = ({
  content,
  onChange,
  ...editorProps
}) => {
  
  const editor = useEditor({
    extensions: getEditorExtensions(),
    content,
    parseOptions: { preserveWhitespace: 'full' },
    onUpdate: onChange,
  });

  return (
    <PopulatedEditor 
      {...editorProps}
      editor={editor}
    />
  );
};

export default ConfiguredRichTextEditor;
