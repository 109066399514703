import React from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Tabs, Tab, Box } from "@mui/material";
import { GroupsOutlined, SupportOutlined } from "@mui/icons-material";

function samePageLinkNavigation(event) {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}

function LinkTab(props) {
  return (
    <Tab
      id={props.id}
      value={props.value}
      onClick={(event) => {
        if (samePageLinkNavigation(event)) {
          event.preventDefault();
        }
      }}
      aria-current={props.selected && 'page'}
      {...props}
      className="primary-nav-individual-tab"
    />
  );
}

const AdminTabs = (props) => {
  const { selectedTab } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(selectedTab);

  const handleChange = (event, newValue) => {
    // event.type can be equal to focus with selectionFollowsFocus.
    if (
      event.type !== 'click' ||
      (event.type === 'click' && samePageLinkNavigation(event))
    ) {
      setValue(newValue);
      navigate('/' + newValue, { state: { from: location.pathname } });
    }

  };

  return (
    <Box
      sx={{
        position: "fixed",
        height: "100vh",
        borderRight: "1px solid",
        borderColor: "divider",
        paddingX: .5,
        paddingTop: .5
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="admin tabs"
        role="navigation"
        orientation="vertical"
        className="no-indicator-tabs"
      >
        <LinkTab
          value="customer-management"
          label="Customers"
          href="/customer-management"
          icon={<GroupsOutlined />}
        />
        <LinkTab
          value="contact-management"
          label="Carriers"
          href="/contact-management"
          icon={<SupportOutlined />}
        />
      </Tabs>
    </Box>
  )
}
export default AdminTabs;