import React from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Stack, Typography } from '@mui/material';
import { EditNoteOutlined } from '@mui/icons-material';
import { setEditDemandNotes } from '../redux/slices/globalDialogueSlice';
import { theme } from '../Theme';
import { userHasPermission } from '../common';

const CustomerSpecialNotes = () => {
    const dispatch = useDispatch();
    const { documentData } = useSelector((state) => state.Document);
    const { user, userData } = useSelector((state) => state.User);
    const { documentId } = useParams();
    const demandHasNotes = documentData?.customerSpecialNotes.length > 0

    return (
        <Stack
            spacing={1}
            direction={"row"}
            alignItems="center"
        >
            <Typography
                variant='body1'
                color={theme.palette.text.secondary}
                sx={{
                    fontStyle: !demandHasNotes && "italic"
                }}
            >
                <span style={{ fontWeight: 500, fontStyle: 'normal' }}>Notes: </span>
                {demandHasNotes ? documentData?.customerSpecialNotes : "No notes saved on this demand"}
            </Typography>
            {
                userHasPermission('DemandCompose', userData) &&
                <IconButton
                    size="small"
                    disabled={!userHasPermission('DemandCompose', userData)}
                    onClick={() => {
                        dispatch(
                            setEditDemandNotes({
                                open: true,
                                isOpenFromInventory: false,
                                data: { documentId, user },
                            })
                        );
                    }}
                >
                    <EditNoteOutlined />
                </IconButton>
            }

        </Stack>
    )
}

export default CustomerSpecialNotes