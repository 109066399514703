import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AwsRum} from 'aws-rum-web';
import awsExports from "./aws-exports";


try {
  if(
    awsExports.hasOwnProperty('RUM_IDENTITY_POOL_ID') && 
    awsExports.hasOwnProperty('RUM_APP_MONITOR_ID')
  ) {
    const { RUM_IDENTITY_POOL_ID, RUM_APP_MONITOR_ID } = awsExports
    const config = {
      sessionSampleRate: 1,
      identityPoolId: RUM_IDENTITY_POOL_ID,
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["http","errors","performance"],
      allowCookies: true,
      enableXRay: true
    };

    const APPLICATION_ID= RUM_APP_MONITOR_ID;
    const APPLICATION_VERSION = '1.0.0';
    const APPLICATION_REGION = 'us-east-1';

    const _awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );
  } else {
    console.log('Skipping metrics client initialization')
  }
} catch (error) {
  console.warn('Failed to initialize metrics client')
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
