import { apiSlice } from '../apiSlice';
import { mapFilesWithCalculatedStartPages } from './helpers';
import { mapNewFilesToSequence } from '../../redux/slices/editDocumentsSlice';

const documentFilesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET /document/{documentId}/files/ids
    getAllFileIdsByDocumentId: builder.query({
      query: (documentId) => ({
        url: `/document/${documentId}/files/ids`,
        method: 'GET',
      }),
    }),
    // GET /file/{fileId}
    getFileById: builder.query({
      query: (fileId) => ({
        url: `/file/${fileId}`,
        method: 'GET',
      }),
    }),
    // custom query to specific files by specific file ids
    getFilesByIds: builder.query({
      queryFn: async (fileIds, { signal, dispatch, getState }, _extraOptions, baseQuery) => {
        try {
          const files = await Promise.all(fileIds.map((fileId) => baseQuery(`/file/${fileId}`)));
          return {
            data: files.map((file) => file.data),
          };
        } catch (error) {
          return { error };
        }
      },
    }),
    // custom query to get all associated files by document id
    getAllFilesByDocumentId: builder.query({
      queryFn: async (documentId, { signal, dispatch, getState }, _extraOptions, baseQuery) => {
        try {
          const files = await baseQuery(`/document/${documentId}/files`);
          
          return {
            // filter out the divider pages AFTER the start/end pages are calculated, this is the earlist point we can do this
            data: mapFilesWithCalculatedStartPages(files.data).filter(file => file?.fileEntityType !== 'DIVIDER_PAGE'),
          };

        } catch (error) {
          return { error };
        }
      },
      onQueryStarted: async (_, { dispatch, _getState, queryFulfilled}) => {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(mapNewFilesToSequence(data));
        }
      }
    }),
    // POST /document/{documentId}/files
    postDocumentFiles: builder.mutation({
      query: ({documentId, files}) => ({
        url: `/document/${documentId}/files`,
        method: 'POST',
        body: JSON.stringify(files),
      })
    }),
    // POST /document/{documentId}/files/fileEntityData
    postDocumentFileEntityData: builder.mutation({
      query: ({documentId, updatedFilesList}) => ({
        url: `document/${documentId}/files/fileEntityData`,
        method: 'POST',
        body: JSON.stringify(updatedFilesList),
      }),
    }),
    // POST /document/{documentId}/setFilesSequence
    postFilesSequence: builder.mutation({
      query: ({documentId, orderedFiles}) => ({
        url: `document/${documentId}/setFilesSequence`,
        method: 'POST',
        body: JSON.stringify({ orderedFiles }),
      }),
    }),
    // POST /document/{documentId}/files/sendAiProcessing
    sendAiProcessing: builder.mutation({
      query: ({documentId, files}) => ({
        url: `/document/${documentId}/files/sendAiProcessing`,
        method: 'POST',
        body: JSON.stringify(files),
      })
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllFileIdsByDocumentIdQuery, // these names are generated by rtk query automatically
  useGetFileByIdQuery,
  useGetFilesByIdsQuery,
  useGetAllFilesByDocumentIdQuery,
  usePostDocumentFilesMutation,
  usePostDocumentFileEntityDataMutation,
  usePostFilesSequenceMutation,
} = documentFilesApi;
