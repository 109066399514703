import React from 'react';
import { TextField, Autocomplete, Switches } from 'mui-rff';
import { TextField as MuiTextField, Chip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { SENDING_FIRM_CASE_NUMBER, SENDING_FIRM_TIN, SENDING_FIRM_PRIMARY_CONTACT, SENDING_FIRM_APPROVER_IS_PRIMARY_CONTACT, SENDING_FIRM_APPROVER, SENDING_FIRM_COLLABORATORS } from '../Schemas/SendingFirm';
import { successFieldInputProps, successFieldStyle } from '../../Styles';
import { getSingleSelectAutoCompleteFilteredOptions, getMultiSelectAutoCompleteFilteredOptions } from '../Helpers';
import { getCurrentUserOption } from '../../../DemandComponents/DemandForms/FormHelpers';

// 'sendingFirm.firmCaseNumber'
export const SendingFirmCaseNumberField = ({ sendingFirmCaseNumberMatchesIntegration }) => {

    return (
        <TextField
            label={SENDING_FIRM_CASE_NUMBER.label}
            name={SENDING_FIRM_CASE_NUMBER.path}
            required={SENDING_FIRM_CASE_NUMBER.required}
            sx={sendingFirmCaseNumberMatchesIntegration ? successFieldStyle : {}}
            InputProps={sendingFirmCaseNumberMatchesIntegration && successFieldInputProps}
            fieldProps={{ validate: SENDING_FIRM_CASE_NUMBER.validation }}
            fullWidth
        />
    )
}

// 'sendingFirm.firmTINNumber'
export const SendingFirmTINField = () => {
    return (
        <TextField
            fullWidth
            required={SENDING_FIRM_TIN.required}
            label={SENDING_FIRM_TIN.label}
            name={SENDING_FIRM_TIN.path}
        />
    )
}

// 'autoCompletes.primaryContact'
export const SendingFirmCaseTeamFields = ({ 
    primaryContact_auto_complete_data,
    attorney_auto_complete_data,
    collaborators_auto_complete_data,
    form,
    values,
    touched,
    errors,
    isPrimaryContactApprover,
    userData,
    includeCurrentUser = true,
}) => {
    const primaryContactApproverHelperText = isPrimaryContactApprover(values?.autoCompletes?.primaryContact?.userId) ? '' : `You must select an approver because ${values?.autoCompletes?.primaryContact?.label} does not have permission to approve demands`;

    return (
        <Grid container spacing={2}>
            <Grid xs={12}>
                <Autocomplete
                    name={SENDING_FIRM_PRIMARY_CONTACT.path}
                    //no need for explicitly controlled component here; RFF is handling with state management, including: value, onChange, error, and helperText
                    fullWidth
                    blurOnSelect
                    filterOptions={(options, params) => getSingleSelectAutoCompleteFilteredOptions(options, params)}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    options={primaryContact_auto_complete_data}
                    onChange={(e, value) => {
                        if (!isPrimaryContactApprover(value?.userId)) {
                            form.change(SENDING_FIRM_APPROVER_IS_PRIMARY_CONTACT.path, false)
                        }
                    }}
                    renderInput={(params) => (
                        <>
                            <MuiTextField
                                {...params}
                                label={SENDING_FIRM_PRIMARY_CONTACT.label}
                                required={SENDING_FIRM_PRIMARY_CONTACT.required}
                                onClick={(e) => form.blur(SENDING_FIRM_PRIMARY_CONTACT.path)}
                                error={Boolean(touched[SENDING_FIRM_PRIMARY_CONTACT.path] && errors.autoCompletes?.primaryContact)}
                                helperText={(touched[SENDING_FIRM_PRIMARY_CONTACT.path] && errors.autoCompletes?.primaryContact) || primaryContactApproverHelperText}
                            />
                        </>
                    )}
                />
            </Grid>

            <Grid xs={12}>
                <Switches
                    name={SENDING_FIRM_APPROVER_IS_PRIMARY_CONTACT.path}
                    onChange={(e) => {
                        const checked = e.target.checked;
                        form.change(SENDING_FIRM_APPROVER_IS_PRIMARY_CONTACT.path, checked)
                        form.change(SENDING_FIRM_APPROVER.path, checked ? undefined : values?.autoCompletes?.attorney)
                    }}
                    data={{ label: SENDING_FIRM_APPROVER_IS_PRIMARY_CONTACT.label, value: true, disabled: !isPrimaryContactApprover(values?.autoCompletes?.primaryContact?.userId) }}
                    color="secondary"
                />
            </Grid>

            {!values?.mutators?.attorneyIsPrimaryContact &&
                <Grid xs={12}>
                    <Autocomplete
                        name={SENDING_FIRM_APPROVER.path}
                        disablePortal
                        //no need for explicitly controlled component here; RFF is handling with state management, including: value, onChange, error, and helperText
                        fullWidth
                        blurOnSelect={true}
                        filterSelectedOptions={true}
                        filterOptions={(options, params) => getSingleSelectAutoCompleteFilteredOptions(options, params)}
                        options={attorney_auto_complete_data}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        renderInput={(params) => (
                            <>
                                <MuiTextField
                                    {...params}
                                    label={SENDING_FIRM_APPROVER.label}
                                    required={SENDING_FIRM_APPROVER.required}
                                    onClick={(e) => form.blur(SENDING_FIRM_APPROVER.path)}
                                    error={Boolean(touched[SENDING_FIRM_APPROVER.path] && errors.autoCompletes?.attorney)}
                                    helperText={touched[SENDING_FIRM_APPROVER.path] && errors.autoCompletes?.attorney}
                                />
                            </>
                        )}

                    />
                </Grid>
            }

            <Grid xs={12}>
                <Autocomplete
                    name={SENDING_FIRM_COLLABORATORS.path}
                    //no need for explicitly controlled component here; RFF is handling with state management, including: value, onChange, error, and helperText
                    fullWidth
                    multiple
                    disableCloseOnSelect
                    limitTags={4}
                    filterOptions={(options, params) => getMultiSelectAutoCompleteFilteredOptions(options, params, values?.autoCompletes?.collaborators)}
                    options={collaborators_auto_complete_data}
                    onChange={(e, newValues) => form.change(
                        SENDING_FIRM_COLLABORATORS.path,
                        includeCurrentUser ? 
                            [...newValues.filter(({ userId }) => userId !== userData.userId), ...[getCurrentUserOption(userData)]] : // keep the current user as a fixed option at the end
                            newValues
                    )}
                    renderInput={(params) => (
                        <>
                            <MuiTextField
                                //MUI-RFF textfield does filtering does not work well with multi select autocomplete, so we use MUI textfield here
                                {...params}
                                autoComplete='off'
                                label={SENDING_FIRM_COLLABORATORS.label}
                            />
                        </>
                    )}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => {
                            const { key, ...tagProps } = getTagProps({ index });

                            return (
                                <Chip
                                    key={key}
                                    label={option.label}
                                    {...tagProps}
                                    disabled={includeCurrentUser && option.userId === userData.userId}
                                />
                            );
                        })
                    }
                />
            </Grid>
        </Grid>
    )
}