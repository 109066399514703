export const MAX_POLLING_RETRIES = 15;
export const COMPLETE_STATUS = 'complete';
export const FAILED_STATUS = 'failed';
export const POLLING_INTERVAL = 4000;
export const FETCH_PDF_DELAY = 100;

export const updateLoadingState = (state, action, isLoading) => {
    const documentId = action.meta.arg.documentId;
    state.demandS3RegenerationLoadingData = {
        ...state.demandS3RegenerationLoadingData,
        [documentId]: isLoading
    }

    state.demandS3RegenerationSkeletonData = {
        ...state.demandS3RegenerationSkeletonData,
        [documentId]: isLoading
    }
}

export const checkExpiryTime = (demandS3Url) => {
    try {
        if (!demandS3Url) {
            return { expiryTime: null, isExpired: true };
        }

        const url = new URL(demandS3Url);
        const expiresParam = url.searchParams.get('Expires');

        if (!expiresParam) {
            return { expiryTime: null, isExpired: true };
        }

        const expiryTime = parseInt(expiresParam, 10);
        const now = Math.floor(Date.now() / 1000);
        const isExpired = now >= expiryTime;

        return {
            expiryTime,
            isExpired,
            timeRemaining: isExpired ? 0 : expiryTime - now
        };
    } catch (error) {
        console.error('Error parsing expiry time:', error);
        return { expiryTime: null, isExpired: true };
    }
}