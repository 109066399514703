import React from 'react';
import { DialogTitle, Stack, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import Title from '../../Title';
import { theme } from '../../../Theme';

const DialogTitleBanner = ({ title, actions, handleClose, CloseIcon }) => {
  return (
    <DialogTitle
      className="dialog-title-blue"
      sx={{ height: '60px', width: '100%', zIndex: 1000, mb: 2 }}
      display="flex"
      alignItems="center"
      position="sticky"
    >
      <Stack
        direction="row"
        style={{ width: '100%' }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <IconButton
            onClick={handleClose}
            aria-label="close"
            className='dark-bg-icon-button'
          >
            {CloseIcon ? <CloseIcon color="white" /> : <Close color="white" />}
          </IconButton>

          <Title color={theme.palette.white.main}>{title}</Title>
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center">
          {actions}
        </Stack>
      </Stack>
    </DialogTitle>
  );
};

export default DialogTitleBanner;
