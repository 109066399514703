import { useNavigate, Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { DataGridPro, gridStringOrNumberComparator } from '@mui/x-data-grid-pro';
import { Stack, Button, Box, Container, Chip, Avatar, Typography } from '@mui/material';
import awsExports from '../aws-exports';
import { convertUTCDateTimeToLocalDate } from '../common';
import AdminTabs from '../CustomerManagement/AdminTabs';
import Title from '../CommonComponents/Title';
import { setPrecedentDefaultTemplateId } from '../redux/slices/adminEditTemplateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { GroupAddOutlined, GroupOutlined, GroupsOutlined } from '@mui/icons-material';
import ColumnMenu from '../CommonComponents/DataGrid/ColumnMenu';
import { theme } from '../Theme';


const CustomersView = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.User);
  const rootApiUrl = awsExports.ROOT_API_URL;
  const [customersData, setCustomersData] = useState([]);
  const [loading, setLoading] = useState(false);

  dispatch(setPrecedentDefaultTemplateId(customersData.find((customer) => customer.name === 'Precedent')?.defaultTemplateId || ''));

  const navigate = useNavigate();

  const getCustomersApi = async () => {
    const settings = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: user.signInUserSession.accessToken.jwtToken,
      },
    };

    setLoading(true);

    fetch(rootApiUrl + '/customers', settings)
      .then((response) => response.json())
      .then((data) => setCustomersData(data))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCreateCustomerClick = () => navigate('/customer-management/new');

  const getOwner = (customer) =>
    customer['ownerUser'] ? `${customer['ownerUser']['firstName']} ${customer['ownerUser']['lastName']}` : '';

  // Grid Column_col
  const name_col = {
    field: 'name',
    headerName: 'Name',
    minWidth: 300,
    flex: 0.25,
    getSortComparator: (sortDirection) => {
      // Custom sort function. Precedent first and then all the others alphabetically
      const modifier = sortDirection === 'desc' ? -1 : 1;
      return (value1, value2, cellParams1, cellParams2) => {
        if (value1 === 'Precedent') {
          return -1;
        }
        if (value2 === 'Precedent') {
          return 1;
        }
        return modifier * gridStringOrNumberComparator(value1, value2, cellParams1, cellParams2);
      };
    },
    valueGetter: (params) => params.row.name,
    renderCell: (params) => <Link style={{ textDecoration: "none" }} to={'/customer-management/' + params.row.customerId}>{params.row.name}</Link>,
  };

  const owner_col = {
    field: 'owner',
    headerName: 'Account owner',
    minWidth: 200,
    flex: 0.25,
    valueGetter: (params) => {
      return getOwner(params.row);
    },
    renderCell: (params) => {
      return (
        params.value === '' ? '-' :
          <Chip
            avatar={
              <Avatar
                sx={{ bgcolor: theme.palette.primary.light, ml: 0 }}
              >
                <Typography
                  sx={{ color: '#ffffff' }}
                >
                  {params.value[0]}
                </Typography>
              </Avatar>
            }
            sx={{
              p: 0,
              borderRadius: 0,
              background: 'none',
              height: 'auto',
              fontSize: ".875rem"
            }}
            label={params.value}
          />
      );
    },
  };

  const created_date_col = {
    field: 'create_dt',
    headerName: 'Created',
    minWidth: 300,
    valueGetter: (params) => {
      return convertUTCDateTimeToLocalDate(new Date(params.row.createdTs * 1000));
    },
  };

  useEffect(() => {
    getCustomersApi();
  }, []);

  const columns = React.useMemo(() => [name_col, owner_col, created_date_col]);

  return (
    <Box sx={{
      display: "flex",
      height: "100vh",
      overflow: "hidden",
      mt: 8
    }}
    >
      {/* Left Sidebar Navigation */}
      <AdminTabs selectedTab="customer-management"></AdminTabs>

      {/* Scrollable Content Area */}
      <Box
        sx={{
          flexGrow: 1,
          marginLeft: "98px", // Offset for fixed sidebar
          overflowY: "auto",
          height: "100vh",
          p: 2,
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Title>Customers</Title>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<GroupsOutlined />}
            onClick={handleCreateCustomerClick}
          >
            Create customer
          </Button>
        </Stack>
        <Box height="calc(100vh - 140px)">
          <DataGridPro
            getRowId={(row) => row.customerId}
            columns={columns}
            rows={customersData}
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }],
              },
              pagination: { paginationModel: { pageSize: 50 } },
            }}
            disableColumnPinning
            disableRowSelectionOnClick={true}
            disableColumnSelector={true}
            loading={loading}
            slots={{
              columnMenu: ColumnMenu
            }}
            pageSizeOptions={[25, 50, 100]}
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              mt: 1.5,
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '14px',
                fontWeight: '500',
              },
              '& .MuiDataGrid-cellContent': {
                fontSize: '14px',
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CustomersView;
