import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListItemIcon, ListItemText, MenuItem, Stack, CircularProgress, Tooltip } from '@mui/material';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
import { getIntegrationLogoUrl } from '../../../CustomerManagement/Integrations/Helpers';
import {
  useGetIntegrationsForUserQuery,
  useGetIntegrationMetadataQuery,
  useUploadDocumentToIntegrationMutation,
  useLazyGetDocumentUploadStatusQuery,
  useLazyGetIntegrationAuthenticationUrlQuery,
} from '../../../services/integrations/integrationsApi';
import { setToast } from '../../../redux/slices/globalToastSlice';
import { userHasPermission } from '../../../common';
import { DOCUMENT_APPROVED, DOCUMENT_RECEIVED, DOCUMENT_SUBMITTED, HANDLING_COMPLETED } from '../../../common-demand';

const UploadDemandToIntegration = ({ documentId, documentStatus, handleMenuClose }) => {
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.User);
  const { integratedCase } = useSelector((state) => state.Integrations);
  const { id: matterId, claimNumber } = integratedCase;
  const isIntegrationCaseLinked = Boolean(matterId && claimNumber);

  const [isUploading, setIsUploading] = useState(false);

  const { data: userIntegrations } = useGetIntegrationsForUserQuery();
  const { data: integrationMetadata = {} } = useGetIntegrationMetadataQuery();
  const { displayName: integrationName = '', integrationId = '' } = integrationMetadata;

  const [uploadDocument] = useUploadDocumentToIntegrationMutation();

  const [getUploadStatus, { data: uploadStatusData = {} }] = useLazyGetDocumentUploadStatusQuery({
    pollingInterval: isUploading ? 2000 : 0,
  });
  const { status: uploadStatus } = uploadStatusData;

  const [getIntegrationAuthUrl] = useLazyGetIntegrationAuthenticationUrlQuery();

  useEffect(() => {
    if (uploadStatus === 'SUCCESS') {
      setIsUploading(false);
      dispatch(setToast({ isOpen: true, message: `Demand sent to ${integrationName} successfully` }));
      handleMenuClose();
    }
  }, [uploadStatus, integrationName, setIsUploading, dispatch]);

  const handleUploadClick = async () => {
    const userIntegration = userIntegrations?.find(
      (userIntegration) => userIntegration.integrationId === integrationId
    );

    if (userIntegration && !userIntegration?.isExpired) {
      try {
        setIsUploading(true);
        const response = await uploadDocument(documentId).unwrap();
        const { uploadRequestId } = response;
        // Delay first poll of upload status to avoid 404 while demand is queued
        setTimeout(() => getUploadStatus({ documentId, uploadRequestId }), 2000);
      } catch (error) {
        setIsUploading(false);
        dispatch(setToast({ isOpen: true, message: `Failed to send demand to ${integrationName}`, severity: 'error' }));
        handleMenuClose();
      }
    } else {
      // Launch external auth flow
      const { origin, pathname } = new URL(window.location.href);
      const urlWithoutQueryString = origin + pathname;

      const requestBody = {
        redirectState: {},
        redirectUrl: urlWithoutQueryString,
      };
      const { data: authUrlData } = await getIntegrationAuthUrl({ integrationId, payload: requestBody });

      authUrlData && window.location.replace(authUrlData?.authenticationUrl);
    }
  };

  const shouldShowUpload =
    isIntegrationCaseLinked &&
    userHasPermission('IntDemandUpload', userData) &&
    [DOCUMENT_APPROVED, DOCUMENT_SUBMITTED, DOCUMENT_RECEIVED, HANDLING_COMPLETED].includes(documentStatus);

  return shouldShowUpload ? (
    <Tooltip title={isUploading ? `Sending to ${integrationName}` : ''} arrow placement="left">
      <span>
        <MenuItem onClick={handleUploadClick} disabled={isUploading}>
          <ListItemIcon>{isUploading ? <CircularProgress size={20} /> : <ScreenShareOutlinedIcon />}</ListItemIcon>
          <ListItemText>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <span>Send to</span>
              <img
                src={getIntegrationLogoUrl(integrationId)}
                alt={integrationName}
                style={{
                  height: 20,
                }}
              />
            </Stack>
          </ListItemText>
        </MenuItem>
      </span>
    </Tooltip>
  ) : null;
};

export default UploadDemandToIntegration;
