import { observeEvent, observeUserIdentityCleared } from "../observability/observability";
import { resetUserState, logout } from '../redux/slices/userSlice';
import { signOut } from '@aws-amplify/auth';

export const handleSignout = async (handleCloseUserMenu, dispatch) => {
    observeEvent("userMenuSignoutClick");
    handleCloseUserMenu && handleCloseUserMenu();
    await dispatch(logout());
    await dispatch(resetUserState());
    observeUserIdentityCleared(); // Record that the user identity has been dropped
    signOut();
  };
