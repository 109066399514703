import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Stack,
    Typography,
    FormControl,
    Select,
    MenuItem,
    Radio,
    RadioGroup,
    FormControlLabel,
    IconButton,
    Tooltip,
    Alert,
    DialogActions
} from '@mui/material';

import { useDispatch } from 'react-redux';
import { setSequencedDocumentFiles } from '../redux/slices/editDocumentsSlice';
import { LowPriorityOutlined } from '@mui/icons-material';

/**
 * Component for bulk moving documents in the document editor
 * 
 * @param {Array} selectedRows - Array of selected document IDs
 * @param {Array} sequencedDocumentFiles - Array of document files
 * @param {Function} setIsDirty - Function to mark the form as dirty (enable save button)
 * @param {Function} setIsRowOrderUpdated - Function to mark that row order was changed
 */
const BulkMoveDocuments = (props) => {
    // Safely extract props with defaults
    const selectedRows = props.selectedRows || [];
    const sequencedDocumentFiles = props.sequencedDocumentFiles || [];

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [movePosition, setMovePosition] = useState('after');
    const [targetDocumentId, setTargetDocumentId] = useState('');

    // Safely filter documents
    const availableTargetDocuments = [];

    // Only process if we have valid document files
    if (Array.isArray(sequencedDocumentFiles)) {
        for (const file of sequencedDocumentFiles) {
            if (file && file.fileEntityData &&
                file.fileEntityData.detectedType !== "cover letter" &&
                file.fileEntityData.detectedType !== "demand letter") {
                availableTargetDocuments.push(file);
            }
        }
    }

    // Check if all valid documents are selected
    const allDocumentsSelected = availableTargetDocuments.length > 0 && 
        selectedRows.length === availableTargetDocuments.length;

    useEffect(() => {
        // Set default target document when dialog opens
        if (open && availableTargetDocuments.length > 0 && !targetDocumentId) {
            const excludeSelectedFiles = availableTargetDocuments.filter((doc) => !selectedRows.includes(doc.fileEntityId));
            if (excludeSelectedFiles.length > 0) {
                setTargetDocumentId(excludeSelectedFiles[0].fileEntityId);
            }
        }
    }, [open, availableTargetDocuments, targetDocumentId, selectedRows]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePositionChange = (event) => {
        setMovePosition(event.target.value);
    };

    const handleTargetChange = (event) => {
        setTargetDocumentId(event.target.value);
    };

    const handleMoveDocuments = () => {
        try {
            // Safety checks
            if (!Array.isArray(sequencedDocumentFiles) || sequencedDocumentFiles.length === 0) {
                console.error("No documents to move");
                return;
            }

            if (!targetDocumentId) {
                console.error("No target document selected");
                return;
            }

            // 1. Get the selected documents - safely
            const selectedDocuments = [];
            for (const file of sequencedDocumentFiles) {
                if (file && file.fileEntityId && selectedRows.includes(file.fileEntityId)) {
                    selectedDocuments.push(file);
                }
            }

            if (selectedDocuments.length === 0) {
                console.error("No documents selected for moving");
                return;
            }

            // 2. Get the target document index
            const targetIndex = sequencedDocumentFiles.findIndex(
                file => file && file.fileEntityId === targetDocumentId
            );

            if (targetIndex === -1) {
                console.error("Target document not found");
                return;
            }

            // 3. Create a new array without the selected documents
            const remainingDocuments = sequencedDocumentFiles.filter(
                file => file && file.fileEntityId && !selectedRows.includes(file.fileEntityId)
            );

            // 4. Determine insertion index based on move position
            let selectedBeforeTarget = 0;
            for (const id of selectedRows) {
                const index = sequencedDocumentFiles.findIndex(file => file && file.fileEntityId === id);
                if (index !== -1 && index < targetIndex) {
                    selectedBeforeTarget++;
                }
            }

            const insertionIndex = movePosition === 'after'
                ? targetIndex + 1 - selectedBeforeTarget
                : targetIndex - selectedBeforeTarget;

            // 5. Insert the selected documents at the specified position
            const newDocumentOrder = [
                ...remainingDocuments.slice(0, insertionIndex),
                ...selectedDocuments,
                ...remainingDocuments.slice(insertionIndex)
            ];

            // 6. Update the sequenced documents in Redux
            dispatch(setSequencedDocumentFiles(newDocumentOrder));

            // Mark the form as dirty (this will enable the save button)
            props.setIsDirty && props.setIsDirty(true);
            props.setIsRowOrderUpdated && props.setIsRowOrderUpdated(true);

            // 7. Close dialog
            handleClose();
        } catch (error) {
            console.error("Error moving documents:", error);
        }
    };

    // If we don't have valid sequencedDocumentFiles, don't render anything
    if (!Array.isArray(sequencedDocumentFiles) || sequencedDocumentFiles.length === 0) {
        return null;
    }

    return (
        <>
            <Tooltip
                title={allDocumentsSelected ? 
                    "Cannot move when all documents are selected" : 
                    "Move selected documents"}
                arrow
                placement='left'
            >
                <span>
                    <IconButton
                        onClick={handleOpen}
                        disabled={selectedRows.length === 0 || allDocumentsSelected}
                    >
                        <LowPriorityOutlined />
                    </IconButton>
                </span>
            </Tooltip>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h6">Move documents</Typography>
                </DialogTitle>

                <DialogContent
                >
                    <Stack
                        spacing={2}
                        sx={{
                            mt: 2
                        }}
                    >
                        <Alert severity='info'>
                            {selectedRows.length} document{selectedRows.length !== 1 ? 's' : ''} selected
                        </Alert>

                        <Stack spacing={2}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography
                                    variant='body1'
                                    color={"primary"}
                                >Move selected documents
                                </Typography>
                                <FormControl size="small" sx={{ minWidth: 120 }}>
                                    <Select
                                        value={movePosition}
                                        onChange={handlePositionChange}
                                    >
                                        <MenuItem value="after">After</MenuItem>
                                        <MenuItem value="before">Before</MenuItem>
                                    </Select>
                                </FormControl>
                            </Stack>

                            {availableTargetDocuments.length === 0 ? (
                                <Typography variant="body2" color="text.secondary">
                                    No available target documents
                                </Typography>
                            ) : (
                                <RadioGroup
                                    value={targetDocumentId}
                                    onChange={handleTargetChange}
                                >
                                    <Stack spacing={1}>
                                        {availableTargetDocuments.map((document) => (
                                            <FormControlLabel
                                                key={document.fileEntityId}
                                                value={document.fileEntityId}
                                                control={<Radio />}
                                                disabled={selectedRows.includes(document.fileEntityId)}
                                                label={
                                                    <Typography variant="body2">
                                                        {document.fileEntityData?.fileName || 'Untitled Document'}
                                                    </Typography>
                                                }
                                            />
                                        ))}
                                    </Stack>
                                </RadioGroup>
                            )}
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="text"
                        color='secondary'
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleMoveDocuments}
                        variant="contained"
                        color='secondary'
                        disabled={!targetDocumentId || selectedRows.includes(targetDocumentId) || availableTargetDocuments.length === 0}
                    >
                        Move documents
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default BulkMoveDocuments;